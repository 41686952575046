// Password requirements:
// - Must be at least 10 characters long
// - Must be 128 or less characters
// - If shorter than 20 characters, it must satisfy at least 3 of the following
//   - at least one lowercase letter (a...z)
//   - at least one upper case letter (A...Z)
//   - At least one digit (1...0)
//   - At least one special character (*)
export const checkPassword = (p: any) => {
  let errorString = '';
  const requiredErrors = [];
  const optionalErrors = [];
  let isPassphrase = false;
  if (typeof p !== 'string') {
    throw new TypeError('String expected.');
  }
  if (p.length < 10) {
    requiredErrors.push('Password must be at least 10 characters long.');
  }
  if (p.length > 128) {
    requiredErrors.push('Password must be fewer than 129 characters.');
  }
  if (p.length < 20) {
    if (!/[a-z]/.test(p)) {
      optionalErrors.push('must contain at least one lowercase letter');
    }
    if (!/[A-Z]/.test(p)) {
      optionalErrors.push('must contain at least one uppercase letter');
    }
    if (!/[0-9]/.test(p)) {
      optionalErrors.push('must contain at least one number');
    }
    if (!/[^A-Za-z0-9]/.test(p)) {
      optionalErrors.push('must contain at least one special character');
    }
  }
  if (p.length > 20) {
    isPassphrase = true;
  }
  if (requiredErrors.length > 0) {
    errorString = requiredErrors.join(' ');
  }

  const numOptionalErrors = optionalErrors.length;
  if (!isPassphrase && numOptionalErrors > 1) {
    errorString += `Password must be either at least 20 characters long or must satisfy ${
      numOptionalErrors - 1
    } more of the following requirements: ${optionalErrors.join(', ')}`;
  }

  if (errorString) {
    throw new Error(errorString);
  }
};
