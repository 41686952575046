import React from 'react';
import { withRouter } from 'react-router-dom';
import { Event, DisplayEvent } from '../event';
import { LinkButton } from '../../../../../components';
import { navigateTo } from '../../../../../core';
import { Context } from '../../../../framework';

export class PolicyLinkedEvent extends Event<any> {}

class PolicyLinkedDisplayEventImpl extends DisplayEvent {
  getTitle = () => 'Policy linked';

  renderFull = () => {
    const { history } = this.props as any;
    return (
      <>
        <LinkButton
          onClick={() =>
            navigateTo({
              url: `/orgs/${Context.organizationId}/insurance/policies/${this.props.event?.entity?.policyId}`,
              history,
            })
          }
        >
          View policy
        </LinkButton>
      </>
    );
  };
}

export const PolicyLinkedDisplayEvent = withRouter(PolicyLinkedDisplayEventImpl as any);
