import React from 'react';
import { FormElement, FormElementProps, FormElementState } from './form-element';

export type SelectValue = number[] | string[] | undefined;
export type SelectValueElement = number | string;

export interface FilterListProps extends FormElementProps<SelectValue> {
  options: { label: string; value: SelectValueElement }[];
}

export class FilterList extends FormElement<SelectValue, FilterListProps, FormElementState<SelectValue>> {
  valueClicked = (value: SelectValueElement) => {
    if (this.state.value === undefined) {
      this.valueChanged([value] as SelectValue);
      return;
    }

    const values: any[] = [...this.state.value];

    if (values.includes(value)) {
      values.splice(values.indexOf(value), 1);
    } else {
      values.push(value);
    }

    this.valueChanged(this.getValue(values));
  };

  protected _render() {
    const values: any[] = this.state.value || [];
    return (
      <div className='filter-list-container'>
        {this.props.options.map((o) => (
          <div
            key={o.value}
            className={`filter-list-value${values.includes(o.value) ? ' active' : ''}`}
            onClick={() => this.valueClicked(o.value)}
          >
            {o.label}
          </div>
        ))}
      </div>
    );
  }

  protected getValueFromProps(props: FilterListProps) {
    return this.getValue(props.defaultValue);
  }

  private getValue(value: SelectValue) {
    if (!Array.isArray(value) || value === undefined || value.length === 0) {
      return undefined;
    }

    return value;
  }
}
