import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import ReactSelect, {
  OptionValues,
  Option as SelectOption,
  Options,
  LoadOptionsHandler,
  FilterOptionsHandler,
  Async,
} from 'react-select';
import { FormElement, FormElementProps, FormElementState } from './form-element';

export type SelectValue = OptionValues | undefined;

export interface SelectProps extends FormElementProps<SelectValue> {
  options?: Options;
  noResultsText?: string;
  filterOptions?: FilterOptionsHandler<SelectValue>;
  loadOptions?: LoadOptionsHandler<SelectValue>;
  autoload?: boolean;
  clearable?: boolean;
  multi?: boolean;
  description?: string;
  cache?: any;
}

export class Select extends FormElement<SelectValue, SelectProps, FormElementState<SelectValue>> {
  onChange = (newValue: SelectOption<SelectValue> | null) => {
    const value = this.getValue(newValue);
    this.valueChanged(value);
  };

  render() {
    const invalid = this.state.touched && !this.state.valid;
    return (
      <FormGroup id={this.props.id} key={this.props.name} className={this.props.className}>
        <Label for={this.props.name}>
          {this.props.label}
          {this.props.sublabel ? ` ${this.props.sublabel}` : ''} {this.props.required ? ' *' : ''}
        </Label>
        <div className={`input-wrapper select ${invalid ? 'invalid' : ''}`}>{this._render()}</div>
        {!!this.props.description && <small>{this.props.description}</small>}
        {invalid && <small>{this.state.error}</small>}
      </FormGroup>
    );
  }
  protected _render() {
    if (this.props.loadOptions) {
      return (
        <Async
          className={this.getValidationClassName()}
          {...this.props}
          placeholder={this.props.placeholder || 'Select'}
          clearable={this.props.required !== true}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onFocus={this.onFocus}
          value={this.state.value}
          loadOptions={this.props.loadOptions}
          filterOptions={this.props.filterOptions}
          autoload={this.props.autoload || false}
          noResultsText={this.props.noResultsText || 'No results found'}
          multi={this.props.multi}
          cache={this.props.cache}
        />
      );
    }

    if (this.props.children) {
      return (
        <>
          <ReactSelect
            className={this.getValidationClassName()}
            {...this.props}
            clearable={this.props.clearable === false ? false : this.props.required !== true}
            onBlur={this.onBlur}
            onChange={this.onChange}
            onFocus={this.onFocus}
            value={this.state.value}
            multi={this.props.multi}
          />
          {this.props.children}
        </>
      );
    }

    return (
      <ReactSelect
        className={this.getValidationClassName()}
        {...this.props}
        clearable={this.props.clearable === false ? false : this.props.required !== true}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onFocus={this.onFocus}
        value={this.state.value}
      />
    );
  }

  protected getValueFromProps(props: SelectProps) {
    return this.getValue(props.defaultValue);
  }

  private getValue(value: any) {
    if (value === null || value === undefined) {
      return undefined;
    }

    if (isOption(value)) {
      return value.value;
    }

    return value as SelectValue;
  }
}

const isOption = (value: any): value is SelectOption => {
  return value !== null && value.value !== null && value.value !== undefined && !value.user; // This is super bad, smelly code, which I will have to repent for, used to check for UserSearchResult from add-user-overlay.tsx
};
