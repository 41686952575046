import momentTimezone, { Moment } from 'moment-timezone';
import uuid from 'uuid';
import { Title } from 'insurance/general/title';
import {
  toInternationalFormat,
  toPrettyInternationalFormat,
  toCellphoneObject,
  toNationalFormat,
  toInternationalObject,
} from 'insurance/shared/utils';
import { RequestedBy, CreatedByType } from '../../models';
import { Construct } from '../../models/construct';
import { Gender } from '../../models/gender';
import { PolicyholderEntityType } from './policyholder-entity-type';
import { Countries } from '../../../components/forms/countries';
import { Lead } from '../../leads/domain/lead';
import { Policy, NetworkPolicy } from '../../policies/domain/policy';
import { PolicyholderIdentification, NetworkPolicyholderIdentification } from './policyholder-identification';

export enum PolicyholderFieldNames {
  Email = 'email',
  Cellphone = 'cellphone',
  PhoneOther = 'phoneother',
  AddressLine1 = 'address.line1',
  AddressLine2 = 'address.line2',
  AddressSuburb = 'address.suburb',
  AddressCity = 'address.city',
  AddressAreaCode = 'address.areaCode',
  AddressCountry = 'address.country',
  AddressGooglePlaceId = 'address.googlePlaceId',
  AddressGeoCoordinatesLatitude = 'address.geoCoordinatesLatitude',
  AddressGeoCoordinatesLongitude = 'address.geoCoordinatesLongitude',
}

export const policyholderFieldConfig = {
  [PolicyholderFieldNames.Email]: { apiKey: 'email' },
  [PolicyholderFieldNames.Cellphone]: { apiKey: 'cellphone' },
  [PolicyholderFieldNames.PhoneOther]: { apiKey: 'phoneother' },
  [PolicyholderFieldNames.AddressLine1]: { apiKey: 'address.line_1' },
  [PolicyholderFieldNames.AddressLine2]: { apiKey: 'address.line_2' },
  [PolicyholderFieldNames.AddressSuburb]: { apiKey: 'address.suburb' },
  [PolicyholderFieldNames.AddressCity]: { apiKey: 'address.city' },
  [PolicyholderFieldNames.AddressAreaCode]: { apiKey: 'address.area_code' },
  [PolicyholderFieldNames.AddressCountry]: { apiKey: 'address.country' },
  [PolicyholderFieldNames.AddressGooglePlaceId]: { apiKey: 'address.google_place_id' },
  [PolicyholderFieldNames.AddressGeoCoordinatesLatitude]: { apiKey: 'address.geo_coordinates_latitude' },
  [PolicyholderFieldNames.AddressGeoCoordinatesLongitude]: { apiKey: 'address.geo_coordinates_longitude' },
};

export const policyholderFieldMap = Object.keys(policyholderFieldConfig).reduce((acc, fieldKey) => {
  return {
    ...acc,
    [fieldKey]: policyholderFieldConfig[fieldKey as PolicyholderFieldNames].apiKey,
  };
}, {});

export enum IdentificationType {
  Id = 'id',
  Passport = 'passport',
  Custom = 'custom',
  Email = 'email',
  Cellphone = 'cellphone',
}

export interface Identification {
  type: IdentificationType;
  number: string;
  country: string;
  expirationDate?: Moment;
}

export interface Cellphone {
  country: string;
  number: string;
}

export enum PolicyholderType {
  Individual = 'individual',
  Company = 'company',
}

export interface Address {
  googlePlaceId?: string;
  geoCoordinatesLatitude?: string;
  geoCoordinatesLongitude?: string;
  line1: string;
  line2?: string;
  suburb: string;
  city: string;
  country: string;
  areaCode: string;
}

interface NetworkAddress {
  google_place_id?: string;
  geo_coordinates_latitude?: string;
  geo_coordinates_longitude?: string;
  line_1: string;
  line_2?: string;
  suburb: string;
  city: string;
  country: string;
  area_code: string;
}

export class Policyholder {
  readonly policyholderId: string;
  readonly type: PolicyholderEntityType;
  readonly createdAt: Moment;
  readonly identification?: PolicyholderIdentification;
  readonly dateOfBirth?: Moment;
  readonly gender?: Gender;
  readonly firstName: string;
  readonly middleName?: string;
  readonly lastName: string;
  readonly companyName?: string;
  readonly registrationNumber?: string;
  readonly contactPosition?: string;
  readonly subsidiaryCompanies?: string[];
  readonly dateOfEstablishment?: Moment;
  readonly companyWebsiteUrl?: string;
  readonly email?: string;
  readonly cellphone?: Cellphone;
  readonly phoneOther?: Cellphone;
  readonly policyIds: string[];
  readonly createdBy: RequestedBy;
  readonly appData?: { [key: string]: any };
  readonly address?: Address;
  readonly title?: Title;
  readonly policies?: Policy[];
  readonly updatedAt: Moment;
  public isFromLead?: boolean;

  constructor(init: Construct<Policyholder>) {
    Object.assign(this, init);
  }

  displayAddress() {
    if (!this.address) {
      return undefined;
    }

    const displayAddress = [
      this.address.line1,
      this.address.line2,
      this.address.suburb,
      this.address.city,
      this.address.areaCode,
      Countries.find((x) => x.code === this.address?.country)?.name,
    ]
      .filter((x) => !!x && !!x.trim())
      .join(', ')
      .replace(/^[,\s]+|[,\s]+$/g, '')
      .replace(/,[,\s]*,/g, ',');

    return displayAddress;
  }

  prettyPhoneOtherNumber() {
    const number = this.phoneOther ? this.phoneOther : undefined;
    if (!number) {
      return undefined;
    }
    return toPrettyInternationalFormat(number);
  }

  isIndividual() {
    return this.type === PolicyholderEntityType.Individual;
  }

  isCompany() {
    return this.type === PolicyholderEntityType.Company;
  }

  identificationNumber() {
    if (this.type === PolicyholderEntityType.Individual) {
      return this.identification ? this.identification.number : '';
    }
    return this.registrationNumber;
  }

  getLegalId() {
    if (this.type === PolicyholderEntityType.Company) {
      return `Company reg. ${this.registrationNumber}`;
    }

    return this.identification && this.identification.number;
  }

  fullName() {
    if (this.type === PolicyholderEntityType.Individual) {
      return `${this.firstName || ' '} ${this.middleName ? `${this.middleName} ` : ''}${this.lastName || ' '}`;
    }
    return this.companyName;
  }

  getCountry() {
    if (this.type === PolicyholderEntityType.Company) {
      return 'za';
    }
    return (this.identification && this.identification.country.toLowerCase()) || 'za';
  }

  prettyCellNumber = () => {
    const number = this.cellphone && this.cellphone.number;
    if (!this.cellphone || !number) {
      return undefined;
    }

    return toPrettyInternationalFormat(this.cellphone);
  };

  public computedDateOfBirth() {
    if (this.type === PolicyholderEntityType.Company || !this.identification) {
      return momentTimezone();
    }

    if (this.identification.type === IdentificationType.Passport) {
      return momentTimezone(this.dateOfBirth);
    }

    const currentYear = new Date().getFullYear();
    const currentCentury = Math.floor(currentYear / 100) * 100;
    let yearPart = currentCentury + parseInt(this.identification.number.substr(0, 2), 10);
    if (yearPart > currentYear) {
      yearPart -= 100;
    }

    const monthPart = parseInt(this.identification.number.substr(2, 2), 10) - 1;
    const dayPart = parseInt(this.identification.number.substr(4, 2), 10);

    const dateOfBirth = new Date(yearPart, monthPart, dayPart);

    return momentTimezone(dateOfBirth);
  }

  public age() {
    if (this.type === PolicyholderEntityType.Company) {
      return 0;
    }

    if (this.identification && this.identification.type === IdentificationType.Passport) {
      return momentTimezone().diff(momentTimezone(this.dateOfBirth), 'years', false);
    }

    return momentTimezone().diff(this.computedDateOfBirth(), 'years', false);
  }

  // used with the policy wizard
  public toInputValues = () => ({
    age: this.age(),
    firstName: this.firstName,
    lastName: this.lastName,
    gender: this.gender,
  });

  public static fromNetwork(init: NetworkPolicyholder) {
    return new Policyholder({
      policyholderId: init.policyholder_id,
      type: init.type,
      createdAt: momentTimezone(init.created_at),
      updatedAt: momentTimezone(init.updated_at),
      identification: init.id && PolicyholderIdentification.fromNetwork(init.id),
      dateOfBirth: init.date_of_birth ? momentTimezone(init.date_of_birth) : undefined,
      gender: init.gender,
      title: init.title,
      firstName: init.first_name,
      middleName: init.middle_name,
      lastName: init.last_name,
      companyName: init.company_name,
      registrationNumber: init.registration_number,
      contactPosition: init.contact_position,
      subsidiaryCompanies: init.subsidiary_companies,
      dateOfEstablishment: init.date_of_establishment ? momentTimezone(init.date_of_establishment) : undefined,
      companyWebsiteUrl: init.company_website_url,
      email: init.email,
      policies: init.policies ? init.policies.map(Policy.fromNetwork) : undefined,
      cellphone: init.cellphone
        ? typeof init.cellphone === 'string'
          ? toCellphoneObject(init.cellphone)
          : init.cellphone
        : undefined,
      phoneOther: init.phone_other
        ? typeof init.phone_other === 'string'
          ? toCellphoneObject(init.phone_other)
          : init.phone_other
        : undefined,
      policyIds: init.policy_ids,
      appData: init.app_data,
      createdBy: init.created_by,
      address: init.address
        ? {
            googlePlaceId: init.address.google_place_id,
            geoCoordinatesLatitude: init.address.geo_coordinates_latitude,
            geoCoordinatesLongitude: init.address.geo_coordinates_longitude,
            line1: init.address.line_1,
            line2: init.address.line_2,
            suburb: init.address.suburb,
            city: init.address.city,
            country: init.address.country,
            areaCode: init.address.area_code,
          }
        : undefined,
    });
  }

  public toNetworkPolicyholder() {
    return {
      first_name: this.firstName,
      middle_name: this.middleName,
      last_name: this.lastName,
      email: this.email,
      cellphone: this.cellphone !== undefined ? this.cellphone.number : undefined,
      phone_other: this.phoneOther !== undefined ? this.phoneOther.number : undefined,
      gender: this.gender,
      title: this.title,
      date_of_birth: this.dateOfBirth ? momentTimezone(this.dateOfBirth).format('YYYYMMDD') : null,
      company_name: this.companyName,
      registration_number: this.registrationNumber,
      date_of_establishment: this.dateOfEstablishment
        ? momentTimezone(this.dateOfEstablishment).format('YYYYMMDD')
        : null,
      subsidiary_companies: this.subsidiaryCompanies,
      contact_position: this.contactPosition,
      company_website_url: this.companyWebsiteUrl,
      type: this.type,
      address:
        this.address && this.address.line1
          ? {
              google_place_id: this.address.googlePlaceId || undefined,
              geo_coordinates_latitude: this.address.geoCoordinatesLatitude || undefined,
              geo_coordinates_longitude: this.address.geoCoordinatesLongitude || undefined,
              line_1: this.address.line1,
              line_2: this.address.line2,
              suburb: this.address.suburb,
              city: this.address.city,
              area_code: this.address.areaCode,
              country: this.address.country,
            }
          : undefined,
    };
  }

  public toSubmitModel() {
    if (this.type === PolicyholderEntityType.Company) {
      return {
        type: PolicyholderEntityType.Company,
        first_name: this.firstName,
        middle_name: this.middleName || null,
        last_name: this.lastName,
        email: this.email || null,
        title: this.title || null,
        cellphone: this.cellphone && this.cellphone.number ? this.cellphone.number : null,
        phone_other: this.phoneOther && this.phoneOther.number ? this.phoneOther.number : null,
        registration_number: this.registrationNumber || null,
        company_website_url: this.companyWebsiteUrl || null,
        contact_position: this.contactPosition || null,
        date_of_establishment: this.dateOfEstablishment
          ? momentTimezone(this.dateOfEstablishment).format('YYYYMMDD')
          : null,
        subsidiaryCompanies: this.subsidiaryCompanies,
        company_name: this.companyName || null,
        address:
          this.address && this.address.line1
            ? {
                google_place_id: this.address.googlePlaceId,
                geo_coordinates_latitude: this.address.geoCoordinatesLatitude,
                geo_coordinates_longitude: this.address.geoCoordinatesLongitude,
                line_1: this.address.line1,
                line_2: this.address.line2 || null,
                suburb: this.address.suburb,
                city: this.address.city,
                area_code: this.address.areaCode,
                country: this.address.country,
              }
            : undefined,
      };
    }

    const data = {
      first_name: this.firstName,
      middle_name: this.middleName || null,
      last_name: this.lastName,
      email: this.email || null,
      title: this.title || null,
      cellphone: this.cellphone && this.cellphone.number ? this.cellphone.number : null,
      phone_other: this.phoneOther && this.phoneOther.number ? this.phoneOther.number : null,
      id: this.identification || null,
      address:
        this.address && this.address.line1
          ? {
              google_place_id: this.address.googlePlaceId,
              geo_coordinates_latitude: this.address.geoCoordinatesLatitude,
              geo_coordinates_longitude: this.address.geoCoordinatesLongitude,
              line_1: this.address.line1,
              line_2: this.address.line2 || null,
              suburb: this.address.suburb,
              city: this.address.city,
              area_code: this.address.areaCode,
              country: this.address.country,
            }
          : undefined,
    };

    if (this.identification?.type === IdentificationType.Id && this.identification.country === 'ZA') {
      return data;
    }

    if (this.gender) {
      (data as any).gender = this.gender;
    }

    if (this.dateOfBirth) {
      (data as any).date_of_birth = this.dateOfBirth.format('YYYYMMDD');
    }

    return data;
  }

  public static New() {
    return new Policyholder({
      type: PolicyholderEntityType.Individual,
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      identification: {
        type: IdentificationType.Id,
        number: '',
      } as any,
      dateOfBirth: undefined, // to ensure it doesn't default the DOB inputs to today's date
      isFromLead: false,
      createdAt: momentTimezone(),
      updatedAt: momentTimezone(),
      policyIds: [],
      policyholderId: undefined as any, // speak to allister
      createdBy: {
        id: uuid().toString(), // speak to allister
        type: CreatedByType.System, // speak to allister
      },
    });
  }

  public static NewCompany() {
    const p = new Policyholder({
      type: PolicyholderEntityType.Individual,
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      updatedAt: momentTimezone(),
      identification: {
        type: IdentificationType.Passport,
        country: 'ZA',
        number: `1234${Math.floor(Math.random() * 100000)}`,
        expirationDate: undefined,
      },
      dateOfBirth: momentTimezone('1993-01-01'),
      isFromLead: false,
      createdAt: momentTimezone(),
      policyIds: [],
      policyholderId: uuid().toString(),
      createdBy: {
        id: uuid().toString(), // speak to allister
        type: CreatedByType.System, // speak to allister
      },
    });
    return p;
  }

  public static fromLead(lead: Lead) {
    const p = new Policyholder({
      policyholderId: uuid(),
      type: lead.type as any,
      createdAt: momentTimezone(),
      updatedAt: momentTimezone(),
      identification: lead.identification,
      dateOfBirth: lead.dateOfBirth ? momentTimezone(lead.dateOfBirth) : undefined,
      gender: lead.gender as Gender,
      title: undefined,
      firstName: lead.firstName || '',
      middleName: lead.middleName || '',
      lastName: lead.lastName || '',
      companyName: lead.companyName,
      registrationNumber: lead.registrationNumber,
      email: lead.email,
      cellphone: lead.cellphone
        ? typeof lead.cellphone === 'string'
          ? {
              country: 'ZA',
              number: toNationalFormat(lead.cellphone),
            }
          : lead.cellphone
        : undefined,
      phoneOther: lead.phoneOther
        ? typeof lead.phoneOther === 'string'
          ? {
              country: 'ZA',
              number: toNationalFormat(lead.phoneOther),
            }
          : lead.phoneOther
        : undefined,
      policyIds: [],
      appData: lead.appData,
      createdBy: lead.createdBy,
      address: lead.address
        ? {
            line1: lead.address.line1,
            line2: lead.address.line2,
            suburb: lead.address.suburb,
            city: lead.address.city,
            country: lead.address.country,
            areaCode: lead.address.areaCode,
          }
        : undefined,
    });

    return p;
  }
}

export class NetworkPolicyholder {
  policyholder_id: string;
  type: PolicyholderEntityType;
  first_name: string;
  middle_name?: string;
  last_name: string;
  company_name?: string;
  registration_number?: string;
  contact_position?: string;
  subsidiary_companies?: string[];
  date_of_establishment?: Moment;
  company_website_url?: string;
  id?: NetworkPolicyholderIdentification;
  email?: string;
  cellphone?: string;
  phone_other?: string;
  date_of_birth?: string;
  gender?: Gender;
  title?: Title;
  created_at: string;
  updated_at: string;
  app_data?: object;
  policy_ids: string[];
  created_by: RequestedBy;
  address?: NetworkAddress;
  policies?: NetworkPolicy[];
}

export function formatCellphoneNumbers(policyholder: Policyholder) {
  const parsedCellphone =
    policyholder.cellphone && policyholder.cellphone.number ? toInternationalObject(policyholder.cellphone) : undefined;

  const internationalCellphone = parsedCellphone && parsedCellphone.number ? parsedCellphone : undefined;

  const parsedCellphoneOther =
    policyholder.phoneOther && policyholder.phoneOther.number
      ? toInternationalObject(policyholder.phoneOther)
      : undefined;

  const internationalCellphoneOther =
    parsedCellphoneOther && parsedCellphoneOther.number ? parsedCellphoneOther : undefined;

  const identity: Identification | undefined =
    policyholder.cellphone && policyholder.identification?.type === IdentificationType.Cellphone
      ? {
          number: toInternationalFormat({
            number: policyholder.identification.number,
            country: policyholder.identification.country,
          }) as string,
          country: policyholder.identification.country,
          type: policyholder.identification.type,
        }
      : policyholder.identification;

  const updatedPolicyholder = new Policyholder({
    ...policyholder,
    cellphone: internationalCellphone,
    identification: identity,
    phoneOther: internationalCellphoneOther,
  });

  return updatedPolicyholder;
}
