import momentTimezone, { Moment } from 'moment-timezone';
import { Construct } from '../../models/construct';
import { CreatedBy } from '../../models';

export class PolicyCoveredItems {
  public coveredItemId: string;
  public organizationId: string;
  public coverType: CoverType;
  public createdAt: Moment;
  public createdBy: CreatedBy;
  public monthlyPremium: number;
  public environment: string;
  public policyId: string;
  public updatedAt: Moment;
  public archivedAt: Moment;
  public data: any;
  public coverPeriods: any;

  constructor(init: Construct<PolicyCoveredItems>) {
    Object.assign(this, init);
  }

  public static fromNetwork(init: NetworkPolicyCoveredItems) {
    return new PolicyCoveredItems({
      coveredItemId: init.covered_item_id,
      organizationId: init.organization_id,
      environment: init.environment,
      policyId: init.policy_id,
      coverType: init.cover_type,
      monthlyPremium: init.monthly_premium,
      createdAt: momentTimezone(init.created_at),
      updatedAt: momentTimezone(init.updated_at),
      createdBy: init.created_by,
      archivedAt: momentTimezone(init.archived_at),
      data: init.data,
      coverPeriods:
        init.cover_periods &&
        init.cover_periods.map((cp: any) => ({
          id: cp.id,
          organizationId: cp.organization_id,
          startDate: cp.start_date,
          endDate: cp.end_date,
          premium: cp.premium,
        })),
    });
  }
}

export interface NetworkPolicyCoveredItems {
  covered_item_id: string;
  organization_id: string;
  environment: string;
  policy_id: string;
  cover_type: CoverType;
  monthly_premium: number;
  created_at: string;
  updated_at: string;
  created_by: CreatedBy;
  archived_at: string;
  data: any;
  cover_periods: any;
}

export enum CoverType {
  Monthly = 'monthly',
  OnDemand = 'on_demand',
}
