import React from 'react';
import { Multi } from './form-element-multi';
import { Switch } from './form-element-switch';
import { Checkbox } from './form-element-checkbox';
import { Select } from './form-element-select';
import { Text } from './form-element-text';
import { Date } from './form-element-date';
import { FormValuesWithKeys } from './form-keyed';
import { FormMultiInput, MultiInputType, MultiInput, MultiInputStep } from './form-multi-input';
import { Radio } from './form-element-radio';

export class FormElementFactory {
  static mapToFormElement(
    step: MultiInputStep,
    value: FormValuesWithKeys,
    name: string,
    input: FormMultiInput,
    autoFocus: boolean,
    required = true,
    currency = 'ZAR',
  ) {
    const defaultValue = value.value(name);
    const key = value.key(name);
    const disabled = value.disable;
    const commonProps = {
      key,
      id: key,
      name,
      label: input.label,
      required,
      defaultValue,
      disabled,
    };
    if (
      input.type === MultiInputType.Text ||
      input.type === MultiInputType.Number ||
      input.type === MultiInputType.SAID ||
      input.type === MultiInputType.Email
    ) {
      return (
        <Text
          type={input.type}
          min={input.min}
          max={input.max}
          step={input.step}
          isCurrency={input.currency} // the backend has a boolean set on the schema for currency, this should be isCurrency
          currency={currency} // This is coming from the form props or defaulting to ZAR
          minLength={input.minLength}
          maxLength={input.maxLength}
          pattern={input.pattern}
          checkSum={input.checksum}
          autoFocus={autoFocus}
          {...commonProps}
        />
      );
    }

    if (input.type === MultiInputType.Date) {
      return <Date {...commonProps} />;
    }

    if (input.type === MultiInputType.Switch) {
      return <Switch {...commonProps} />;
    }

    if (input.type === MultiInputType.Checkbox) {
      return <Checkbox inline {...commonProps} />;
    }

    if (input.type === MultiInputType.Multi) {
      return (
        <Multi
          schema={(input.schema as MultiInput[]).filter(
            (x) => x.inputStep === step || x.inputStep === MultiInputStep.All,
          )}
          requireAtLeast={input.requireAtLeast}
          requireAtMost={input.requireAtMost}
          referenceValues={value}
          preventAddOrUpdate={input.preventAddOrUpdate || step === MultiInputStep.Application}
          addAnotherLabel={input.addAnotherLabel}
          {...commonProps}
        />
      );
    }

    if (input.type === MultiInputType.Radio && input.options) {
      return <Radio options={input.options} autoFocus={autoFocus} {...commonProps} />;
    }

    return <Select options={input.options} autoFocus={autoFocus} {...commonProps} />;
  }
}
