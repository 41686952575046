import { Event, DisplayEvent } from '../event';

export enum PolicyAlteredEventType {
  SumAssuredUpdated = 'sum_assured_updated',
  BeneficiariesUpdated = 'beneficiaries_updated',
}

export class PolicyAlteredEvent extends Event<any> {
  //
}

export class PolicyAlteredDisplayEvent extends DisplayEvent {
  getTitle = () => 'Policy altered';
  renderFull = () => null;
}
