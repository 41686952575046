import momentTimezone, { Moment } from 'moment-timezone';
import { omit } from 'lodash';
import { toCellphoneObject, toInternationalFormat } from 'insurance/shared/utils';
import { Gender } from '../../models/gender';
import { IdentificationType, Policyholder } from '../../policyholders/domain/policyholder';
import { Identification } from '../../covered-people/domain/policy-covered-person';
import { BeneficiaryPaymentDetails, Bank, AccountType, PaymentType } from '../../payments/domain/payee-bank-details';
import { Policy } from '../../policies/domain/policy';
import { isValidEmail, isValidIdNumber } from '../../../components';
import { Title } from '../../general/title';
import { Cellphone } from '../../general';
import { BeneficiaryEntityType } from './beneficiary-entity-type';
import { snakeToCamel, camelToSnake } from '../../utils';

export class NetworkBeneficiary {
  public beneficiary_id: string;
  public policyholder_id?: string;
  public first_name: string;
  public last_name: string;
  public percentage: number;
  public id?: Identification;
  public date_of_birth?: string;
  public email?: string;
  public cellphone?: string;
  public payment_details?: BeneficiaryPaymentDetails;
  public company_name?: string;
  public registration_number?: string;
  public type?: BeneficiaryEntityType;
  public relationship: string;
  public gender?: Gender;
  public title?: Title;
}

export const isBeneficiaryValid = (beneficiary: Beneficiary) => {
  let valid = beneficiary.firstName && beneficiary.firstName.length > 2;
  valid = valid && beneficiary.lastName && beneficiary.lastName.length > 2;
  valid = valid && beneficiary.email && isValidEmail(beneficiary.email);

  if (beneficiary.id) {
    valid =
      valid &&
      beneficiary.id.number &&
      (beneficiary.id.type === 'id' ? isValidIdNumber(beneficiary.id.number) : beneficiary.id.number.length > 2);
    valid =
      valid && (beneficiary.id.type !== 'id' ? beneficiary.id.country && beneficiary.id.country.length === 2 : true);
  }

  valid = valid && beneficiary.percentage > 0 && beneficiary.percentage <= 100;

  return valid;
};

export const getEmptyBeneficiary = (): Beneficiary => {
  return new Beneficiary({
    id: {
      type: IdentificationType.Id,
      number: '',
      country: 'ZA',
    },
    cellphone: {
      country: 'ZA',
      number: '',
    },
    paymentDetails: {
      type: PaymentType.EFT,
      details: {
        bankName: Bank.ABSA,
        branchCode: '',
        accountType: AccountType.Cheque,
        accountNumber: '',
      },
    },
  });
};

export class Beneficiary {
  public beneficiaryId: string;
  public policyholderId?: string;
  public firstName: string;
  public lastName: string;
  public percentage: number;
  public title?: Title;
  public gender?: Gender;
  public id?: Identification;
  public dateOfBirth?: Moment;
  public email: string;
  public relationship: string;
  public cellphone?: Cellphone;
  public paymentDetails?: BeneficiaryPaymentDetails;
  public companyName?: string;
  public registrationNumber?: string;
  public entityType?: BeneficiaryEntityType;

  constructor(init: Partial<Beneficiary>) {
    Object.assign(this, init);
  }

  getCountry = () => {
    return (this.id && this.id.country.toLowerCase()) || 'za';
  };

  cellNumber = () => {
    return this.cellphone ? this.cellphone.number : undefined;
  };

  // needs to change
  prettyCellNumber = () => {
    const number = this.cellNumber();
    if (!number) {
      return undefined;
    }

    // Move to central utils
    return toInternationalFormat(number);
  };

  fullname = () => {
    if (!this.firstName || !this.lastName) {
      return undefined;
    }

    return `${this.firstName} ${this.lastName}`;
  };

  isComplete = () => {
    if (!this.firstName || !this.lastName) {
      return false;
    }

    if (!this.hasPaymentDetails()) {
      return false;
    }

    if (!this.relationship) {
      return false;
    }

    return (this.cellphone !== undefined && this.cellphone.number !== undefined) || this.email !== undefined;
  };

  hasPaymentDetails = () => {
    return (
      this.paymentDetails &&
      this.paymentDetails.details &&
      this.paymentDetails.details.bankName !== undefined &&
      this.paymentDetails.details.accountNumber !== undefined
    );
  };

  prettyRelationship = () => {
    if (!this.relationship) {
      return undefined;
    }

    return this.relationship[0].toUpperCase() + this.relationship.replace(/_/gi, ' ').substr(1);
  };

  public static fromNetwork(init: NetworkBeneficiary) {
    return new Beneficiary({
      beneficiaryId: init.beneficiary_id,
      cellphone: init.cellphone
        ? typeof init.cellphone === 'string'
          ? toCellphoneObject(init.cellphone)
          : init.cellphone
        : undefined,
      dateOfBirth: init.date_of_birth ? momentTimezone(init.date_of_birth) : undefined,
      companyName: init.company_name,
      email: init.email,
      entityType: init.type,
      gender: init.gender,
      firstName: init.first_name,
      lastName: init.last_name,
      id: !init.id ? { type: IdentificationType.Id, country: 'ZA', number: '' } : init.id,
      relationship: init.relationship,
      registrationNumber: init.registration_number,
      title: init.title,
      policyholderId: init.policyholder_id,
      percentage: init.percentage,
      paymentDetails: init.payment_details && snakeToCamel(init.payment_details),
    });
  }

  toNetwork(): NetworkBeneficiary {
    return {
      beneficiary_id: this.beneficiaryId,
      policyholder_id: this.policyholderId,
      first_name: this.firstName,
      last_name: this.lastName,
      company_name: this.companyName,
      type: this.entityType,
      registration_number: this.registrationNumber,
      percentage: this.percentage,
      id: this.id,
      relationship: this.relationship,
      date_of_birth: this.dateOfBirth && this.dateOfBirth.toISOString(),
      email: this.email,
      cellphone: this.cellphone ? toInternationalFormat(this.cellphone) : undefined,
      payment_details: this.paymentDetails
        ? {
            type: this.paymentDetails.type,
            details: this.paymentDetails.details,
          }
        : undefined,
    };
  }

  public static fromPolicyholder(p: Policyholder, policy: Policy) {
    return new Beneficiary({
      beneficiaryId: policy.beneficiaries[0].beneficiaryId,
      firstName: p.firstName,
      lastName: p.lastName,
      id: p.identification,
      title: p.title,
      cellphone: p.cellphone,
      policyholderId: p.policyholderId,
      email: p.email,
      percentage: 100,
      gender: p.gender,
      paymentDetails: policy.beneficiaries[0].paymentDetails,
      relationship: policy.beneficiaries[0].relationship,
    });
  }
}

export const beneficiaryToNetwork = (beneficiary: Beneficiary) => {
  const networkBeneficiary = camelToSnake(
    omit(
      { ...beneficiary, type: beneficiary.entityType },
      ...[
        'beneficiaryId',
        ...(beneficiary.policyholderId ? ['id', 'firstName', 'lastName', 'email', 'cellphone'] : []),
      ],
      ...[...(beneficiary.id && !beneficiary.id.number ? ['id'] : [])],
      ...(beneficiary.entityType === BeneficiaryEntityType.Company ? ['id'] : []),
    ),
  );
  return networkBeneficiary;
};
