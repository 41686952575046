import { ProductModule } from 'insurance/product-modules/domain/product-module';
import { Context } from '../framework';
import { accountStore } from '../account/stores/account-store';
import { Organization } from './domain/organization';
import { OrganizationUser } from './domain/organization-user';
import { getApiUrl, rootApiRequest } from '../shared/api';
import { clearLocalStorage } from '../utils';

export class OrganizationsApi {
  public static async getOrganizations() {
    const token = accountStore.loginToken;
    // potentially cut the request if the loginToken was not found
    const result = await fetch(`${getApiUrl()}/v1/organizations?feature_flags=true`, {
      headers: { authorization: `Bearer ${token}` },
    });
    const json = await result.json();

    if (json.error.type === 'authentication_error') {
      clearLocalStorage();
      window.location.href = '/';
    }
    return json.map(Organization.fromNetwork);
  }

  public static async getOrganizationProductModules(params: { liveDefinition: boolean }): Promise<ProductModule[]> {
    const { liveDefinition } = params;
    const result = await rootApiRequest(`product-modules?live_definition=${liveDefinition.toString()}`, {
      method: 'GET',
    });

    return result.map(ProductModule.fromNetwork);
  }

  public static async updateOrganization(params: { data: any }) {
    const { data } = params;
    const result = await rootApiRequest(
      '',
      {
        method: 'PATCH',
        noOrganization: true,
        body: {
          ...data,
        },
      },
      `/v1/organizations/${Context.organizationId}`,
    );

    return Organization.fromNetwork(result);
  }

  public static async createOrganization(params: { name: string; website?: string; description?: string }) {
    const { name, website, description } = params;

    const result = await rootApiRequest(
      '',
      {
        method: 'POST',
        noOrganization: true,
        body: {
          name,
          website,
          description,
        },
      },
      '/v1/organizations',
    );

    return Organization.fromNetwork(result);
  }

  public static async getOrganization() {
    const token = accountStore.loginToken;
    const { organizationId } = Context;

    // potentially cut the request if the loginToken was not found
    const result = await fetch(`${getApiUrl()}/v1/organizations/${organizationId}`, {
      headers: { authorization: `Bearer ${token}` },
    });
    const json = await result.json();
    return Organization.fromNetwork(json);
  }

  public static async getSelfForOrganization() {
    const token = accountStore.loginToken;
    const { organizationId } = Context;

    // potentially cut the request if the loginToken was not found
    const result = await fetch(`${getApiUrl()}/v1/organizations/${organizationId}/users/self`, {
      headers: { authorization: `Bearer ${token}` },
    });
    const json = await result.json();

    return OrganizationUser.fromNetwork(json);
  }

  // TODO move to actions
  public static async enableOrganizationProductModule(params: {
    organizationProductModule: ProductModule;
  }): Promise<ProductModule[]> {
    const { organizationProductModule } = params;
    const result = await rootApiRequest(`product-modules/${organizationProductModule.productModuleId}/enable`, {
      method: 'POST',
      body: {},
    });

    return result.map(ProductModule.fromNetwork);
  }

  public static async flushOrganization(): Promise<ProductModule[]> {
    const result = await rootApiRequest(
      `insurance/flush`,
      {
        method: 'POST',
        body: {},
      },
      '/v1/apps/',
    );

    return result.map(ProductModule.fromNetwork);
  }
}
