import React from 'react';
import { Row, Col, FormGroup, FormControl, Alert, FormLabel, Button } from 'react-bootstrap';
import SMSOtpWhite from '../images/sms-otp-white';
import { AccountApi } from '../account-api';
import { buttonText } from '../../utils';

interface Props {
  nextStep: () => void;
  login: any;
  previousStep: any;
  appToken: any;
  setState: any;
  userId: any;
  email: any;
  error?: string;
  isLoadingNext?: boolean;
  loginToken: string;
}

interface State {
  code?: string;
  isLoadingResendOtp: boolean;
  alertMessage?: string;
}

export class SmsOtpPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoadingResendOtp: false,
    };
  }

  async openNext(e: any) {
    e.preventDefault();
    this.props.login(this.state.code);
  }

  async sendOtp() {
    const { loginToken } = this.props;
    this.setState({ isLoadingResendOtp: true }, async () => {
      if (!loginToken) {
        return (window.location.href = '/');
      }

      try {
        await AccountApi.resendOtp({
          email: this.props.email,
          userId: this.props.userId,
          loginToken,
        });
        this.setState({ isLoadingResendOtp: false });
      } catch (error) {
        this.setState({ isLoadingResendOtp: false });
        console.error(error);
      }
    });
  }

  async resendOtp() {
    await this.sendOtp();
    this.setState({ alertMessage: 'OTP resent' });
  }

  componentDidMount() {
    this.sendOtp();
  }

  handleChange(e: any) {
    const code = e.target.value;
    this.setState({ code });
  }

  render() {
    const message = 'Check your phone for an SMS.';
    const icon = <SMSOtpWhite />;

    return (
      <Row>
        <Col sm={5}>
          <div>
            {icon}
            <h2>SMS based 2FA</h2>
            <p>{message}</p>
          </div>
        </Col>
        <Col sm={7} style={{ background: '#f5f8fa' }}>
          <div style={{ flex: 'auto', padding: 30 }}>
            {this.state.alertMessage ? <Alert variant='success'>{this.state.alertMessage}</Alert> : undefined}
            <form onSubmit={this.openNext.bind(this)}>
              <FormGroup controlId='formInputOTP'>
                <FormLabel>Enter verification code</FormLabel>
                <FormControl
                  type='text'
                  placeholder='6-digit code'
                  autoComplete='off'
                  onChange={this.handleChange.bind(this)}
                  value={this.state.code}
                />
              </FormGroup>
              {this.props.error && <p style={{ color: '#fd3238' }}>{this.props.error}</p>}
              <Button variant='primary' type='submit' disabled={!this.state.code || !!this.props.isLoadingNext} block>
                {buttonText({
                  loading: !!this.props.isLoadingNext,
                  text: 'Next',
                })}
              </Button>
              <Button variant='link' onClick={this.resendOtp.bind(this)} block>
                {buttonText({
                  loading: this.state.isLoadingResendOtp,
                  text: 'Resend OTP',
                })}
              </Button>
            </form>
          </div>
        </Col>
      </Row>
    );
  }
}
