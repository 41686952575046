import React from 'react';
import { Event, DisplayEvent, DisplayEventProps } from '../event';
import { CallStatus, prettyCallStatus } from '../../../../calls/domain/call';

export class CallFailedEvent extends Event<any> {
  public readonly callStatus: CallStatus;
}

type CallFailedDisplayEventProps = DisplayEventProps<CallFailedEvent>;

export class CallFailedDisplayEvent extends DisplayEvent<CallFailedDisplayEventProps> {
  getTitle = () => 'Call unsuccessful';
  renderFull = () => {
    return (
      <>Policyholder tried to call in, but was unsuccessful. Reason: {prettyCallStatus(this.props.event.callStatus)}.</>
    );
  };
}
