import { IEntity } from '../entities';

export enum StateType {
  Loading,
  Loaded,
  Empty,
  Error,
}

export type CombinedEntityStateType = IEntity | IEntity[] | (() => IEntity | null) | (() => IEntity[]);
