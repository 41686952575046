import React from 'react';
import { withRouter } from 'react-router-dom';
import { Event, DisplayEvent } from '../event';
import { ComplaintEventType } from './complaint-event-type';
import { LinkButton } from '../../../../../components';
import { navigateTo } from '../../../../../core';
import { Context } from '../../../../framework';
import { Complaint } from '../../../../complaints/domain/complaint';

export class ComplaintOpenedEvent extends Event<any> {
  complaintId: string;

  public static New(reason: string, complaint: Complaint) {
    return new ComplaintOpenedEvent(
      {
        type: ComplaintEventType.ComplaintOpened,
      } as any,
      complaint,
    );
  }
}

export class ComplaintOpenedDisplayEventImpl extends DisplayEvent {
  getTitle = () => 'Complaint opened';
  renderFull = () => {
    const { history } = this.props as any;
    const complaintId = Context.secondId;
    if (this.props.event?.entity?.complaintId === complaintId) {
      return null;
    }
    return (
      <>
        <LinkButton
          onClick={() =>
            navigateTo({
              url: `/orgs/${Context.organizationId}/insurance/complaints/${this.props.event?.entity?.complaintId}`,
              history,
            })
          }
        >
          {' '}
          View complaint
        </LinkButton>
      </>
    );
  };
}

export const ComplaintOpenedDisplayEvent = withRouter(ComplaintOpenedDisplayEventImpl as any);
