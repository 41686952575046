import React from 'react';
import { observer, inject } from 'mobx-react';
import { Theme } from 'rootstrap/components/button';
import { AccountStore } from '../insurance/account/stores/account-store';
import { OTPType } from '../insurance/account/domain/login-result';
import { Form, FormGroupHalf, Text, FormValues, LinkButton, PrimaryButton } from '../components';
import { buttonText } from '../insurance/utils';
import { Alert } from '../components/alerts/alerts';

interface TwoFAProps {
  onSubmit: (twoFACode?: string) => void;
  twoFAError: boolean;
  accountLockedError: boolean;
  loading: boolean;
  email: string;
  password: string;
  appToken?: string;
  close?: () => void;
}

interface Injected extends TwoFAProps {
  accountStore: AccountStore;
}

interface TwoFaData {
  twoFACode: string;
}
interface TwoFAState {
  form: TwoFaData;
  valid: boolean;
  touched: boolean;
  resendingOtp?: boolean;
}
@inject('accountStore')
@observer
export class TwoFA extends React.Component<TwoFAProps, TwoFAState> {
  constructor(props: TwoFAProps) {
    super(props);
    this.state = {
      form: {
        twoFACode: '',
      },
      valid: false,
      touched: false,
    };
  }

  get injected() {
    return this.props as Injected;
  }

  submit = () => {
    const { form, valid } = this.state;
    if (valid && !this.props.loading) {
      this.setState({ resendingOtp: false });
      this.props.onSubmit(form.twoFACode);
    }
  };

  resendOtp = async () => {
    this.setState({ resendingOtp: false });
    const { accountStore, email, password, appToken } = this.injected;
    if (appToken) {
      await accountStore.resetPassword({
        password,
        appToken,
      });
    } else {
      await accountStore.tryLogin({ email, password });
    }

    this.setState({ resendingOtp: true });
  };

  resendOtpAlert = () => {
    return (
      <>
        <br />
        <Alert style={{ marginBottom: '0px' }} color='success'>
          A new OTP has been sent. Please check your phone.
        </Alert>
      </>
    );
  };

  resendOtpButton = () => {
    return (
      <div>
        <LinkButton style={{ color: '#4078fa', cursor: 'pointer' }} onClick={this.resendOtp}>
          Resend OTP
        </LinkButton>
      </div>
    );
  };

  changed = (valid: boolean, form: FormValues) => {
    const twoFACode = form.value<string>('twoFACode');
    const data = {
      twoFACode,
    };

    this.setState({ form: data, valid });

    if (valid && twoFACode.replace(/[^0-9.]/g, '').length === 6) {
      this.props.onSubmit(twoFACode);
      this.setState({ resendingOtp: false });
    }
  };

  initialized = (valid: boolean, form: FormValues) => {
    const data = {
      twoFACode: form.value<string>('twoFACode'),
    };

    this.setState({ form: data, valid });
  };

  render() {
    const { accountStore, appToken } = this.injected;
    const { resendingOtp } = this.state;
    const loginTypeApp = accountStore.loginType && accountStore.loginType === OTPType.App;
    const authenticationMessage = loginTypeApp ? 'Check your 2FA app' : 'Check your phone for an SMS';

    return (
      <div>
        <h3>2 factor authentication</h3>
        <p>
          <b id='authType'>{accountStore.loginType && authenticationMessage}</b>
        </p>
        {this.props.twoFAError && <Alert color='danger'>Invalid 6 digit verification code.</Alert>}
        {this.props.accountLockedError && (
          <Alert color='danger'>Your account has been locked. Please reset your password.</Alert>
        )}
        <Form
          onSubmit={this.submit}
          onValueChanged={this.changed}
          initialized={this.initialized}
          touched={this.state.touched}
        >
          <FormGroupHalf>
            <Text
              id='otp'
              type='text'
              autoFocus
              name='twoFACode'
              label='6 digit verification code'
              defaultValue=''
              required
              minLength={6}
              maxLength={7}
            />
          </FormGroupHalf>
          <div>
            {!loginTypeApp && this.resendOtpButton()}
            {resendingOtp && this.resendOtpAlert()}
          </div>
          <br />
          <br />
          <PrimaryButton
            type='submit'
            disabled={this.props.loading}
            style={{ width: '100%', height: 48, marginBottom: 15 }}
            id='twoFaButton'
            className='btn btn-primary'
          >
            {buttonText({ loading: this.props.loading, text: appToken ? 'Change password' : 'Sign in' })}
          </PrimaryButton>
          <PrimaryButton
            outline
            style={{ width: '100%', height: 48, marginLeft: 0, marginRight: 0 }}
            theme={Theme.secondary}
            onClick={this.props.close}
          >
            Back
          </PrimaryButton>
        </Form>
      </div>
    );
  }
}
