import React from 'react';
import { FormValues, Form } from '../forms/form';
import { Modal, ModalHeader, ModalBody, ModalFooter } from './modals';
import { PrimaryButton } from '../buttons/buttons';

export interface FormModalProps {
  open: boolean;
  close: () => void;
  valid?: boolean;
}

export interface FormModalState {
  valid: boolean;
  values: FormValues;
  loading: boolean;
  error?: string;
}

interface Error {
  error: string;
}

interface AsyncAction {
  onResolve: (callback: any) => any;
  onReject: (callback: any) => any;
}

export abstract class FormModal<
  P extends FormModalProps = FormModalProps,
  S extends FormModalState = FormModalState
> extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    (this as any).state = {
      valid: Boolean(props.valid),
      loading: false,
      values: {},
    };
  }

  _onValueChanged = (valid: boolean, values: FormValues) => {
    this.setState({ valid, values });
    this.onValueChanged();
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onValueChanged(a?: any, b?: any) {}

  submitClicked = () => {
    this.setState({ loading: true });

    const promise = this.submit();
    if (promise instanceof Promise) {
      promise.then(this.success).catch((error: Error) => this.setState({ loading: false, error: error.error }));
    } else {
      promise.onResolve(this.success).onReject((error: Error) => this.setState({ loading: false, error: error.error }));
    }
  };

  success = () => {
    this.setState(
      {
        valid: false,
        loading: false,
        error: undefined,
        values: new FormValues(),
      },
      () => {
        this.props.close();
      },
    );
  };

  abstract submit(): Promise<any> | AsyncAction;

  toggle = () => {
    if (this.state.loading) {
      return;
    }

    this.props.close();
  };

  isDanger = () => false;

  render() {
    return (
      <Modal animated={this.state.loading} danger={this.isDanger()} isOpen={this.props.open} toggle={this.toggle}>
        <ModalHeader toggle={this.props.close}>{this.title()}</ModalHeader>
        <ModalBody>
          {this.state.error && <div className='modal-body-error'>{this.state.error}</div>}
          <Form onValueChanged={this._onValueChanged} disabled={this.state.loading}>
            {this.renderForm()}
          </Form>
        </ModalBody>
        <ModalFooter>
          <PrimaryButton
            loading={this.state.loading}
            block
            disabled={!this.state.valid || this.state.loading}
            onClick={this.submitClicked}
          >
            {this.state.loading ? this.submittingText() : this.primaryButtonText()}
          </PrimaryButton>
        </ModalFooter>
      </Modal>
    );
  }

  abstract renderForm(): JSX.Element;
  abstract primaryButtonText: () => string;
  abstract submittingText: () => string;
  abstract title: () => string;
}
