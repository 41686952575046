import { accountStore } from '../../account/stores/account-store';
import { Context } from '../../framework';
import { getApiUrl } from '../../shared/api';

export class AttachmentApi {
  public static async downloadFromUrl(params: { url: string }) {
    const token = accountStore.loginToken;
    const fetchOptions = {
      headers: { authorization: `Bearer ${token}` },
    };

    let transformedUrl = '';
    let isTransformed = false;
    const splitUrlBase = params.url.split('/download/');
    if (splitUrlBase.length === 2) {
      const pathBreakdown = splitUrlBase[1].split('/');
      if (pathBreakdown.length > 2) {
        isTransformed = true;
        pathBreakdown.forEach((item, index) => {
          switch (index) {
            case pathBreakdown.length - 2:
              transformedUrl += `${item}%2f`;
              break;
            case pathBreakdown.length - 1:
              transformedUrl += item;
              break;
            default:
              transformedUrl += `${item}/`;
          }
        });
        transformedUrl = `${splitUrlBase[0]}/download/${transformedUrl}`;
      }
    }

    const url = isTransformed ? prependOrganizationToUrl(transformedUrl) : prependOrganizationToUrl(params.url);

    return fetch(url, fetchOptions);
  }

  public static async downloadFromId(params: { fileId: string; fileName: string; extension: string }) {
    const { fileId, extension, fileName } = params;
    const token = accountStore.loginToken;
    const fetchOptions = {
      headers: { authorization: `Bearer ${token}` },
    };

    return fetch(`${getApiUrl()}/v1/files/download/${fileId}/${fileName}.${extension}`, fetchOptions);
  }
}

const prependOrganizationToUrl = (url: string) => {
  url = url.replace('/insurance/policies/', `/apps/${Context.organizationId}/insurance/policies/`);

  url = url.replace('/files/download/', `/apps/${Context.organizationId}/files/download/`);

  return url;
};
