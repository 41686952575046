import { PolicyEventType } from './policy-event-type';
import { NetworkEvent } from '../network-event';
import { Event } from '../event';

import { PolicyActivatedEvent, PolicyActivatedDisplayEvent } from './policy-activated-event';
import { PolicyAlteredEvent, PolicyAlteredDisplayEvent } from './policy-altered-event';
import { PolicyCancelledEvent, PolicyCancelledDisplayEvent } from './policy-cancelled-event';
import { PolicyEmailSentEvent, PolicyEmailSentDisplayEvent } from './policy-email-sent-event';
import { PolicyIssuedEvent, PolicyIssuedDisplayEvent } from './policy-issued-event';
import { PolicyLapsedEvent, PolicyLapsedDisplayEvent } from './policy-lapsed-event';
import { PolicyExpiredEvent, PolicyExpiredDisplayEvent } from './policy-expired-event';
import { PolicyNotTakenUpEvent, PolicyNotTakenUpDisplayEvent } from './policy-not-taken-up-event';
import { PolicyLinkedEvent, PolicyLinkedDisplayEvent } from './policy-linked-event';
import { PolicyReceiptCreatedEvent, PolicyReceiptCreatedDisplayEvent } from './policy-receipt-created-event';
import { PolicyRefundRequestedEvent, PolicyRefundRequestedDisplayEvent } from './policy-refund-requested-event';
import { PolicyScheduleUpdatedEvent, PolicyScheduleUpdatedDisplayEvent } from './policy-schedule-updated-event';
import { PolicyTermsUpdatedEvent, PolicyTermsUpdatedDisplayEvent } from './policy-terms-updated-event';
import { PolicyMonitorRequestEvent, PolicyMonitorRequestDisplayEvent } from './policy-monitor-request-event';
import { Policy } from '../../../../policies/domain/policy';
import { ClaimLinkedEvent, ClaimLinkedDisplayEvent } from './claim-linked-event';
import { ComplaintLinkedEvent, ComplaintLinkedDisplayEvent } from './complaint-linked-event';
import { PolicyImportedEvent, PolicyImportedDisplayEvent } from './policy-imported-event';
import { PolicyRequotedDisplayEvent, PolicyRequotedEvent } from './policy-requoted-event';
import {
  PolicyAlterationPackageAppliedDisplayEvent,
  PolicyAlterationPackageApplicationEvent,
} from './policy-alteration-package-applied-event';
import { PaymentMethodRemovedDisplayEvent, PaymentMethodRemovedEvent } from './payment-method-removed-event';

export class PolicyEventFactory {
  public static fromNetwork(event: NetworkEvent, policy: Policy) {
    switch (event.type) {
      case PolicyEventType.PolicyActivated:
        return new PolicyActivatedEvent(event, policy);
      case PolicyEventType.PolicyAltered:
        return new PolicyAlteredEvent(event as any, policy);
      case PolicyEventType.PolicyCancelled:
        return new PolicyCancelledEvent(event as any, policy);
      case PolicyEventType.PolicyEmailSent:
        return new PolicyEmailSentEvent(event as any, policy);
      case PolicyEventType.PolicyIssued:
        return new PolicyIssuedEvent(event as any, policy);
      case PolicyEventType.PolicyImported:
        return new PolicyImportedEvent(event as any, policy);
      case PolicyEventType.PolicyLapsed:
        return new PolicyLapsedEvent(event as any, policy);
      case PolicyEventType.PolicyExpired:
        return new PolicyExpiredEvent(event as any, policy);
      case PolicyEventType.PolicyNotTakenUp:
        return new PolicyNotTakenUpEvent(event as any, policy);
      case PolicyEventType.PolicyLinked:
        return new PolicyLinkedEvent(event as any, policy);
      case PolicyEventType.PolicyReceiptCreated:
        return new PolicyReceiptCreatedEvent(event as any, policy);
      case PolicyEventType.PolicyRefundRequested:
        return new PolicyRefundRequestedEvent(event as any, policy);
      case PolicyEventType.PolicyScheduleUpdated:
        return new PolicyScheduleUpdatedEvent(event as any, policy);
      case PolicyEventType.PolicyTermsUpdated:
        return new PolicyTermsUpdatedEvent(event as any, policy);
      case PolicyEventType.PolicyRequoted:
        return new PolicyRequotedEvent(event as any, policy);
      case PolicyEventType.PolicyAlterationPackageApplied:
        return new PolicyAlterationPackageApplicationEvent(event as any, policy);
      case PolicyEventType.ClaimLinked:
        return new ClaimLinkedEvent(event as any, policy);
      case PolicyEventType.ComplaintLinked:
        return new ComplaintLinkedEvent(event as any, policy);
      case PolicyEventType.MonitorRequest:
        return new PolicyMonitorRequestEvent(event as any, policy);
      case PolicyEventType.PaymentMethodRemoved:
        return new PaymentMethodRemovedEvent(event as any, policy);
      case PolicyEventType.BeneficiaryUpdated:
      case PolicyEventType.BillingFailure:
      case PolicyEventType.PaymentSuccessful:
      case PolicyEventType.PaymentFailed:
      default:
        return null;
    }
  }

  public static toDisplayEvent(event: Event<any>) {
    switch (event.type) {
      case PolicyEventType.PolicyActivated:
        return PolicyActivatedDisplayEvent;
      case PolicyEventType.PolicyAltered:
        return PolicyAlteredDisplayEvent;
      case PolicyEventType.PolicyCancelled:
        return PolicyCancelledDisplayEvent;
      case PolicyEventType.PolicyEmailSent:
        return PolicyEmailSentDisplayEvent;
      case PolicyEventType.PolicyIssued:
        return PolicyIssuedDisplayEvent;
      case PolicyEventType.PolicyImported:
        return PolicyImportedDisplayEvent;
      case PolicyEventType.PolicyLapsed:
        return PolicyLapsedDisplayEvent;
      case PolicyEventType.PolicyExpired:
        return PolicyExpiredDisplayEvent;
      case PolicyEventType.PolicyNotTakenUp:
        return PolicyNotTakenUpDisplayEvent;
      case PolicyEventType.PolicyLinked:
        return PolicyLinkedDisplayEvent;
      case PolicyEventType.PolicyReceiptCreated:
        return PolicyReceiptCreatedDisplayEvent;
      case PolicyEventType.PolicyRefundRequested:
        return PolicyRefundRequestedDisplayEvent;
      case PolicyEventType.PolicyScheduleUpdated:
        return PolicyScheduleUpdatedDisplayEvent;
      case PolicyEventType.PolicyTermsUpdated:
        return PolicyTermsUpdatedDisplayEvent;
      case PolicyEventType.PolicyRequoted:
        return PolicyRequotedDisplayEvent;
      case PolicyEventType.PolicyAlterationPackageApplied:
        return PolicyAlterationPackageAppliedDisplayEvent;
      case PolicyEventType.ClaimLinked:
        return ClaimLinkedDisplayEvent;
      case PolicyEventType.ComplaintLinked:
        return ComplaintLinkedDisplayEvent;
      case PolicyEventType.MonitorRequest:
        return PolicyMonitorRequestDisplayEvent;
      case PolicyEventType.PaymentMethodRemoved:
        return PaymentMethodRemovedDisplayEvent;
      case PolicyEventType.BeneficiaryUpdated:
      case PolicyEventType.BillingFailure:
      case PolicyEventType.PaymentSuccessful:
      case PolicyEventType.PaymentFailed:
      default:
        return null;
    }
  }
}
