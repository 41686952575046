import * as React from 'react';
import { Event, DisplayEvent } from '../event';
import { ClaimEventType } from './claim-event-type';
import { CreatedByType } from '../../../created-by';
import { Claim } from '../../../../claims/domain/claim';

export class ClaimApprovalAcknowledgedEvent extends Event<any> {
  public static New(claim: Claim, reason?: string) {
    return new ClaimApprovalAcknowledgedEvent(
      {
        type: ClaimEventType.ClaimApprovalAcknowledged,
        requestedBy: {
          type: CreatedByType.System,
        },
        reason,
      } as any,
      claim,
    );
  }
}

export class ClaimApprovalAcknowledgedDisplayEvent extends DisplayEvent {
  getTitle = () => 'Claim approval acknowledged';
  renderFull = () => (
    <div>
      Reason: <br />
      {(this.props.event as any).reason}{' '}
    </div>
  );
}
