import * as React from 'react';
import { Event, DisplayEvent } from '../event';
import { ClaimEventType } from './claim-event-type';
import { CreatedByType } from '../../../created-by';
import { Claim } from '../../../../claims/domain/claim';

export class ClaimRepudiationNotAcknowledgedEvent extends Event<any> {
  public static New(reason: string, claim: Claim) {
    return new ClaimRepudiationNotAcknowledgedEvent(
      {
        type: ClaimEventType.ClaimRepudiationNotAcknowledged,
        requestedBy: {
          type: CreatedByType.System, // TODO
        },
        reason,
      } as any,
      claim,
    );
  }
}

export class ClaimRepudiationNotAcknowledgedDisplayEvent extends DisplayEvent {
  getTitle = () => 'Repudiation not acknowledged';
  renderFull = () => (
    <div>
      Reason: <br />
      {(this.props.event as any).reason}{' '}
    </div>
  );
}
