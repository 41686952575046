import { buildApiUrl } from '../../shared/utils';
import { Environment } from '../../models/environment';
import { apiRequest, getApiBaseUrl } from '../../shared/api';
import { ProductModule, NetworkProductModule } from '../domain/product-module';

export enum ProductModuleIncludes {
  LiveDefinition = 'live_definition',
}

export enum ProductModuleEnabledForEnvironment {
  Sandbox = 'sandbox',
  Live = 'live',
}

export interface ProductModuleFilters {
  enabledForEnvironment: Environment[];
}
export interface GetProductModuleParams {
  filters: ProductModuleEnabledForEnvironment;
}

export const getProductModules = async (params: {
  organizationId: string;
  environment: Environment;
  loginToken: string;
  filters: ProductModuleFilters;
}) => {
  const { environment, loginToken, organizationId, filters } = params;

  const enabledForEnvironment = filters.enabledForEnvironment
    .map((environment) =>
      environment === Environment.Production
        ? ProductModuleEnabledForEnvironment.Live
        : ProductModuleEnabledForEnvironment.Sandbox,
    )
    .join(',');

  const queryParams = {
    enabled_for_environment: enabledForEnvironment,
    live_definition: true,
    draft_definition: true,
  };

  const response: Response = await apiRequest({
    url: buildApiUrl(
      `${getApiBaseUrl({ environment })}/v1/insurance/organizations/${organizationId}/product-modules`,
      queryParams,
    ),
    method: 'get',
    loginToken,
  });

  const networkProductModules: NetworkProductModule[] = await response.json();

  const productModules = networkProductModules.map((networkProductModule) =>
    ProductModule.fromNetwork(networkProductModule),
  );

  return productModules;
};
