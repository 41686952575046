import styled, { css } from 'styled-components/macro';
import { Button, DropdownToggle } from 'reactstrap';
import { Size } from './button';
import { color, Spacing } from '../../global-styles';
import { Dot } from '../spinner/styles';

export const StyledButton = styled.button<{
  size: Size;
  minWidth?: number;
}>`
  border-radius: 4px;
  font-weight: 700;
  color: #fff;
  border: 1px solid ${color.rootBlue};
  height: 32px;
  line-height: 20px;

  ${({ size }) =>
    size === Size.lg &&
    css`
      height: 48px;
      padding-left: ${Spacing.default};
      padding-right: ${Spacing.default};
      min-width: 120px;
    `}

  ${({ size }) =>
    size === Size.default &&
    css`
      padding-left: ${Spacing.sm};
      height: ${Spacing.md};
      padding-right: ${Spacing.sm};
      min-width: 72px;
    `}

  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth}px;
    `}

  &:hover {
    background: #2762ea;
  }
`;

export const PrimaryButton = styled(StyledButton)`
  background: ${color.rootBlue};
  border-color: ${color.rootBlue};

  &:hover {
    background: #2762ea;
  }
`;

export const SecondaryButton = styled(StyledButton)`
  :hover {
    background: rgba(41, 105, 255, 0.08);
  }
  background: transparent;
  border: 1px solid ${color.border};
  color: ${color.rootBlue};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DangerButton = styled(StyledButton)`
  color: #fff;
  border-color: ${color.danger};
  background: ${color.danger};

  :hover {
    background: ${color.danger};
  }
`;

export const TextButton = styled(StyledButton)`
  background: transparent;
  border: none;
  color: ${color.rootBlue};
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background: rgba(41, 105, 255, 0.08);
  }
`;

export const DisabledButton = styled(StyledButton)`
  background: #dadada;
  border-color: #dadada;
  color: ${color.emDisabled};

  :hover {
    background: #dadada;
  }

  ${Dot} {
    background-color: ${color.emDisabled};
  }
`;

export const ButtonGrouping = styled.div`
  display: inline-flex;
  grid-template-columns: 1fr auto auto;

  ${StyledButton} + ${StyledButton} {
    margin-left: ${Spacing.sm};
  }
`;

export const StyledSplitButton = styled(Button)`
  border: 0.5px solid #c3c3c3;
  border-right: 0px;
  width: 90px;
  height: 34px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  z-index: 0 !important;
`;

export const StyledSplitButtonDropDown = styled(DropdownToggle)`
  border: 0.5px solid #c3c3c3;
  width: auto;
  padding-top: 8px;
  padding-left: 5px;
  padding-right: 10px;
  border-radius: 0px 1px 1px 0px;
  cursor: pointer;
`;
