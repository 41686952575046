import { Context } from '../framework';
import { Environment } from '../models/environment';
import { ProductModuleDefinition } from './domain/product-module-definition';
import { ProductModuleDefinitionSchema } from './domain/product-module-definition-schema';
import { rootApiRequest } from '../shared/api';
import { ProductModuleDefinitionAlterationHook } from './domain/product-module-definition-alteration-hooks';
import { ProductModuleEnabledForEnvironment, ProductModuleFilters } from './actions/get-product-modules';
import { NetworkProductModuleDeprecated, ProductModuleDeprecated } from './domain/product-module-deprecated';

export class ProductModuleApi {
  public static async getProductModule(params: { productModuleId: string }): Promise<ProductModuleDeprecated> {
    const { productModuleId } = params;

    const result = await rootApiRequest(`product-modules/${productModuleId}`, {});
    return ProductModuleDeprecated.fromNetwork(result);
  }

  public static async getProductModules(params?: {
    filters: ProductModuleFilters;
  }): Promise<ProductModuleDeprecated[]> {
    const filters = params?.filters;
    const query: [string, string | number | boolean][] = [];

    const defaultEnabledForEnvironment =
      Context.environment === Environment.Production
        ? ProductModuleEnabledForEnvironment.Live
        : ProductModuleEnabledForEnvironment.Sandbox;

    const convertedEnvironmentFilter = filters?.enabledForEnvironment.map((environment) => {
      return environment === Environment.Production
        ? ProductModuleEnabledForEnvironment.Live
        : ProductModuleEnabledForEnvironment.Sandbox;
    });

    const enabledForEnvironment = convertedEnvironmentFilter
      ? convertedEnvironmentFilter.join(',')
      : defaultEnabledForEnvironment;

    query.push(['enabled_for_environment', enabledForEnvironment]);
    query.push(['live_definition', true]);

    const queryString = query.map(([key, value]) => `${key}=${value}`).join('&');
    const productModules: NetworkProductModuleDeprecated[] = await rootApiRequest(`product-modules?${queryString}`, {});
    return productModules.map(ProductModuleDeprecated.fromNetwork);
  }

  public static async getProductModuleDefinition(params: {
    productModuleId: string;
    productModuleDefinitionId: string;
  }): Promise<ProductModuleDefinition> {
    const { productModuleId, productModuleDefinitionId } = params;

    const result = await rootApiRequest(
      `product-modules/${productModuleId}/definitions/${productModuleDefinitionId}?application_schema=true`,
      {},
    );
    return ProductModuleDefinition.fromNetwork(result);
  }

  public static async getProductModuleDefinitionSchema(params: {
    productModuleId: string;
    productModuleDefinitionId: string;
    productModuleDefinitionSchemaId: string;
  }): Promise<ProductModuleDefinitionSchema> {
    const { productModuleId, productModuleDefinitionId, productModuleDefinitionSchemaId } = params;

    const result = await rootApiRequest(
      `product-modules/${productModuleId}/definitions/${productModuleDefinitionId}/schemas/${productModuleDefinitionSchemaId}`,
      {},
    );

    return ProductModuleDefinitionSchema.fromNetwork(result);
  }

  public static async getProductModuleDefinitionAlterationHooks(params: {
    productModuleId: string;
    productModuleDefinitionId: string;
  }) {
    const { productModuleId, productModuleDefinitionId } = params;
    const result = await rootApiRequest(
      `product-modules/${productModuleId}/definitions/${productModuleDefinitionId}/alteration-hooks`,
      {},
    );

    return result.map(ProductModuleDefinitionAlterationHook.fromNetwork);
  }
}
