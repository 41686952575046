export enum OrganizationPermissions {
  ManageOrganization = 'organizations:manage',
  TransferOrganizationOwnership = 'organizations:transfer_ownership',
}

export enum ApiKeyPermissions {
  ReadApiKeys = 'api_keys:read',
  ManageApiKeys = 'api_keys:manage',
}

export enum TemplatePermissions {
  ManageTemplates = 'templates:manage',
}

export enum CallPermissions {
  InitiateOutboundCall = 'calls:outbound',
  ReadCalls = 'calls:read',
  CreateCalls = 'calls:create',
}

export enum ClaimPermissions {
  ReadClaims = 'claims:read',
  ReadSensitiveClaimData = 'claims:read_sensitive',
  ManageClaims = 'claims:manage',
  OpenClaims = 'claims:open',
  MakeClaimDecisions = 'claims:make_decision',
  CloseClaims = 'claims:close',
  AcknowledgeClaimDecisions = 'claims:acknowledge_decision',
  AcknowledgeClaimApproval = 'claims:acknowledge:approval',
  AcknowledgeClaimGoodwill = 'claims:acknowledge:goodwill',
  AcknowledgeClaimRepudiation = 'claims:acknowledge:repudiation',
  AcknowledgeClaimNoClaim = 'claims:acknowledge:no_claim',
  CreateClaimNotes = 'claims:notes:create',
  ArchiveClaimAttachments = 'claims:attachments:archive',
  ReceiveClaimDecisionsNotification = 'claims:notifications:make_decision',
  ReceiveClaimApprovalNotification = 'claims:notifications:approval',
  ReceiveClaimGoodwillNotification = 'claims:notifications:goodwill',
  ReceiveClaimNoClaimNotification = 'claims:notifications:no_claim',
  ReceiveClaimRepudiationNotification = 'claims:notifications:repudiation',
  ReceiveClaimReviewNotification = 'claims:notifications:review',
}

export enum QuotePermissions {
  CreateQuotes = 'quotes:create',
  ReadQuotes = 'quotes:read',
}

export enum LeadPermissions {
  ReadLeads = 'leads:read',
  ManageLeads = 'leads:manage',
  CreateLeadNotes = 'leads:notes:create',
}

export enum PaymentPermissions {
  SubmitPaymentResults = 'payments:external:update',
  CreatePayment = 'payments:create',
  ReadPaymentCoupons = 'payment_coupons:read',
  CreatePaymentCoupons = 'payment_coupons:create',
  CancelPaymentCoupons = 'payment_coupons:cancel',
}

export enum ExportPermissions {
  ReadDataExports = 'data_exports:read',
  CreateDataExports = 'data_exports:create',
  EditDataExports = 'data_exports:edit',
}

export enum PayoutPermissions {
  ReadPayoutRequests = 'payout_requests:read',
  CreatePayoutRequests = 'payout_requests:create',
  EditPayoutRequests = 'payout_requests:edit',
  ApprovePayoutRequests = 'payout_requests:approve',
  RejectPayoutRequests = 'payout_requests:reject',
}

export enum BeneficiaryPermissions {
  ReadBeneficiaries = 'beneficiaries:read',
  CreateBeneficiaries = 'beneficiaries:create',
  EditBeneficiaries = 'beneficiaries:edit',
}

export enum DataImportPermissions {
  ReadImports = 'imports:read',
  CreateImports = 'imports:create',
  EditImports = 'imports:edit',
}

export enum CoveredPeoplePermissions {
  ReadCoveredPeople = 'covered_people:read',
  CreateCoveredPeople = 'covered_people:create',
  EditCoveredPeople = 'covered_people:edit',
}

export enum AddonPermissions {
  ReadAddOnSanctions = 'addon_sanctions:read',
  CreateAddOnSanctions = 'addon_sanctions:create',
  EditAddOnSanctions = 'addon_sanctions:edit',
}

export enum IntegrationPermissions {
  ReadReports = 'reports:read',
  CreateReports = 'reports:create',
  EditReports = 'reports:edit',
}

export enum UserPermissions {
  ReadUsers = 'users:read',
  ManageUsers = 'users:manage',
}

export enum PolicyPermissions {
  ReadPolicies = 'policies:read',
  IssuePolicies = 'policies:issue',
  ManagePolicies = 'policies:manage',
  UpdatePolicies = 'policies:update',
  CreatePolicyNotes = 'policies:notes:create',
  CreatePolicyAttachments = 'policies:attachments:create',
  ArchivePolicyAttachments = 'policies:attachments:archive',
}

export enum ComplaintPermissions {
  ReadComplaints = 'complaints:read',
  ManageComplaints = 'complaints:manage',
  ArchiveComplaintAttachments = 'complaints:attachments:archive',
  OpenComplaints = 'complaints:open',
}

export enum ApplicationPermissions {
  CreateApplications = 'applications:create',
  ReadApplications = 'applications:read',
  CreateApplicationAttachments = 'applications:attachments:create',
  UpdateApplications = 'applications:update',
  ArchiveApplications = 'applications:archive',
}

export enum PolicyholderPermissions {
  ReadPolicyholders = 'policyholders:read',
  CreatePolicyholders = 'policyholders:create',
  ManagePolicyholders = 'policyholders:manage',
  CreatePolicyholderNotes = 'policyholders:notes:create',
  CreatePolicyholderAttachments = 'policyholders:attachments:create',
}

export enum ReportPermissions {
  ReadReports = 'reports:read',
  CreateReports = 'reports:create',
  EditReports = 'reports:edit',
}

export enum CommunicationPermissions {
  ReadCommunications = 'communications:read',
  CreateCommunications = 'communications:create',
  EditCommunications = 'communications:edit',
}

export enum ViewPermissions {
  ReadViews = 'views:read',
  CreateViews = 'views:create',
  EditViews = 'views:edit',
  ArchiveViews = 'views:archive',
}

export enum OrganizationRolePermissions {
  ReadOrganizationRoles = 'organization_roles:read',
  CreateOrganizationRoles = 'organization_roles:create',
  EditOrganizationRoles = 'organization_roles:edit',
}

export enum WebhookPermissions {
  ReadWebhooks = 'webhooks:read',
  CreateWebhooks = 'webhooks:create',
  EditWebhooks = 'webhooks:edit',
}

export enum DataStoresPermissions {
  ReadDataStores = 'data_stores:read',
  CreateDataStores = 'data_stores:create',
  EditDataStores = 'data_stores:edit',
  ArchiveDataStores = 'data_stores:archive',
}

export enum DataAdapterPermissions {
  ReadDataAdapter = 'data_adapter:read',
  EditDataAdapter = 'data_adapter:edit',
}

export enum DataStoreEntitiesPermissions {
  ReadDataStoreEntities = 'data_store_entities:read',
  CreateDataStoreEntities = 'data_store_entities:create',
  EditDataStoreEntities = 'data_store_entities:edit',
  ArchiveDataStoreEntities = 'data_store_entities:archive',
}

export enum PaymentMethodsPermissions {
  VerifyPaymentMethods = 'payment_methods:verify',
}

export enum InsightsPermissions {
  ReadInsights = 'insights:read',
}

export enum WorkbenchPermissions {
  ReadWorkBenchProductModules = 'workbench:read',
  WriteWorkBenchProductModules = 'workbench:write',
}
export enum AlterationHooksPermissions {
  ReadAlterationHooks = 'alteration_hooks:read',
  CreateAlterationHooks = 'alteration_hooks:create',
  ArchiveAlterationHooks = 'alteration_hooks:archive',
}

export enum EmbeddedInsurancePermissions {
  CreateEmbeddedInsurance = 'embedded_insurance:create',
  CreateEmbedUrl = 'embedded_insurance:create_url',
}

export type Permission =
  | OrganizationPermissions
  | ApiKeyPermissions
  | TemplatePermissions
  | CallPermissions
  | ClaimPermissions
  | QuotePermissions
  | LeadPermissions
  | PaymentPermissions
  | ExportPermissions
  | PayoutPermissions
  | BeneficiaryPermissions
  | DataImportPermissions
  | CoveredPeoplePermissions
  | AddonPermissions
  | IntegrationPermissions
  | UserPermissions
  | PolicyPermissions
  | ComplaintPermissions
  | ApplicationPermissions
  | PolicyholderPermissions
  | ReportPermissions
  | CommunicationPermissions
  | ViewPermissions
  | OrganizationRolePermissions
  | DataStoresPermissions
  | DataStoreEntitiesPermissions
  | DataAdapterPermissions
  | WebhookPermissions
  | PaymentMethodsPermissions
  | WorkbenchPermissions
  | AlterationHooksPermissions
  | PaymentMethodsPermissions
  | InsightsPermissions
  | EmbeddedInsurancePermissions;

export const Permission = {
  ...OrganizationPermissions,
  ...ApiKeyPermissions,
  ...TemplatePermissions,
  ...CallPermissions,
  ...ClaimPermissions,
  ...QuotePermissions,
  ...LeadPermissions,
  ...PaymentPermissions,
  ...ExportPermissions,
  ...PayoutPermissions,
  ...BeneficiaryPermissions,
  ...DataImportPermissions,
  ...CoveredPeoplePermissions,
  ...AddonPermissions,
  ...IntegrationPermissions,
  ...UserPermissions,
  ...PolicyPermissions,
  ...ComplaintPermissions,
  ...ApplicationPermissions,
  ...PolicyholderPermissions,
  ...ReportPermissions,
  ...CommunicationPermissions,
  ...ViewPermissions,
  ...OrganizationRolePermissions,
  ...WebhookPermissions,
  ...DataStoresPermissions,
  ...DataStoreEntitiesPermissions,
  ...DataAdapterPermissions,
  ...PaymentMethodsPermissions,
  ...WorkbenchPermissions,
  ...AlterationHooksPermissions,
  ...InsightsPermissions,
  ...EmbeddedInsurancePermissions,
};

// These permissions are required due to the dashboard breaking if they are not present on a role.
// The intention is not to provide the user with access to all the functionality displayed on the dashboard, but to stop the app from breaking.
// E.g: users can see a list of policies only because this is the default landing page when entering the app.
// This is intended as a temporary fix and the proper solution is to update web to better handle these situations.
// Please note: there is a duplicate of this list in platform that also needs to be updated: app.auth/roles/domain/permissions.ts
export const mandatoryPermissions: Permission[] = [
  PolicyPermissions.ReadPolicies,
  UserPermissions.ReadUsers,
  PolicyholderPermissions.ReadPolicyholders,
  ViewPermissions.ReadViews,
  ViewPermissions.CreateViews,
  ViewPermissions.EditViews,
  ViewPermissions.ArchiveViews,
];
