import React from 'react';
import { withRouter } from 'react-router-dom';
import { Event, DisplayEvent } from '../event';
import { LinkButton } from '../../../../../components';
import { navigateTo } from '../../../../../core';
import { Context } from '../../../../framework';

export class ComplaintLinkedEvent extends Event<any> {}

class ComplaintLinkedDisplayEventImpl extends DisplayEvent {
  getTitle = () => 'Complaint linked';

  renderFull = () => {
    const { history } = this.props as any;
    const complaintId = Context.secondId;
    if (this.props.event?.entity?.complaintId === complaintId) {
      return null;
    }
    return (
      <LinkButton
        onClick={() =>
          navigateTo({
            url: `/orgs/${Context.organizationId}/insurance/complaints/${this.props.event?.complaintId}`,
            history,
          })
        }
      >
        View complaint
      </LinkButton>
    );
  };
}

export const ComplaintLinkedDisplayEvent = withRouter(ComplaintLinkedDisplayEventImpl as any);
