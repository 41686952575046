import moment, { Moment } from 'moment-timezone';
import { Construct } from '../../models/construct';
import { CreatedBy } from '../../models';
import { NetworkProductModuleDefinition, ProductModuleDefinition } from './product-module-definition';

export class ProductModule {
  readonly productModuleId: string;
  readonly organizationId: string;
  readonly sandbox: boolean;
  readonly live: boolean;
  readonly createdAt: Moment;
  readonly restricted: boolean;
  readonly key: string;
  readonly name: string;
  readonly createdBy: CreatedBy;
  readonly liveId?: string;
  readonly draftId: string;
  readonly productModuleDefinition?: ProductModuleDefinition;
  readonly draftProductModuleDefinition?: ProductModuleDefinition;
  readonly ownedByOrganizationId?: string;

  constructor(init: Construct<ProductModule>) {
    Object.assign(this, init);
  }

  static fromNetwork(init: NetworkProductModule) {
    return new ProductModule({
      productModuleId: init.product_module_id,
      organizationId: init.organization_id,
      sandbox: init.sandbox,
      live: init.live,
      createdAt: moment(init.created_at),
      restricted: init.restricted,
      key: init.key,
      name: init.name,
      createdBy: init.created_by,
      ownedByOrganizationId: init.owned_by_organization_id,
      liveId: init.live_id,
      draftId: init.draft_id,
      productModuleDefinition:
        init.product_module_definition && ProductModuleDefinition.fromNetwork(init.product_module_definition),
      draftProductModuleDefinition:
        init.draft_product_module_definition &&
        ProductModuleDefinition.fromNetwork(init.draft_product_module_definition),
    });
  }
}

export interface NetworkProductModule {
  product_module_id: string;
  organization_id: string;
  owned_by_organization_id: string;
  sandbox: boolean;
  live: boolean;
  created_at: string;
  restricted: boolean;
  key: string;
  name: string;
  created_by: CreatedBy;
  live_id?: string;
  draft_id: string;
  product_module_definition_id: string;
  product_module_definition?: NetworkProductModuleDefinition;
  draft_product_module_definition?: NetworkProductModuleDefinition;
}
