import React from 'react';
import { withRouter } from 'react-router-dom';
import { Event, DisplayEvent, DisplayEventContext, DisplayEventProps } from '../event';
import { LinkButton } from '../../../../../components';
import { FeatureContext, Context, Permission } from '../../../../framework';
import { navigateTo } from '../../../../../core';
import { Claim } from '../../../../claims/domain/claim';

export class ClaimImportedEvent extends Event<Claim> {
  public claimId: string;
}

class ClaimImportedDisplayEventImpl extends DisplayEvent<DisplayEventProps<ClaimImportedEvent>> {
  getTitle = () => {
    if (this.props.context === DisplayEventContext.Claim) {
      return 'Claim imported';
    }

    return <>Claim {this.props.event?.entity?.claimId.substr(0, 8).toUpperCase()} imported</>;
  };

  renderFull = () => {
    const { history } = this.props as any;
    return (
      <>
        {FeatureContext.instance.hasPermission(Permission.ReadClaims) && (
          <LinkButton
            onClick={() =>
              navigateTo({
                url: `/orgs/${Context.organizationId}/insurance/claims/${this.props.event?.entity?.claimId}`,
                history,
              })
            }
          >
            View
          </LinkButton>
        )}
      </>
    );
  };
}

export const ClaimImportedDisplayEvent = withRouter(ClaimImportedDisplayEventImpl as any);
