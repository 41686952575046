import React, { Props } from 'react';
import momentTimezone from 'moment-timezone';
import { Form as ReactstrapForm } from 'reactstrap';
import { FormElement, FormElementProps, FormElementState } from './form-element';

export class FormValues {
  values: {
    [name: string]: {
      valid: boolean;
      value: any;
    };
  } = {};

  value<T = any>(name: string): T {
    return (this.values[name] || { value: undefined }).value as T;
  }

  toObject = () => {
    const obj: any = {};

    for (const key in this.values) {
      obj[key] = this.value(key);

      if (momentTimezone.isMoment(obj[key])) {
        obj[key] = obj[key].format('YYYYMMDD');
      } else if (typeof obj[key] === 'object') {
        obj[key] = obj[key].value;
      }
    }

    return obj;
  };

  constructor(init?: { name: string; value: any }[]) {
    if (init) {
      for (const item of init) {
        this.values[item.name] = {
          value: item.value,
          valid: false,
        };
      }
    }
  }
}

export interface FormProps extends Props<any> {
  onValueChanged?: (valid: boolean, values: FormValues, elementName: string) => void;
  initialized?: (valid: boolean, values: FormValues) => void;
  onSubmit?: () => void;
  sidebar?: boolean;
  noLastMargin?: boolean;
  disabled?: boolean;
  touched?: boolean;
  skipFormTag?: boolean;
  className?: string;
}

export interface FormState {
  valid: boolean;
}

type Element = FormElement<any, FormElementProps<any>, FormElementState<any>>;

export class Form extends React.Component<FormProps, FormState> {
  private readonly formElements: { [name: string]: Element } = {};
  private timeout: any = null;

  constructor(props: FormProps) {
    super(props);

    this.state = {
      valid: false,
    };
  }

  register = (element: Element) => {
    const isNew = !this.formElements[element.props.name];
    this.formElements[element.props.name] = element;
    if (isNew) {
      this.valueChanged(element, true);
      if (this.props.initialized) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          const keys = Object.keys(this.formElements);
          const valid = keys.reduce(
            (isValid: boolean, key: string) => isValid && this.formElements[key].state.valid,
            true,
          );
          const data = new FormValues();
          keys.forEach((key) => {
            data.values[key] = {
              valid: this.formElements[key].state.valid,
              value: this.formElements[key].state.value,
            };
          });

          if (this.props.initialized) {
            this.props.initialized(valid, data);
          }
        }, 250);
      }
    } else {
      this.valueChanged(element);
    }
  };

  valueChanged = (element: Element, skipAlert = false) => {
    const keys = Object.keys(this.formElements);
    const valid = keys.reduce((isValid: boolean, key: string) => isValid && this.formElements[key].state.valid, true);
    this.setState({ valid });
    if (this.props.onValueChanged) {
      const data = new FormValues();
      keys.forEach((key) => {
        data.values[key] = {
          valid: this.formElements[key].state.valid,
          value: this.formElements[key].state.value,
        };
      });

      if (!skipAlert) {
        this.props.onValueChanged(valid, data, element.props.name);
      }
    }
  };

  isReactFragment = (child: any) => {
    try {
      return child.type.toString() === React.Fragment.toString();
    } catch (error) {
      return false;
    }
  };

  hasNameOrLabel(child: React.ReactElement<FormElementProps<any>>) {
    return child && child.props && (!!(child.props as any).name || !!(child.props as any).label);
  }

  mustSkipChild(child: React.ReactElement<FormElementProps<any>>) {
    if (!child || typeof child.type === 'string') {
      return true;
    }

    if (this.hasNameOrLabel(child)) {
      return false;
    }

    const { children } = child.props as any;
    if (!children) {
      return true;
    }

    if (Array.isArray(children)) {
      return !this.hasNameOrLabel(children[0]);
    }

    return !this.hasNameOrLabel(children);
  }

  render() {
    let { children } = this.props;
    if (React.Children.count(children) === 1) {
      const child = React.Children.toArray(children)[0];
      if (this.isReactFragment(child)) {
        children = (child as any).props.children;
      }
    }

    const childrenWithProps = React.Children.map(
      children as React.ReactElement<FormElementProps<any>>,
      (child: React.ReactElement<FormElementProps<any>>) => {
        if (this.mustSkipChild(child)) {
          return child;
        }

        return React.cloneElement(child, {
          onValidityChanged: this.valueChanged,
          registerWithForm: this.register,
          touched: this.props.touched || child.props.touched,
          formValid: this.state.valid,
          // disabled: this.props.disabled || child.props.disabled
        });
      },
    );

    if (this.props.skipFormTag) {
      return <div className={this.props.className}>{childrenWithProps}</div>;
    }

    return (
      <ReactstrapForm
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          if (this.props.onSubmit) {
            this.props.onSubmit();
          }
          return false;
        }}
        className={(this.props.sidebar ? 'sidebar-form ' : '') + (this.props.noLastMargin ? 'no-last-margin ' : '')}
      >
        {childrenWithProps}
      </ReactstrapForm>
    );
  }
}
