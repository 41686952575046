export enum PolicyStatus {
  PendingInitialPayment = 'pending_initial_payment',
  NotTakenUp = 'not_taken_up',
  Cancelled = 'cancelled',
  Active = 'active',
  Lapsed = 'lapsed',
  Expired = 'expired',
}

export const toPolicyStatus = (status: PolicyStatus) => {
  switch (status) {
    case 'active':
      return PolicyStatus.Active;
    case 'cancelled':
      return PolicyStatus.Cancelled;
    case 'expired':
      return PolicyStatus.Expired;
    case 'lapsed':
      return PolicyStatus.Lapsed;
    case 'not_taken_up':
      return PolicyStatus.NotTakenUp;
    case 'pending_initial_payment':
      return PolicyStatus.PendingInitialPayment;
    default:
      return PolicyStatus.Active;
  }
};
