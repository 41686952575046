import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as MobXProvider } from 'mobx-react';
import { Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Header } from 'rootstrap/components/headings/header';
import { Button } from 'rootstrap/components/button';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import { requireEnvVar } from 'insurance/shared/utils';
import { accountStore } from './insurance/account/stores/account-store';
import { Login } from './login';
import { ActivateContainer } from './insurance/account/views/activate-container';
import './index.scss';
import './insurance/settings/stylesheets/application.scss';
import './insurance/settings/stylesheets/settings.scss';

const ErrorFallback = ({ error }: { error: Error }) => {
  return (
    <div style={{ margin: '150px auto 0', maxWidth: '800px' }}>
      <Header content='Oops, something went wrong!' />
      <p>The error was:</p>
      <pre>{error.message}</pre>
      <p>
        Please reload the page and try again, or contact{' '}
        <a target='_blank' rel='noreferrer' href='mailto:support@root.co.za'>
          support@root.co.za
        </a>{' '}
        if the problem persists.
      </p>
      <Button onClick={() => location.reload()}>Reload page</Button>
    </div>
  );
};

const history = createBrowserHistory();

// testing build

ReactDOM.render(
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Helmet>
      <script
        src={`https://maps.googleapis.com/maps/api/js?key=${requireEnvVar(
          'REACT_APP_PLACES_API_KEY',
          process.env.REACT_APP_PLACES_API_KEY,
        )}&libraries=places`}
      />
    </Helmet>
    <Router history={history}>
      <Switch>
        <Route path='/activate/:token'>
          <MobXProvider accountStore={accountStore}>
            <ActivateContainer />
          </MobXProvider>
        </Route>
        <Route>
          <MobXProvider accountStore={accountStore}>
            <Login />
          </MobXProvider>
        </Route>
      </Switch>
    </Router>
  </ErrorBoundary>,
  document.getElementById('root'),
);
