import React from 'react';
import { fontFamily } from 'rootstrap/global-styles/typography';
import { color } from 'rootstrap/global-styles/color';

// TODO: Remove inline styles.
export const Header = ({
  content,
  size = 'md',
  bolded,
  style,
}: {
  content: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  bolded?: boolean;
  style?: Record<string, string | number>;
}) => {
  const sizeMap = {
    xs: {
      fontSize: '18px',
    },
    sm: {
      fontSize: '20px',
    },
    md: {
      fontSize: '24px',
    },
    lg: {
      fontSize: '28px',
    },
    xl: {
      fontSize: '36px',
    },
  };

  return (
    // TODO: Use different h tags for sizes.
    <h3
      style={{
        fontFamily: fontFamily.bodyFont,
        color: color.emHigh,
        fontStyle: 'normal',
        fontWeight: bolded ? 'bold' : 300,
        lineHeight: 1.143,
        margin: 0,
        marginBottom: '14px',
        ...sizeMap[size],
        ...style,
      }}
    >
      {content}
    </h3>
  );
};
