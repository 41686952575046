import momentTimezone, { Moment } from 'moment-timezone';
import { CreatedBy } from 'insurance/models';
import { Construct } from 'insurance/models/construct';
import {
  NetworkProductModuleDefinitionSchema,
  ProductModuleDefinitionSchema,
} from './product-module-definition-schema';

export class ProductModuleDefinitionAlterationHook {
  readonly productModuleDefinitionAlterationHookId: string;
  readonly productModuleDefinitionId: string;
  readonly schemaId: string;
  readonly key: string;
  readonly name: string;
  readonly createdAt: Moment;
  readonly createdBy: CreatedBy;
  readonly productModuleDefinitionSchema?: ProductModuleDefinitionSchema;

  constructor(init: Construct<ProductModuleDefinitionAlterationHook>) {
    Object.assign(this, init);
  }

  public static fromNetwork(init: NetworkProductModuleDefinitionAlterationHook) {
    return new ProductModuleDefinitionAlterationHook({
      productModuleDefinitionAlterationHookId: init.product_module_definition_alteration_hook_id,
      name: init.name,
      key: init.key,
      schemaId: init.schema_id,
      productModuleDefinitionId: init.product_module_definition_id,
      createdBy: init.created_by,
      createdAt: momentTimezone(init.created_at),
      productModuleDefinitionSchema:
        init.product_module_definition_schema &&
        ProductModuleDefinitionSchema.fromNetwork(init.product_module_definition_schema),
    });
  }
}

export class NetworkProductModuleDefinitionAlterationHook {
  product_module_definition_alteration_hook_id: string;
  product_module_definition_id: string;
  schema_id: string;
  key: string;
  name: string;
  created_at: string;
  created_by: CreatedBy;
  product_module_definition_schema?: NetworkProductModuleDefinitionSchema;
}
