import React from 'react';
import momentTimezone, { Moment } from 'moment-timezone';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { FormElement, FormElementProps, FormElementState } from './form-element';
import { AgeValidator } from './validation';

export type DatePickerInputType = Moment | undefined;

export interface DatePickerProps extends FormElementProps<DatePickerInputType> {
  mustEqualAge?: number;
  maxDate?: DatePickerInputType;
  minDate?: DatePickerInputType;
}

const DateFormat = 'YYYY-MM-DD';

export class Date extends FormElement<DatePickerInputType, DatePickerProps, FormElementState<DatePickerInputType>> {
  onChange = (newValue: Moment | null) => {
    const value = this.getValue(newValue || undefined);
    this.valueChanged(value);
  };

  onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setTimeout(() => {
      const elem = document.getElementsByName(this.props.name)[0] as HTMLInputElement;
      if (elem.value === '' || this.isValidDate(elem.value)) {
        return;
      }
      const value = this.getStringValue();
      elem.value = value;
      elem.setAttribute('value', value);
    });
    super._onBlur(event);
  };

  protected _render() {
    const extraProps: Partial<ReactDatePickerProps> = {};

    if (this.props.mustEqualAge) {
      extraProps.minDate = momentTimezone().subtract(this.props.mustEqualAge + 1, 'year');
      extraProps.maxDate = momentTimezone().subtract(this.props.mustEqualAge, 'year').subtract(1, 'day');
    }

    return (
      <ReactDatePicker
        className={`form-control ${this.getValidationClassName()}`}
        {...this.props}
        {...extraProps}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onFocus={this.onFocus}
        isClearable={this.props.required !== true}
        selected={this.getValueForDatePicker()}
        dateFormat={DateFormat}
        placeholderText={this.props.placeholder}
        showYearDropdown
        showMonthDropdown
        dropdownMode='select'
        autoComplete='off'
      />
    );
  }

  protected getValueFromProps(props: DatePickerProps): DatePickerInputType {
    return this.getValue(props.defaultValue);
  }

  private getValue(value: DatePickerInputType) {
    return value || undefined;
  }

  private getValueForDatePicker() {
    if (!this.state.value || !momentTimezone.isMoment(this.state.value)) {
      return null;
    }

    return this.state.value;
  }

  private getStringValue() {
    if (!this.state.value || !momentTimezone.isMoment(this.state.value)) {
      return '';
    }

    return this.state.value.format(DateFormat);
  }

  private isValidDate(date: string) {
    return momentTimezone(date, DateFormat, true).isValid();
  }

  public getSpecificValidators(props: DatePickerProps) {
    const validators = [];
    if (props.mustEqualAge) {
      validators.push(new AgeValidator(props.label, props.mustEqualAge));
    }
    return validators;
  }
}
