import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Event, DisplayEvent, DisplayEventContext, DisplayEventProps } from '../event';
import { LinkButton } from '../../../../../components';
import { navigateTo } from '../../../../../core';
import { Context } from '../../../../framework';
import { PolicyViewStore } from '../../../../policies/stores/policy-view-store';

export class PolicyIssuedEvent extends Event<any> {
  public readonly policyId: string;
}

type PolicyIssuedDisplayEventProps = DisplayEventProps<PolicyIssuedEvent>;

interface Injected extends PolicyIssuedDisplayEventProps {
  policyViewStore: PolicyViewStore;
}

@inject('policyViewStore')
@observer
export class PolicyIssuedDisplayEventImpl extends DisplayEvent<PolicyIssuedDisplayEventProps> {
  get injected() {
    return this.props as Injected;
  }

  getTitle = () => {
    if (this.props.context === DisplayEventContext.Policy) {
      return 'Policy issued';
    }

    const { isLoading } = this.injected.policyViewStore;
    const { policy } = this.injected.policyViewStore;

    return (
      <>
        Policy <i>{!isLoading && policy ? policy.packageName : ''}</i> issued
      </>
    );
  };

  renderFull = () => {
    const { isLoading } = this.injected.policyViewStore;
    const { policy } = this.injected.policyViewStore;
    const { history } = this.props as any;
    // please double check that this will work
    return (
      <>
        Policy <i>{!isLoading && policy ? policy.policyNumber : ''}</i> issued.
        {this.props.context !== DisplayEventContext.Policy && (
          <LinkButton
            onClick={() =>
              navigateTo({
                url: `/orgs/${Context.organizationId}/insurance/policies/${policy?.policyId}`,
                history,
              })
            }
          >
            View policy
          </LinkButton>
        )}
      </>
    );
  };
}

export const PolicyIssuedDisplayEvent = withRouter(PolicyIssuedDisplayEventImpl as any);
