import { Construct } from '../../models/construct';

export class FeatureFlag {
  readonly featureKey: string;
  readonly organizationId: string;
  readonly sandbox: boolean;
  readonly production: boolean;

  constructor(init: Construct<FeatureFlag>) {
    Object.assign(this, init);
  }

  toNetwork(): NetworkFeatureFlag {
    return {
      feature_key: this.featureKey,
      organization_id: this.organizationId,
      sandbox: this.sandbox,
      production: this.production,
    };
  }

  static fromNetwork(init: NetworkFeatureFlag) {
    return new FeatureFlag({
      featureKey: init.feature_key,
      organizationId: init.organization_id,
      sandbox: init.sandbox,
      production: init.production,
    });
  }
}

export interface NetworkFeatureFlag {
  feature_key: string;
  organization_id: string;
  sandbox: boolean;
  production: boolean;
}
