import { ValidatorResult, Validator } from './types';

export class PatternValidator<T extends string = string> implements Validator<T> {
  constructor(private readonly name: string, private readonly pattern: string) {}

  validate(value: T) {
    if (!value) {
      return { valid: true };
    }
    const valid = new RegExp(this.pattern, 'ig').test(value);
    const result: ValidatorResult = { valid };

    if (!result.valid) {
      result.message = `${this.name} is invalid.`;
    }

    return result;
  }
}
