export type NotificationType =
  | PolicyNotificationType
  | PaymentNotificationType
  | PaymentMethodNotificationType
  | ClaimNotificationType
  | MemberNotificationType
  | ComplaintNotificationType
  | ApplicationNotificationType
  | LayoutNotificationType
  | CustomNotificationType
  | 'layout';

export enum PaymentNotificationType {
  PaymentFailureFirst = 'payment_failure_1st',
  PaymentFailureFourth = 'payment_failure_4th',
  PaymentFailureFifteenth = 'payment_failure_15th',
  PaymentFailureDebitOrder = 'payment_failure_debit_order',
  PaymentSuccessDebitOrder = 'payment_success_debit_order',
  PaymentSuccessExternal = 'payment_success_external',
  PaymentFailureEFT = 'payment_failure_eft',
  PaymentFailureOnDemandCoverPeriod = 'payment_failure_on_demand_cover_period',
  PaymentFailureOnDemandMonthly = 'payment_failure_on_demand_monthly',
  PaymentFailureCard = 'payment_failure_card',
  PaymentFailureExternal = 'payment_failure_external',
}

export interface NotificationDescriptionRecipient {
  [k: string]: {
    description: string;
    recipient: string;
  };
}

export enum RecipientTypes {
  Policyholder = 'policyholder',
  Beneficiary = 'beneficiary',
  CoveredPerson = 'covered person',
  Claimant = 'claimant',
  Agent = 'agent',
  Member = 'member',
  Complainant = 'complainant',
}

export const paymentNotificationDescriptionRecipient: NotificationDescriptionRecipient = {
  paymentFailure1st: {
    description: 'This event is triggered when a payment fails for the 1st of the month.',
    recipient: RecipientTypes.Policyholder,
  },
  paymentFailure4th: {
    description: 'This event is triggered when a payment fails for the 4th of the month.',
    recipient: RecipientTypes.Policyholder,
  },
  paymentFailure15th: {
    description: 'This event is triggered when a payment fails for the 15th of the month.',
    recipient: RecipientTypes.Policyholder,
  },
  paymentFailureDebitOrder: {
    description: 'This event is triggered when a debit order payment on a policy is not successful.',
    recipient: RecipientTypes.Policyholder,
  },
  paymentSuccessDebitOrder: {
    description: 'This event is triggered when a debit order payment on a policy is successful.',
    recipient: RecipientTypes.Policyholder,
  },
  paymentSuccessExternal: {
    description: 'This event is triggered when an external payment on a policy is successful.',
    recipient: RecipientTypes.Policyholder,
  },
  paymentFailureEft: {
    description: 'This event is triggered when an EFT payment on a policy is unsuccessful.',
    recipient: RecipientTypes.Policyholder,
  },
  paymentFailureOnDemandCoverPeriod: {
    description: 'This event is triggered when a payment for an on-demand cover period fails.',
    recipient: RecipientTypes.Policyholder,
  },
  paymentFailureOnDemandMonthly: {
    description: 'This event is triggered when a payment for a monthly on-demand policy fails.',
    recipient: RecipientTypes.Policyholder,
  },
  paymentFailureCard: {
    description: 'This event is triggered when a card payment for a policy fails.',
    recipient: RecipientTypes.Policyholder,
  },
  paymentFailureExternal: {
    description: 'This event is triggered when a payment is unsuccessful for a policy with an external payment method.',
    recipient: RecipientTypes.Policyholder,
  },
};

export const isPaymentNotificationType = (value: any): value is PaymentNotificationType => {
  return Object.values(PaymentNotificationType).includes(value);
};

export enum PaymentMethodNotificationType {
  PaymentMethodBlocked = 'payment_method_blocked',
  PaymentMethodFailedVerification = 'payment_method_failed_verification',
  PaymentMethodUpdated = 'payment_method_updated',
}

export const paymentMethodNotificationDescriptionRecipient: NotificationDescriptionRecipient = {
  paymentMethodBlocked: {
    description: 'This event is triggered when a payment method becomes blocked.',
    recipient: RecipientTypes.Policyholder,
  },
  paymentMethodFailedVerification: {
    description: 'This event is triggered when a payment method fails verification.',
    recipient: RecipientTypes.Policyholder,
  },
  paymentMethodUpdated: {
    description: 'This event is triggered when a payment method is updated.',
    recipient: RecipientTypes.Policyholder,
  },
};

export const isPaymentMethodNotificationType = (value: any): value is PaymentMethodNotificationType => {
  return Object.values(PaymentMethodNotificationType).includes(value);
};

export enum CustomNotificationType {
  CustomEvent = 'custom_event',
}

export enum CustomNotificationEventType {
  Policy = 'policy',
  Payment = 'payment',
  PaymentMethod = 'payment_method',
  Claim = 'claim',
}

export enum PolicyNotificationType {
  PolicyIssued = 'policy_issued',
  BeneficiariesUpdated = 'beneficiaries_updated',
  CoveredPeopleUpdated = 'covered_people_updated',
  DebitOrdersActivated = 'debit_orders_activated',
  EFTActivated = 'eft_activated',
  PolicyholderUpdated = 'policy_policyholder_updated',
  PolicyCancelled = 'policy_cancelled',
  PolicyLapsed = 'policy_lapsed',
  PolicyNotTakenUp = 'policy_not_taken_up',
  PolicyExpired = 'policy_expired',
  PolicyUpdated = 'policy_updated',
  FirstPremiumPayment = 'first_premium_payment',
  SumAssuredUpdated = 'sum_assured_updated',
  PolicyRequoted = 'policy_requoted',
  PolicyReactivated = 'policy_reactivated',
  PolicyAnniversary = 'policy_anniversary',
  ExternalPaymentsActivated = 'external_payments_activated',
  AlterationPackageApplied = 'alteration_package_applied',
}

export const policyNotificationDescriptionRecipient: NotificationDescriptionRecipient = {
  policyIssued: {
    description: 'This event is triggered when a policy is issued.',
    recipient: RecipientTypes.Policyholder,
  },
  beneficiariesUpdated: {
    description: 'This event is triggered when a beneficiary on a policy is updated.',
    recipient: RecipientTypes.Beneficiary,
  },
  coveredPeopleUpdated: {
    description: 'This event is triggered when a covered person is updated on a policy.',
    recipient: RecipientTypes.CoveredPerson,
  },
  debitOrdersActivated: {
    description: 'This event is triggered when a payment method is added to a policy.',
    recipient: RecipientTypes.Policyholder,
  },
  eftActivated: {
    description: 'This event is triggered when an EFT payment method is enabled.',
    recipient: RecipientTypes.Policyholder,
  },
  externalPaymentsActivated: {
    description: 'This event is triggered when an external payment method is enabled.',
    recipient: RecipientTypes.Policyholder,
  },
  policyPolicyholderUpdated: {
    description: "This event is triggered when a policyholder's details are updated.",
    recipient: RecipientTypes.Policyholder,
  },
  policyCancelled: {
    description: 'This event is triggered when a policy is canceled.',
    recipient: RecipientTypes.Policyholder,
  },
  policyLapsed: {
    description: 'This event is triggered when a policy is lapsed due to non-payment of premium.',
    recipient: RecipientTypes.Policyholder,
  },
  policyNotTakenUp: {
    description: 'This event is triggered when a policy has been issued but never receives a payment.',
    recipient: RecipientTypes.Policyholder,
  },
  policyExpired: {
    description: 'This event is triggered when a policy reaches its expiry date.',
    recipient: RecipientTypes.Policyholder,
  },
  policyUpdated: {
    description: 'This event is triggered when information pertaining to a policy is updated.',
    recipient: RecipientTypes.Policyholder,
  },
  firstPremiumPayment: {
    description: 'This event is triggered when the first successful payment is made on a policy.',
    recipient: RecipientTypes.Policyholder,
  },
  sumAssuredUpdated: {
    description: 'This event is triggered when the sum assured amount is changed on a policy.',
    recipient: RecipientTypes.Policyholder,
  },
  policyRequoted: {
    description: 'This event is triggered when a policy is requoted.',
    recipient: RecipientTypes.Policyholder,
  },
  policyReactivated: {
    description: 'This event is triggered when an inactive policy is reactivated.',
    recipient: RecipientTypes.Policyholder,
  },
  alterationPackageApplied: {
    description: 'This event is triggered when an alteration package is applied',
    recipient: RecipientTypes.Policyholder,
  },
  policyAnniversary: {
    description: 'This event is triggered when a policy reaches its anniversary date.',
    recipient: RecipientTypes.Policyholder,
  },
};

export const isPolicyNotificationType = (value: any): value is PolicyNotificationType => {
  return Object.values(PolicyNotificationType).includes(value);
};

export enum ClaimNotificationType {
  ClaimApprovedBeneficiary = 'claim_approved_beneficiary',
  ClaimApproved = 'claim_approved',
  ClaimReceived = 'claim_received',
  ClaimRepudiated = 'claim_repudiated',
  ClaimLinkedToPolicy = 'claim_linked_to_policy',
  ClaimSentToReview = 'claim_sent_to_review',
  ClaimDecisionReview = 'claim_decision_review',
  ClaimSentToReviewClaimant = 'claim_sent_to_review_claimant',
}

export const claimNotificationDescriptionRecipient: NotificationDescriptionRecipient = {
  claimApprovedBeneficiary: {
    description:
      'This event is triggered when a claim is approved, notifying the beneficiaries that funds will be paid to them.',
    recipient: RecipientTypes.Beneficiary,
  },
  claimApproved: {
    description: 'This event is triggered when a claim is approved.',
    recipient: RecipientTypes.Claimant,
  },
  claimReceived: {
    description: 'This event is triggered when a claim is opened for review.',
    recipient: RecipientTypes.Claimant,
  },
  claimRepudiated: {
    description: 'This event is triggered when a claim has been declined.',
    recipient: RecipientTypes.Claimant,
  },
  claimLinkedToPolicy: {
    description: 'This event is triggered when a claim is linked to a policy.',
    recipient: RecipientTypes.Claimant,
  },
  claimSentToReview: {
    description: 'This event is triggered when a claim is sent for assessment.',
    recipient: RecipientTypes.Agent,
  },
  claimDecisionReview: {
    description: 'This event is triggered when the descision for a claim is sent to the supervisor for approval.',
    recipient: RecipientTypes.Agent,
  },
  claimSentToReviewClaimant: {
    description: 'This event is triggered when a claim is sent for assessment.',
    recipient: RecipientTypes.Claimant,
  },
};

export const isClaimNotificationType = (value: any): value is ClaimNotificationType => {
  return Object.values(ClaimNotificationType).includes(value);
};

export enum MemberNotificationType {
  MemberAdded = 'member_added',
  MemberRemoved = 'member_removed',
  MemberUpdated = 'member_updated',
  MemberBeneficiariesUpdated = 'member_beneficiaries_updated',
}

export const memberNotificationDescriptionRecipient: NotificationDescriptionRecipient = {
  memberAdded: {
    description: 'This event is triggered when a member is added to a policy.',
    recipient: RecipientTypes.Member,
  },
  memberRemoved: {
    description: 'This event is triggered when a member is removed from a policy.',
    recipient: RecipientTypes.Member,
  },
  memberUpdated: {
    description: 'This event is triggered when the details for a member are updated.',
    recipient: RecipientTypes.Member,
  },
  memberBeneficiariesUpdated: {
    description: "This event is triggered when a member's beneficiaries are altered on a group policy.",
    recipient: RecipientTypes.Member,
  },
};

export const isMemberNotificationType = (value: any): value is MemberNotificationType => {
  return Object.values(MemberNotificationType).includes(value);
};

export enum ApplicationNotificationType {
  SendApplicationPdfToPolicyholder = 'send_application_pdf_to_policyholder',
}

export const applicationNotificationDescriptionRecipient: NotificationDescriptionRecipient = {
  sendApplicationPdfToPolicyholder: {
    description: 'This event is triggered when an application for a policy is requested.',
    recipient: RecipientTypes.Policyholder,
  },
};

export enum LayoutNotificationType {
  Layout = 'layout',
}

export const isApplicationNotificationType = (value: any): value is ApplicationNotificationType => {
  return Object.values(ApplicationNotificationType).includes(value);
};

export enum ComplaintNotificationType {
  ComplaintReceived = 'complaint_received',
}

export const complaintNotificationDescriptionRecipient: NotificationDescriptionRecipient = {
  complaintReceived: {
    description: 'This event is triggered when a complaint is opened on a policy.',
    recipient: RecipientTypes.Complainant,
  },
};

export const isComplaintNotificationType = (value: any): value is ComplaintNotificationType => {
  return Object.values(ComplaintNotificationType).includes(value);
};

export const isCustomNotificationType = (value: any): value is CustomNotificationType => {
  return Object.values(CustomNotificationType).includes(value);
};

export const validNotificationTypes = [
  ...Object.values(PolicyNotificationType),
  ...Object.values(PaymentNotificationType),
  ...Object.values(ClaimNotificationType),
  ...Object.values(MemberNotificationType),
  ...Object.values(ComplaintNotificationType),
  ...Object.values(ApplicationNotificationType),
  ...Object.values(LayoutNotificationType),
  ...Object.values(CustomNotificationType),
];
