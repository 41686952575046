import React from 'react';
import ReactSelect, { Option, Options } from 'react-select';
import { getTimezoneOptions } from 'insurance/shared/utils';
import { FormElement, FormElementProps, FormElementState } from './form-element';

export type TimezoneProps = FormElementProps<string>;

export class Timezone extends FormElement<string, TimezoneProps, FormElementState<string>> {
  onTimezoneChange = (newValue: Option | null) => {
    if (newValue) {
      this.valueChanged(newValue.name);
    }
  };

  onFilter = (options: Options, filter: string) => {
    return options.filter((opt: any) => opt.name.toLowerCase().includes(filter.toLowerCase()));
  };

  protected _render() {
    const disabled = !!(this.props.defaultValue && this.props.disabled && !this.state.changed);
    return (
      <div className='select-timezone'>
        <ReactSelect
          className={this.getValidationClassName() + (this.state.focused ? ' is-focused' : '')}
          {...this.props}
          disabled={disabled}
          onBlur={this.onBlur}
          onChange={this.onTimezoneChange}
          onFocus={this.onFocus}
          value={this.state.value}
          clearable={false}
          filterOptions={this.onFilter}
          options={getTimezoneOptions() as any}
        />
      </div>
    );
  }

  protected getValueFromProps(props: TimezoneProps) {
    return this.getValue(props.defaultValue);
  }

  private getValue(value: string | undefined) {
    return value || '';
  }
}
