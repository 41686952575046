import { ValidatorResult, Validator } from './types';

export class MaxValidator<T extends number = number> implements Validator<T> {
  constructor(
    private readonly name: string,
    private readonly max: number,
    private readonly isCurrency: boolean,
    private readonly currency: string = 'ZAR',
  ) {}

  validate(value: T) {
    if (value === undefined) {
      return { valid: true };
    }

    if (typeof value !== 'number') {
      return {
        valid: false,
        message: 'Value is not a number.',
      };
    }

    const result: ValidatorResult = { valid: value <= this.max };

    if (!result.valid) {
      const max = this.isCurrency ? (this.max * 100).formatMoney(this.currency) : this.max;
      result.message = `${this.name} should be less than or equal to ${max}.`;
    }

    return result;
  }
}
