import React from 'react';
import ReactSelect, { Option, Options } from 'react-select';
import { FormElement, FormElementProps, FormElementState } from './form-element';
import { Countries } from './countries';

export type CountryValue = string | undefined;

export type CountryProps = FormElementProps<CountryValue>;

export const countryOptions = Countries.map((country) => {
  return {
    name: country.name,
    label: (
      <span>
        <span className={`flag-icon flag-icon-${country.code.toLowerCase()}`} />
        &nbsp;&nbsp;{country.name}
      </span>
    ) as any,
    value: country.code,
  };
});

export class Country extends FormElement<CountryValue, CountryProps, FormElementState<CountryValue>> {
  onChange = (newValue: Option<CountryValue> | null) => {
    const value = this.getValue(newValue);
    this.valueChanged(value);
  };

  onFilter = (options: Options<CountryValue>, filter: string) => {
    return options.filter((opt: any) => {
      const name = (opt.name as string).toLowerCase();
      return name.includes(filter.toLowerCase());
    });
  };

  protected _render() {
    return (
      <ReactSelect
        className={`${this.getValidationClassName()} country-select`}
        {...this.props}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onFocus={this.onFocus}
        disabled={this.props.disabled}
        value={this.state.value}
        clearable={this.props.required !== true}
        noResultsText='No matching countries'
        filterOptions={this.onFilter}
        options={countryOptions}
      />
    );
  }

  protected getValueFromProps(props: CountryProps) {
    return this.getValue(props.defaultValue);
  }

  private getValue(value: CountryValue | Option<CountryValue> | null) {
    if (value === null) {
      return undefined;
    }

    if (typeof value === 'object') {
      return value.value;
    }

    return value;
  }
}
