import { LoginTwoFARequired, LoginError, LoginSuccess } from './domain/login-result';
import { getApiUrl } from '../shared/api';

export class AccountApi {
  public static async login(params: { email: string; password: string; twoFACode?: string }) {
    const result = await fetch(`${getApiUrl() as string}/v1/accounts/login`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: params.email,
        password: params.password,
        code: params.twoFACode,
      }),
    });
    unAuthenticated({ status: result.status });

    const body = await result.json();

    if (body.error) {
      if (body.error.otp) {
        return new LoginTwoFARequired(body.error.otp, body.error.account_error_type);
      }
      return new LoginError(body.error.account_error_type);
    }

    if (body.success && body.login_token) {
      return new LoginSuccess(body.login_token, body.user_id);
    }

    return new LoginError();
  }

  public static async refreshLogin(params: { oldToken: string }) {
    const result = await fetch(`${getApiUrl() as string}/v1/accounts/refresh-login-token`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${params.oldToken}`,
      },
      body: JSON.stringify({
        old_token: params.oldToken,
      }),
    });
    unAuthenticated({ status: result.status });

    const body = await result.json();

    if (body.error) {
      return new LoginError(body.error.message);
    }

    if (body.success && body.login_token) {
      return new LoginSuccess(body.login_token, body.user_id);
    }

    return new LoginError();
  }

  public static async accountInit(params: { loginToken: string }) {
    const result = await fetch(`${getApiUrl() as string}/v1/accounts/init`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${params.loginToken}`,
      },
    });
    unAuthenticated({ status: result.status });

    const body = await result.json();

    if (body.error) {
      throw new Error(body.error);
    }

    return body;
  }

  public static async resetPassword(params: { password: string; appToken: string; code?: string }) {
    const { appToken: token, password, code } = params;
    const result = await fetch(`${getApiUrl() as string}/v1/accounts/reset-password`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        password,
        code,
        token,
      }),
    });
    unAuthenticated({ status: result.status });

    return result.json();
  }

  public static async forgotPassword(params: { email: string }) {
    const result = await fetch(`${getApiUrl() as string}/v1/accounts/forgot-password`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        email: params.email,
      }),
    });
    unAuthenticated({ status: result.status });

    const body = await result.json();

    if (body.error) {
      return body;
    }

    return body;
  }

  // Returns the user object for org
  public static async getSelfForOrg(params: { organizationId: string; loginToken: string }) {
    const result = await fetch(`${getApiUrl() as string}/v1/organizations/${params.organizationId}/users/self`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${params.loginToken}`,
      },
    });
    unAuthenticated({ status: result.status });

    const body = await result.json();

    if (body.error) {
      return body;
    }

    return body;
  }

  public static async activateSet2fa(params: { code: string; appToken: string; loginToken: string }) {
    const { code, appToken, loginToken } = params;
    const result = await fetch(`${getApiUrl() as string}/v1/activate/set-2fa`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${loginToken}`,
      },
      body: JSON.stringify({
        code,
        app_token: appToken,
      }),
    });
    unAuthenticated({ status: result.status });

    const body = await result.json();

    if (body.error) {
      return body;
    }

    return body;
  }

  public static async activateSetPassword(params: {
    email?: string;
    userId?: string;
    password: string;
    loginToken: string;
  }) {
    const { email, password, userId, loginToken } = params;

    const data: any = {
      user_id: userId,
      email,
      password,
    };

    const result = await fetch(`${getApiUrl() as string}/v1/activate/set-password`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${loginToken}`,
      },
      body: JSON.stringify({
        ...data,
      }),
    });
    unAuthenticated({ status: result.status });

    const body = await result.json();

    if (body.error) {
      return body;
    }

    return body;
  }

  public static async enabledAppOtp(params: { otpCode: string; appToken: string; loginToken: string }) {
    const { otpCode, appToken, loginToken } = params;

    const data: any = {
      code: otpCode,
      app_token: appToken,
    };

    const result = await fetch(`${getApiUrl() as string}/v1/accounts/enable-app-otp`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${loginToken}`,
      },
      body: JSON.stringify({
        ...data,
      }),
    });
    unAuthenticated({ status: result.status });

    const body = await result.json();

    if (body.error) {
      return body;
    }

    return body;
  }

  public static async disableAppOtp(params: { loginToken: string; OTPCode: string }) {
    const { loginToken, OTPCode } = params;

    const result = await fetch(`${getApiUrl() as string}/v1/accounts/disable-app-otp`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${loginToken}`,
      },
      body: JSON.stringify({
        code: OTPCode,
      }),
    });
    unAuthenticated({ status: result.status });

    const body = await result.json();

    if (body.error) {
      return body;
    }

    return body;
  }

  public static async sendOtpToken(params: { otpCode: string; loginToken: string }) {
    const { otpCode, loginToken } = params;

    const data: any = {
      otp_code: otpCode,
    };

    const result = await fetch(`${getApiUrl() as string}/v1/accounts/send-app-token`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${loginToken}`,
      },
      body: JSON.stringify({
        ...data,
      }),
    });
    unAuthenticated({ status: result.status });

    const body = await result.json();

    return body;
  }

  public static async getUserNotifications(params: { userId: string; loginToken: string }) {
    const { loginToken } = params;
    const url = `${getApiUrl() as string}/v1/notifications`;

    const result = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${loginToken}`,
      },
    });
    unAuthenticated({ status: result.status });

    const body = await result.json();

    if (body.error) {
      throw new Error(body);
    }

    return body;
  }

  public static async updateUserNotifications(params: { loginToken: string; data: any }) {
    const { loginToken, data } = params;
    const url = `${getApiUrl() as string}/v1/notifications`;

    const result = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${loginToken}`,
      },
      method: 'PATCH',
      body: JSON.stringify({
        ...data,
      }),
    });
    unAuthenticated({ status: result.status });

    const body = await result.json();

    if (body.error) {
      return body;
    }

    return body;
  }

  public static async updateUserTimezone(params: { loginToken: string; data: any }) {
    const { loginToken, data } = params;
    const url = `${getApiUrl() as string}/v1/update-timezone`;

    const result = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${loginToken}`,
      },
      method: 'PATCH',
      body: JSON.stringify({ data }),
    });

    unAuthenticated({ status: result.status });

    const body = await result.json();

    if (body.error) {
      return body;
    }

    return body;
  }

  public static async changePassword(params: {
    oldPassword?: string;
    newPassword?: string;
    code: string;
    loginToken: string;
  }) {
    const { code, oldPassword, newPassword, loginToken } = params;

    const data: any = {
      old_password: oldPassword,
      new_password: newPassword,
      code,
    };

    const result = await fetch(`${getApiUrl() as string}/v1/change-password`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${loginToken}`,
      },
      body: JSON.stringify({
        ...data,
      }),
    });
    unAuthenticated({ status: result.status });

    const body = await result.json();

    if (body.error) {
      return body;
    }

    return body;
  }

  public static async status(params: { email?: string; userId?: string; loginToken: string }) {
    const { email, userId, loginToken } = params;
    const result = await fetch(`${getApiUrl() as string}/v1/activate/status`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${loginToken}`,
      },
      body: JSON.stringify({
        email,
        user_id: userId,
      }),
    });
    unAuthenticated({ status: result.status });

    const body = await result.json();

    if (body.error) {
      return body;
    }

    return body;
  }

  public static async resendOtp(params: { email?: string; userId?: string; loginToken: string }) {
    const { email, userId, loginToken } = params;
    const result = await fetch(`${getApiUrl() as string}/v1/activate/resend-otp`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${loginToken}`,
      },
      body: JSON.stringify({
        email,
        user_id: userId,
      }),
    });
    unAuthenticated({ status: result.status });

    const body = await result.json();

    if (body.error) {
      return body;
    }

    return body;
  }

  public static async sendOTP(params: { email?: string; userId?: string; loginToken: string }) {
    const { email, userId, loginToken } = params;
    const result = await fetch(`${getApiUrl() as string}/v1/accounts/send-otp`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${loginToken}`,
      },
      body: JSON.stringify({
        email,
        user_id: userId,
      }),
    });
    unAuthenticated({ status: result.status });

    const body = await result.json();

    if (body.error) {
      return body;
    }

    return body;
  }

  public static async getClientApps(params: { loginToken: string }) {
    const { loginToken } = params;

    const result = await fetch(`${getApiUrl() as string}/v1/client-apps`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${loginToken}`,
      },
    });
    unAuthenticated({ status: result.status });

    const body = await result.json();

    if (body.error) {
      return body;
    }

    return body;
  }

  public static async changeTimezone(params: { user: any }) {
    const { user } = params;
    const loginToken = window.localStorage.getItem('login_token');
    const url = `${getApiUrl() as string}/v1/user/update-timezone`;

    const result = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${loginToken!}`,
      },
      method: 'PATCH',
      body: JSON.stringify({
        ...user,
      }),
    });

    const body = await result.json();

    return body;
  }
}

export const unAuthenticated = (params: { status: number }) => {
  const { status } = params;
  if (status === 401) {
    return (window.location.href = '/');
  }
};
