import React from 'react';
import { Spinner } from '../spinner';
import { DisabledButton, PrimaryButton, SecondaryButton, DangerButton, TextButton } from './styles';

export enum Theme {
  primary = 'primary',
  secondary = 'secondary',
  danger = 'danger',
  text = 'text',
}

export enum Size {
  default = 'default',
  lg = 'lg',
}

export interface Props {
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  theme?: Theme;
  size?: Size | undefined;
  minWidth?: number;
  isLoading?: boolean;
  type?: 'submit' | 'reset' | 'button';
}

export const Button: React.FC<Props> = ({
  children,
  theme = Theme.primary,
  size = Size.default,
  disabled,
  isLoading,
  minWidth,
  type = 'button',
  ...props
}) => {
  const Component = getComponentToRender(theme, disabled);

  return (
    <Component type={type} minWidth={minWidth} size={size} disabled={disabled} {...props}>
      {isLoading ? <Spinner /> : children}
    </Component>
  );
};

const getComponentToRender = (theme: Theme, disabled: boolean | undefined) => {
  if (disabled) {
    return DisabledButton;
  }

  let component;

  switch (theme) {
    case Theme.secondary:
      component = SecondaryButton;
      break;

    case Theme.danger:
      component = DangerButton;
      break;

    case Theme.text:
      component = TextButton;
      break;

    default:
      component = PrimaryButton;
      break;
  }

  return component;
};
