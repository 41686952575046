import React from 'react';
import { Event, DisplayEvent } from '../event';

export class ClaimClosedEvent extends Event<any> {}

export class ClaimClosedDisplayEvent extends DisplayEvent {
  getTitle = () => 'Claim closed';
  renderFull = () => (
    <div>
      Reason: <br />
      {(this.props.event as any).reason}{' '}
    </div>
  );
}
