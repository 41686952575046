import { ValidatorResult, Validator } from './types';

export class CreditCardValidator<T extends string = string> implements Validator<T> {
  constructor(private readonly name: string) {}

  validate(value: T) {
    if (value === undefined || typeof value !== 'string') {
      return { valid: true };
    }

    const result: ValidatorResult = {
      valid: isValidCreditCardNumber(value.replace(/\s/g, '')),
    };

    if (!result.valid) {
      result.message = `${this.name} should be a valid credit card number.`;
    }

    return result;
  }
}

export const isValidCreditCardNumber = (value: string) => {
  if (/[^0-9-\s]+/.test(value)) {
    return false;
  }

  if (value.length !== 16) {
    return false;
  }

  // The Luhn Algorithm. It's so pretty.
  let nCheck = 0;
  let nDigit = 0;
  let bEven = false;
  value = value.replace(/\D/g, '');

  for (let n = value.length - 1; n >= 0; n--) {
    const cDigit = value.charAt(n);
    nDigit = parseInt(cDigit, 10);

    if (bEven) {
      nDigit *= 2;
      if (nDigit > 9) {
        nDigit -= 9;
      }
    }

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 === 0;
};
