import React from 'react';
import { Event, DisplayEvent, DisplayEventProps } from '../event';
import { LinkButton } from '../../../../../components';
import { ViewFileModal } from '../../../../documents/view-file-modal';

export class PolicyTermsUpdatedEvent extends Event<any> {
  termsUri: string;

  constructor(init: any, entity: any) {
    super(init, entity);
    Object.assign(this, init);
  }
}

type PolicyTermsUpdatedDisplayEventProps = DisplayEventProps<PolicyTermsUpdatedEvent>;

export class PolicyTermsUpdatedDisplayEvent extends DisplayEvent<
  PolicyTermsUpdatedDisplayEventProps,
  { showModal: boolean }
> {
  constructor(props: PolicyTermsUpdatedDisplayEventProps) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  getTitle = () => 'Terms document updated';
  renderFull = () => {
    const fileUrl = this.props.event.termsUri;
    const fileName = 'Terms document';
    const fileType = 'application/pdf';

    return (
      <>
        <LinkButton onClick={() => this.setState({ showModal: true })}>View terms document</LinkButton>
        {this.state.showModal && (
          <ViewFileModal
            fileName={fileName}
            fileUrl={fileUrl}
            fileType={fileType}
            close={() => this.setState({ showModal: false })}
          />
        )}
      </>
    );
  };
}
