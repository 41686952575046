import { PolicyholderEventType } from './policyholder-event-type';
import { NetworkEvent } from '../network-event';
import { Event } from '../event';

import { PolicyholderCreatedEvent, PolicyholderCreatedDisplayEvent } from './policyholder-created-event';
import { PolicyholderUpdatedEvent, PolicyholderUpdatedDisplayEvent } from './policyholder-updated-event';
import { Policyholder } from '../../../../policyholders/domain/policyholder';
import { PolicyholderImportedEvent, PolicyholderImportedDisplayEvent } from './policyholder-imported-event';

export class PolicyholderEventFactory {
  public static fromNetwork(event: NetworkEvent, policyholder: Policyholder) {
    switch (event.type) {
      case PolicyholderEventType.PolicyholderCreated:
        return new PolicyholderCreatedEvent(event as any, policyholder);
      case PolicyholderEventType.PolicyholderImported:
        return new PolicyholderImportedEvent(event as any, policyholder);
      case PolicyholderEventType.PolicyholderUpdated:
        return new PolicyholderUpdatedEvent(event as any, policyholder);
      default:
        return null;
    }
  }

  public static toDisplayEvent(event: Event<any>) {
    switch (event.type) {
      case PolicyholderEventType.PolicyholderCreated:
        return PolicyholderCreatedDisplayEvent;
      case PolicyholderEventType.PolicyholderImported:
        return PolicyholderImportedDisplayEvent;
      case PolicyholderEventType.PolicyholderUpdated:
        return PolicyholderUpdatedDisplayEvent;
      default:
        return null;
    }
  }
}
