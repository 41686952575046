import React from 'react';
import { observer } from 'mobx-react';
import { Input } from '../forms';
import { Svg } from '../svg';

export interface SearchInputProps {
  placeholder: string;
  onChange: (value?: string) => void;
  defaultValue?: string;
  lean?: boolean;
  className?: string;
}

export interface State {
  value?: string;
  defaultValue?: string;
}

@observer
export class SearchInput extends React.Component<SearchInputProps, State> {
  private timeout: any = null;

  constructor(props: SearchInputProps) {
    super(props);
    this.state = {};
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.valueChanged(value);
  };

  valueChanged = (value?: string) => {
    if (value === '') {
    }

    this.setState({ value });

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    setTimeout(() => {
      this.timeout = null;
      this.props.onChange(value);
    }, 500);
  };

  clear = () => {
    this.valueChanged();
  };

  render() {
    const { lean, placeholder, className, defaultValue } = this.props;
    const { value } = this.state;

    const inputValue = value !== undefined ? value : defaultValue || '';

    return (
      <div className={lean ? 'search-control-lean' : 'search-control'}>
        <Input
          type='text'
          name='search'
          placeholder={placeholder}
          value={inputValue}
          onChange={this.onChange}
          className={className}
        />
        <div className={lean ? 'lean-icon-container' : ''}>
          <Svg className='search-icon' href='/assets/images/icons/search.svg' />
          <Svg
            className={`clear-icon${value || defaultValue ? '' : ' hidden'}`}
            href='/assets/images/icons/icon-24-px-cross.svg'
            onClick={this.clear}
          />
        </div>
      </div>
    );
  }
}
