import React from 'react';
import momentTimezone from 'moment-timezone';
import { Form as ReactstrapForm } from 'reactstrap';
import { FormElement, FormElementProps, FormElementState } from './form-element';
import { FormProps, FormState } from './form';

export class FormValuesWithKeys {
  values: {
    [name: string]: {
      valid: boolean;
      value: any;
      key: string;
    };
  } = {};

  public readonly id: string;
  public disable: boolean;

  value<T = any>(name: string): T {
    return (this.values[name] || { value: undefined }).value as T;
  }

  valid(name: string): boolean {
    return (this.values[name] || { valid: false }).valid;
  }

  key(name: string): string {
    if (!this.values[name]) {
      this.values[name] = {
        valid: false,
        key: `form-value-${Math.random()}`,
        value: undefined,
      };
    }

    return this.values[name].key;
  }

  toObject = () => {
    const obj: any = {};

    for (const key in this.values) {
      obj[key] = this.prettyValue(this.value(key));
    }

    return obj;
  };

  static fromObject = (obj: any) => {
    const values: any = Object.entries(obj).map(([key, value]) => {
      return {
        valid: !!value,
        name: key,
        value,
      };
    });
    return new FormValuesWithKeys(values);
  };

  prettyValue(val: any, dateFormat = 'YYYYMMDD') {
    if (momentTimezone.isMoment(val)) {
      return val.format(dateFormat);
    }
    if (Array.isArray(val)) {
      return val.map((x: FormValuesWithKeys) => x.toObject());
    }
    if (typeof val === 'object') {
      return val.value;
    }

    return val;
  }

  constructor(
    init?: { name: string; value: any; key?: string; valid?: boolean }[],
    id?: string,
    form?: FormValuesWithKeys,
  ) {
    this.id = id || `form-values-${Math.random()}`;
    this.disable = false;

    if (init) {
      if (form) {
        for (const item of init) {
          form.add(item.name, item.value);
        }
        this.values = form.values;
      } else {
        for (const item of init) {
          this.values[item.name] = {
            value: item.value,
            valid: item.valid || false,
            key: item.key || `form-value-${Math.random()}`,
          };
        }
      }
    }
  }

  clone() {
    const values = new FormValuesWithKeys(
      Object.keys(this.values).map((k) => {
        return {
          name: k,
          valid: this.values[k].valid,
          value: this.values[k].value,
          key: this.values[k].key,
        };
      }),
      this.id,
    );

    values.disable = this.disable;

    return values;
  }

  reset() {
    return new FormValuesWithKeys(
      Object.keys(this.values).map((k) => {
        return {
          name: k,
          valid: false,
          value: undefined,
          key: `form-value-${Math.random()}`,
        };
      }),
    );
  }

  add(key: string, value: any) {
    this.values[key] = {
      valid: false,
      value,
      key: `form-value-${Math.random()}`,
    };
  }
}

type Element = FormElement<any, FormElementProps<any>, FormElementState<any>>;

interface FormKeyedProps extends FormProps {
  values: FormValuesWithKeys;
  currency?: string;
}

export class FormKeyed extends React.Component<FormKeyedProps, FormState> {
  private readonly formElements: { [name: string]: Element } = {};
  private timeout: any = null;

  constructor(props: FormKeyedProps) {
    super(props);

    this.state = {
      valid: false,
    };
  }

  register = (element: Element) => {
    if (!element.props.id) {
      throw new Error(`ID not set for form element ${element.props.name}`);
    }

    const isNew = !this.formElements[element.props.name];
    this.formElements[element.props.name] = element;
    if (isNew) {
      this.valueChanged(element, true);

      if (this.props.initialized) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          const keys = Object.keys(this.formElements);
          const valid = keys.reduce(
            (isValid: boolean, key: string) => isValid && this.formElements[key].state.valid,
            true,
          );
          const data = this.props.values.clone();

          keys.forEach((key) => {
            data.values[key] = {
              valid: this.formElements[key].state.valid,
              value: this.formElements[key].state.value,
              key: this.formElements[key].props.id as string,
            };
          });

          if (this.props.initialized) {
            this.props.initialized(valid, data);
          }
        }, 250);
      }
    } else {
      this.valueChanged(element);
    }
  };

  valueChanged = (element: Element, skipAlert = false) => {
    const keys = Object.keys(this.formElements);
    const valid = keys.reduce((isValid: boolean, key: string) => isValid && this.formElements[key].state.valid, true);
    this.setState({ valid });
    if (this.props.onValueChanged) {
      const data = this.props.values.clone();
      keys.forEach((key) => {
        data.values[key] = {
          valid: this.formElements[key].state.valid,
          value: this.formElements[key].state.value,
          key: this.formElements[key].props.id as string,
        };
      });

      if (!skipAlert) {
        this.props.onValueChanged(valid, data, element.props.name);
      }
    }
  };

  isReactFragment = (child: any) => {
    try {
      return child.type.toString() === React.Fragment.toString();
    } catch (error) {
      return false;
    }
  };

  hasNameOrLabel(child: React.ReactElement<FormElementProps<any>>) {
    return child && child.props && (!!(child.props as any).name || !!(child.props as any).label);
  }

  mustSkipChild(child: React.ReactElement<FormElementProps<any>>) {
    if (!child || typeof child.type === 'string') {
      return true;
    }

    if (this.hasNameOrLabel(child)) {
      return false;
    }

    const { children } = child.props as any;
    if (!children) {
      return true;
    }

    if (Array.isArray(children)) {
      return !this.hasNameOrLabel(children[0]);
    }

    return !this.hasNameOrLabel(children);
  }

  render() {
    let { children } = this.props;
    if (React.Children.count(children) === 1) {
      const child = React.Children.toArray(children)[0];
      if (this.isReactFragment(child)) {
        children = (child as any).props.children;
      }
    }

    const childrenWithProps = React.Children.map(
      children as React.ReactElement<FormElementProps<any>>,
      (child: React.ReactElement<FormElementProps<any>>) => {
        if (this.mustSkipChild(child)) {
          return child;
        }
        return React.cloneElement(child, {
          onValidityChanged: this.valueChanged,
          registerWithForm: this.register,
          touched: this.props.touched || child.props.touched,
          formValid: this.state.valid,
        });
      },
    );

    if (this.props.skipFormTag) {
      return <div className={this.props.className}>{childrenWithProps}</div>;
    }

    return (
      <ReactstrapForm
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          if (this.props.onSubmit) {
            this.props.onSubmit();
          }
          return false;
        }}
        className={(this.props.sidebar ? 'sidebar-form ' : '') + (this.props.noLastMargin ? 'no-last-margin ' : '')}
      >
        {childrenWithProps}
      </ReactstrapForm>
    );
  }
}

// export const isForm = (value: any): value is IdNumberForm => {
//   if (value.props && value.props.changed) {
//     return true;
//   }
//   return false;
// };
