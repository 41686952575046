import React from 'react';
import { Event, DisplayEvent, DisplayEventProps } from '../event';
import { CallApi } from '../../../../calls/calls-api';
import { LinkButton, Transition } from '../../../../../components';

export class CallCompletedEvent extends Event<any> {
  public readonly recordingUrl: string;
  public readonly callId: string;

  constructor(init: any, entity: any) {
    super(init, entity);
    Object.assign(this, init);
  }
}

type CallCompletedDisplayEventProps = DisplayEventProps<CallCompletedEvent>;

export class CallCompletedDisplayEvent extends DisplayEvent<CallCompletedDisplayEventProps> {
  constructor(props: CallCompletedDisplayEventProps) {
    super(props);
    this.state = {
      showAudioPlayer: false,
      isLoaded: true,
    };
  }

  getSignedDownloadUrl = async () => {
    const { callId } = this.props.event;
    try {
      const { url, file_type } = await CallApi.getPlaybackUrl({ callId });
      this.setState({
        url,
        type: file_type,
        showAudioPlayer: true,
        isLoaded: true,
      });
    } catch (error) {
      this.setState({ isLoaded: true, error: error.message });
    }
  };

  getTitle = () => 'Call completed';
  renderFull = () => {
    const { url, type, showAudioPlayer, isLoaded, error } = this.state;

    return error ? (
      <div>{`Cannot retrieve call recording: ${error.toString()}`}</div>
    ) : (
      <>
        Policyholder called in.
        <br />
        <br />
        {!showAudioPlayer && (
          <LinkButton onClick={() => this.setState({ isLoaded: false }, this.getSignedDownloadUrl)}>Play</LinkButton>
        )}
        {showAudioPlayer && (
          <Transition in={isLoaded} width={100}>
            <audio controls preload='none'>
              <source src={url} type={type} />
            </audio>
          </Transition>
        )}
      </>
    );
  };
}
