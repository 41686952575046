import React, { MouseEvent } from 'react';

import { get } from '../../core';

export interface SvgProps {
  href: string;
  className?: string;
  onClick?: (event: MouseEvent) => void;
  style?: React.CSSProperties;
}

interface SvgState {
  loaded: boolean;
}

export class Svg extends React.Component<SvgProps, SvgState> {
  constructor(props: SvgProps) {
    super(props);
    this.state = {
      loaded: false,
    };

    this.getPromise().then((svg: string) => {
      localStorage.setItem(this.props.href, svg);
      this.setState({ loaded: true });
    });
  }

  getPromise() {
    const svg = localStorage.getItem(this.props.href);
    if (svg) {
      return Promise.resolve(svg);
    }

    return get<string>({
      url: this.props.href,
      isLocal: true,
    });
  }

  render() {
    return (
      <span
        style={this.props.style}
        className={this.props.className}
        onClick={this.props.onClick}
        dangerouslySetInnerHTML={{
          __html: this.state.loaded ? (localStorage.getItem(this.props.href) as string) : '',
        }}
      />
    );
  }
}
