import React from 'react';
import { Row, Col, FormGroup, FormControl, Image, Button, FormLabel } from 'react-bootstrap';
import JwtDecode from 'jwt-decode';
import ResetPasswordImage from '../images/reset-password';
import { AccountApi } from '../account-api';
import { buttonText } from '../../utils';

interface Props {
  appToken?: string;
  nextStep: () => void;
  previousStep: () => void;
  setState: any;
  userId?: string;
  login: any;
  loginToken: string;
}

interface State {
  otpType?: string;
  tfa: string;
  appToken: string;
  tfaValid?: boolean;
  twoFactorUrl?: string;
  twoFactorSecret?: string;
  loading: boolean;
  errorMessage?: string;
}

export class TwoFactorAuthentication extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tfa: '',
      appToken: '',
      loading: false,
    };
  }

  componentDidMount() {
    const { appToken } = this.props;
    if (appToken) {
      const decoded: any = JwtDecode(appToken);
      const { url } = decoded; // otpauth://totp/Root?secret=O5CSS6TVGFWWII3EIZAC4QLQG5XFGLBW
      const encoded = encodeURIComponent(`${url}&issuer=Root`);
      const twoFactorUrl = `https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=${encoded}`;
      const twoFactorSecret = url.slice(27);

      this.setState({ twoFactorUrl });
      this.setState({ twoFactorSecret });
      this.setState({ appToken });
    }
  }

  async openNext(e: any) {
    e.preventDefault();
    const code = this.state.tfa;
    const { appToken } = this.state;
    this.setState({ loading: true });

    try {
      const result = await AccountApi.activateSet2fa({
        code,
        appToken,
        loginToken: this.props.loginToken,
      });

      if (!result.success) {
        this.setState({
          errorMessage: result?.error?.message || 'Could not set 2fa',
        });
        this.setState({ loading: false });
        this.setState({ tfa: '' });
        return;
      }
      this.props.login(code);
      this.props.nextStep();
    } catch (error) {
      if (error instanceof Error) {
        this.setState({ errorMessage: error.message });
        this.setState({ loading: false });
        this.setState({ tfa: '' });
      }
    }
  }

  skipApp2fa() {
    if (window.confirm('We suggest setting up app based 2FA, are you sure you want to skip?')) {
      this.props.setState({ otpType: 'sms' });
    }
  }

  handleChange(e: any) {
    const tfa = e.target.value;
    this.setState({ tfa, tfaValid: tfa.length > 0 });
  }

  renderSideBar() {
    const icon = <ResetPasswordImage />;
    return (
      <div>
        {icon}
        <h2>Let's set up 2FA</h2>
        <p>
          Download the <a href='https://www.authy.com/app/'>Authy</a> or{' '}
          <a href='https://support.google.com/accounts/answer/1066447'>Google Authenticator</a> app, scan the QR code
          above, and enter the 6-digit code.
        </p>
      </div>
    );
  }

  renderForm() {
    return (
      <div style={{ flex: 'auto', padding: 30 }}>
        <Image src={this.state.twoFactorUrl} alt={this.state.twoFactorSecret} />
        <form style={{ marginTop: 20 }} onSubmit={this.openNext.bind(this)}>
          <FormGroup>
            <FormLabel>Enter 2FA code</FormLabel>
            <FormControl
              type='text'
              placeholder='6-digit code'
              value={this.state.tfa}
              onChange={this.handleChange.bind(this)}
            />
          </FormGroup>
          {this.state.errorMessage && <p className='error'>{this.state.errorMessage}</p>}
          <Button variant='primary' type='submit' disabled={!this.state.tfaValid || this.state.loading}>
            {buttonText({ loading: this.state.loading, text: 'Next' })}
          </Button>
          <Button style={{ verticalAlign: 'middle' }} variant='link' onClick={this.skipApp2fa.bind(this)}>
            Skip this step
          </Button>
        </form>
      </div>
    );
  }

  render() {
    return (
      <Row>
        <Col sm={5}>
          <div>{this.renderSideBar()}</div>
        </Col>
        <Col sm={7} style={{ background: '#f5f8fa' }}>
          {this.renderForm()}
        </Col>
      </Row>
    );
  }
}
