import { ValidatorResult, Validator } from './types';

export class MinValidator<T extends number = number> implements Validator<T> {
  constructor(
    private readonly name: string,
    private readonly min: number,
    private readonly isCurrency: boolean,
    private readonly currency: string,
  ) {}

  validate(value: T) {
    if (value === undefined) {
      return { valid: true };
    }

    if (typeof value !== 'number') {
      return {
        valid: false,
        message: 'Value is not a number.',
      };
    }

    const result: ValidatorResult = { valid: value >= this.min };

    if (!result.valid) {
      const min = this.isCurrency ? (this.min * 100).formatMoney(this.currency) : this.min;
      result.message = `${this.name} should be greater than or equal to ${min}.`;
    }

    return result;
  }
}
