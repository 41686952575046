import React from 'react';
import { withRouter } from 'react-router-dom';
import { Event, DisplayEvent } from '../event';
import { ComplaintEventType } from './complaint-event-type';
import { CreatedByType } from '../../../created-by';
import { LinkButton } from '../../../../../components';
import { navigateTo } from '../../../../../core';
import { Context } from '../../../../framework';
import { Complaint } from '../../../../complaints/domain/complaint';

export class ComplaintClosedEvent extends Event<any> {
  public static New(complaint: Complaint) {
    return new ComplaintClosedEvent(
      {
        type: ComplaintEventType.ComplaintClosed,
        requestedBy: {
          type: CreatedByType.System, // TODO
        },
      } as any,
      complaint,
    );
  }
}

class ComplaintClosedDisplayEventImpl extends DisplayEvent {
  getTitle = () => 'Complaint closed';
  renderFull = () => {
    const { history } = this.props as any;
    const complaintId = Context.secondId;
    if (this.props.event?.entity?.complaintId === complaintId) {
      return null;
    }
    return (
      <>
        <LinkButton
          onClick={() =>
            navigateTo({
              url: `/orgs/${Context.organizationId}/insurance/complaints/${this.props.event.entity.complaintId}`,
              history,
            })
          }
        >
          View complaint
        </LinkButton>
      </>
    );
  };
}

export const ComplaintClosedDisplayEvent = withRouter(ComplaintClosedDisplayEventImpl as any);
