import { Construct } from '../../models/construct';
import { NetworkFeatureFlag, FeatureFlag } from './feature-flags';

// THIS SHOULD BE MOVED OUT

export class Organization {
  readonly organizationId: string;
  readonly name: string;
  readonly description: string;
  readonly ownerId: string;
  readonly reviewed: boolean;
  readonly featureFlags: any[];
  readonly icon?: string;
  readonly website?: string;
  readonly ficaEnabled?: boolean;
  readonly qaEnabled?: boolean;
  readonly validOutboundEmailDomains: string[];
  readonly timezone: string;

  constructor(init: Construct<Organization>) {
    Object.assign(this, init);
  }

  toNetwork() {
    return {
      organization_id: this.organizationId,
      name: this.name,
      description: this.description,
      owner: this.ownerId,
      reviewed: this.reviewed,
      icon: this.icon,
      website: this.website,
      fica_enabled: this.ficaEnabled,
      qa_enabled: this.qaEnabled,
      valid_outbound_email_domains: this.validOutboundEmailDomains,
      timezone: this.timezone,
    };
  }

  static fromNetwork(init: NetworkOrganization) {
    return new Organization({
      organizationId: init.organization_id,
      name: init.name,
      description: init.description,
      ownerId: init.owner,
      reviewed: init.reviewed,
      icon: init.icon,
      website: init.website,
      ficaEnabled: init.fica_enabled,
      qaEnabled: init.qa_enabled,
      featureFlags: init.feature_flags ? init.feature_flags.map(FeatureFlag.fromNetwork) : [],
      validOutboundEmailDomains: init.valid_outbound_email_domains,
      timezone: init.timezone,
    });
  }
}

export interface NetworkOrganization {
  organization_id: string;
  name: string;
  description: string;
  feature_flags: NetworkFeatureFlag[];
  reviewed: boolean;
  owner: string;
  icon?: string;
  website?: string;
  fica_enabled?: boolean;
  reports_enabled?: boolean;
  qa_enabled?: boolean;
  valid_outbound_email_domains: string[];
  timezone: string;
}
