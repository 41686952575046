import { NetworkEvent } from '../network-event';
import { Event } from '../event';
import { CallFailedEvent, CallFailedDisplayEvent } from './call-failed';
import { CallCompletedEvent, CallCompletedDisplayEvent } from './call-completed';
import { Call, CallEventType } from '../../../../calls/domain/call';

export class CallEventFactory {
  public static fromNetwork(event: NetworkEvent, entity: Call) {
    switch (event.type) {
      case CallEventType.CallCompleted:
        return new CallCompletedEvent(event as any, entity);
      case CallEventType.CallFailed:
        return new CallFailedEvent(event as any, entity);
      default:
        return null;
    }
  }

  public static toDisplayEvent(event: Event<any>) {
    switch (event.type) {
      case CallEventType.CallCompleted:
        return CallCompletedDisplayEvent;
      case CallEventType.CallFailed:
        return CallFailedDisplayEvent;
      default:
        return null;
    }
  }
}
