import { Identifier } from './identifier';

export enum EntityType {
  Lead = 'LEAD',
  Policyholder = 'POLICYHOLDER',
  Policy = 'POLICY',
  Application = 'APPLICATION',
  Claim = 'CLAIM',
  Complaint = 'COMPLAINT',
  Call = 'CALL',
  Member = 'MEMBER',
  Array = 'ARRAY',
  ApiKey = 'APIKEY',
  User = 'USER',
  Event = 'EVENT',
  Module = 'MODULE',
  Document = 'DOCUMENT',
  Note = 'NOTE',
  Payment = 'PAYMENT',
  DataExportRunLogItem = 'DATA_EXPORT_RUN_LOG_ITEM',
}

export enum FetchState {
  Deferred,
  Loading,
  Loaded,
  Error,
}

export interface IEntity {
  fetchState: FetchState;

  canLoad(): boolean;

  load(): void;

  readonly guid: Identifier;
  readonly entityType: EntityType;

  readonly hasLoaded: boolean;
  readonly hasError: boolean;
}
