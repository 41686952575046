import momentTimezone from 'moment-timezone';
import _ from 'lodash';
import { ClaimNotificationType } from 'insurance/communications/domain/notification-type';
import { NetworkEvent } from './network-event';
import { Event } from './event';
import { snakeToCamel } from '../../../utils';
import { PolicyEventFactory } from './policy/policy-event-factory';
import { PolicyholderEventFactory } from './policyholder/policyholder-event-factory';
import { ClaimEventFactory } from './claims/claim-event-factory';
import { ComplaintEventFactory } from './complaints/complaint-event-factory';
import { GeneralEventFactory } from './general/general-event-factory';
import { CreatedByType } from '../../created-by';
import { MemberEventFactory } from './policy/member-event-factory';
import { CallEventFactory } from './calls/call-event-factory';
import { ApplicationEventFactory } from './applications/application-event-factory';
import { NotificationCreatedEvent } from './general/notification-created-event';
import { NotificationStatus } from '../../../notifications/domain/notification-status';

const isClaimReviewNotification = (event: Event): event is NotificationCreatedEvent =>
  event instanceof NotificationCreatedEvent &&
  [ClaimNotificationType.ClaimDecisionReview, ClaimNotificationType.ClaimSentToReview].includes(
    event.notification.notificationType as ClaimNotificationType,
  );

/**
 * To avoid spamming the claim activity bar with a notification event per recipient, we remove
 * "similar" claim review notification events based on notifications being created within 5
 * seconds of each other.
 *
 * Because we're picking just one event from the list, it would be confusing UX to only show
 * status updates and recipient information for the one notification. Therefore, we also
 * remove those attributes on all claim review events.
 *
 * At some stage, this should be reassessed and have a better back-end solution put in place.
 */
const squashClaimReviewEvents = (events: Event[]) =>
  _.uniqWith(
    events,
    (a, b) =>
      isClaimReviewNotification(a) &&
      isClaimReviewNotification(b) &&
      a.notification.notificationType === b.notification.notificationType &&
      Math.abs(a.createdAt.diff(b.createdAt, 'seconds')) < 5,
  ).map((event) => {
    if (isClaimReviewNotification(event)) {
      event.notification = {
        ...event.notification,
        status: NotificationStatus.Sent,
        statusUpdates: [],
        data: {
          ...event.notification.data,
          to: [],
        },
      };
    }

    return event;
  });

function stableSort(events: NetworkEvent[]) {
  const original = [...events];

  events.sort((a, b) => {
    const result = momentTimezone(b.created_at).diff(momentTimezone(a.created_at));
    return result === 0 ? original.indexOf(a) - original.indexOf(b) : result;
  });

  return events;
}

export class EventFactory {
  public static fromNetwork(events: NetworkEvent[], entity: any) {
    return squashClaimReviewEvents(
      stableSort(events)
        .map((e) => {
          e.requested_by = e.requested_by || {
            id: '',
            type: CreatedByType.System,
          };
          const networkEvent = snakeToCamel(e);
          return (
            PolicyEventFactory.fromNetwork(networkEvent, entity) ||
            ClaimEventFactory.fromNetwork(networkEvent, entity) ||
            ComplaintEventFactory.fromNetwork(networkEvent, entity) ||
            PolicyholderEventFactory.fromNetwork(networkEvent, entity) ||
            GeneralEventFactory.fromNetwork(networkEvent, entity) ||
            MemberEventFactory.fromNetwork(networkEvent, entity) ||
            CallEventFactory.fromNetwork(networkEvent, entity) ||
            ApplicationEventFactory.fromNetwork(networkEvent, entity)
          );
        })
        .filter((e) => e !== null) as Event<any>[],
    );
  }

  public static toDisplayEvent(event: Event<any>) {
    return (
      PolicyEventFactory.toDisplayEvent(event) ||
      ClaimEventFactory.toDisplayEvent(event) ||
      ComplaintEventFactory.toDisplayEvent(event) ||
      PolicyholderEventFactory.toDisplayEvent(event) ||
      GeneralEventFactory.toDisplayEvent(event) ||
      MemberEventFactory.toDisplayEvent(event) ||
      CallEventFactory.toDisplayEvent(event) ||
      ApplicationEventFactory.toDisplayEvent(event)
    );
  }
}
