import React from 'react';
import { Event, DisplayEvent, DisplayEventProps } from '../event';
import { LinkButton } from '../../../../../components';
import { ViewFileModal } from '../../../../documents/view-file-modal';

export class PolicyScheduleUpdatedEvent extends Event<any> {
  policyScheduleUri: string;

  constructor(init: any, entity: any) {
    super(init, entity);
    Object.assign(this, init);
  }
}

type PolicyScheduledDisplayEventProps = DisplayEventProps<PolicyScheduleUpdatedEvent>;

export class PolicyScheduleUpdatedDisplayEvent extends DisplayEvent<
  PolicyScheduledDisplayEventProps,
  { showModal: boolean }
> {
  constructor(props: PolicyScheduledDisplayEventProps) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  getTitle = () => 'Policy schedule updated';
  renderFull = () => {
    const fileUrl = this.props.event.policyScheduleUri;
    const fileName = 'Policy schedule';
    const fileType = 'application/pdf';

    return (
      <>
        <LinkButton onClick={() => this.setState({ showModal: true })}>View policy schedule</LinkButton>
        {this.state.showModal && (
          <ViewFileModal
            fileName={fileName}
            fileUrl={fileUrl}
            fileType={fileType}
            close={() => this.setState({ showModal: false })}
          />
        )}
      </>
    );
  };
}
