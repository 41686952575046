import { ValidatorResult, Validator } from './types';

export class MinLengthValidator<T extends string = string> implements Validator<T> {
  constructor(private readonly name: string, private readonly minLength: number) {}

  validate(value: T) {
    if (value === undefined) {
      return { valid: true };
    }

    if (typeof value !== 'string') {
      return {
        valid: false,
        message: 'Value is not a string.',
      };
    }

    const result: ValidatorResult = { valid: value.length >= this.minLength };

    if (!result.valid) {
      result.message = `${this.name} should be at least ${this.minLength} characters long.`;
    }

    return result;
  }
}
