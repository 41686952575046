import { Environment } from '../models/environment';
import { Organization } from '../general';

export class Context {
  public static get organizationId() {
    return window.location.pathname.split('/')[2];
  }

  public static get secondId() {
    return window.location.pathname.split('/')[5];
  }

  public static get thirdId() {
    return window.location.pathname.split('/')[7];
  }

  public static initEnvironment() {
    const { organization } = this;

    if (!organization) {
      return;
    }

    const key = `${organization.organizationId}_sandbox`;

    // If organization is not reviewed, or there is no existing stored environment,
    // then override the current setting to sandbox.
    if (!organization.reviewed || !this.environment) {
      localStorage.setItem(key, 'true');
    }
  }

  public static get environment() {
    const { organization } = this;

    if (!organization) {
      return Environment.Sandbox;
    }

    const key = `${organization.organizationId}_sandbox`;
    const entry = localStorage.getItem(key);

    return entry === 'true' ? Environment.Sandbox : Environment.Production;
  }

  public static get organization() {
    const organizations = JSON.parse(localStorage.getItem('organizations') as string) as Organization[];
    const organization = (organizations || []).find((x) => x.organizationId === Context.organizationId);

    return organization;
  }

  public static isLiveOrgInSandbox() {
    const { environment, organization } = Context;
    if (organization?.reviewed && environment === Environment.Sandbox) {
      return true;
    }
    return false;
  }

  public static goToSandbox() {
    const { organization } = Context;

    if (!organization) {
      return;
    }

    const key = `${organization.organizationId}_sandbox`;

    localStorage.setItem(key, 'true');
    window.location.reload();
  }

  public static goToLive() {
    const { organization } = Context;

    if (!organization) {
      return;
    }

    const key = `${organization.organizationId}_sandbox`;

    localStorage.setItem(key, 'false');
    window.location.reload();
  }
}
