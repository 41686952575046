export enum ApplicationEventType {
  ApplicationCreated = 'application_created',
  ApplicationUsed = 'application_used',
  TextNoteCreated = 'text_note_created',
  AttachmentCreated = 'attachment_created',
  ApplicationBeneficiariesUpdated = 'application_beneficiaries_updated',
  PaymentMethodAssigned = 'payment_method_assigned',
  ApplicationFlushed = 'application_flushed',
  ApplicationUpdated = 'application_updated',
  ApplicationArchived = 'application_archived',
  SendApplicationPdfToPolicyholder = 'send_application_pdf_to_policyholder',
}
