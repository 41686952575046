import React from 'react';
import { withRouter } from 'react-router-dom';
import { Event, DisplayEvent } from '../event';
import { LinkButton } from '../../../../../components';
import { navigateTo } from '../../../../../core';
import { Context, FeatureContext, Permission } from '../../../../framework';

export class ClaimLinkedEvent extends Event<any> {}

class ClaimLinkedDisplayEventImpl extends DisplayEvent {
  getTitle = () => {
    return 'Claim linked';
  };

  renderFull = () => {
    const { history } = this.props as any;
    const canReadClaims = FeatureContext.instance.hasPermission(Permission.ReadClaims);
    return (
      <>
        {canReadClaims && (
          <LinkButton
            onClick={() =>
              navigateTo({
                url: `/orgs/${Context.organizationId}/insurance/claims/${this.props.event?.claimId}`,
                history,
              })
            }
          >
            View claim
          </LinkButton>
        )}
      </>
    );
  };
}

export const ClaimLinkedDisplayEvent = withRouter(ClaimLinkedDisplayEventImpl as any);
