export enum NetworkErrorType {
  Unauthorized,
  NotFound,
  InternalServer,
  BadRequest,
  Unknown,
}

export class NetworkError {
  constructor(
    public readonly code: number,
    public readonly type: NetworkErrorType,
    public readonly message: string,
    public readonly rawText: string,
    public readonly errors?: object,
  ) {}
}
