import React from 'react';
import { Row, Col, FormGroup, FormControl, OverlayTrigger, Popover, Button, FormLabel } from 'react-bootstrap';
import { checkPassword } from '../utils';
import Vault from '../images/vault';
import { AccountApi } from '../account-api';
import { PrimaryButton } from '../../../components';
import { buttonText } from '../../utils';

interface Props {
  email?: string;
  userId?: string;
  nextStep: () => void;
  setState: any;
  name?: string;
  loginToken: string;
}

interface State {
  password: any;
  passwordValid?: boolean;
  loading: boolean;
  errorMessage?: string;
}

export class Password extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      password: '',
      loading: false,
    };
  }

  componentDidMount() {}

  openNext = async (e: any) => {
    e.preventDefault();
    const { email, userId, loginToken } = this.props;
    const { password } = this.state;

    this.setState({ loading: true });

    if (!loginToken) {
      window.location.href = '/';
      return;
    }
    try {
      const result = await AccountApi.activateSetPassword({
        email,
        password,
        userId,
        loginToken,
      });

      if (!result.success) {
        return;
      }

      this.setState({ loading: false });
      this.props.setState(
        {
          appToken: result.app_token,
          noCard: result.no_card,
          cardActivatorId: result.card_activator_id,
          password,
        },
        () => {
          this.props.nextStep();
        },
      );
    } catch (error) {
      this.setState({ loading: false });
      this.setState({ errorMessage: error.message || error });
      console.error('Password Error:', error);
      return error;
    }
  };

  handlePasswordChange(e: any) {
    const password = e.target.value;
    let passwordValid = true;
    try {
      checkPassword(password);
    } catch (error) {
      passwordValid = false;
    }
    this.setState({ password, passwordValid });
  }

  renderSideBar() {
    const icon = <Vault />;
    const passwordPopover = (
      <Popover id='popover-trigger-click' title='Password requirements'>
        <ul className='password-rules'>
          <li>Must be 10 or more characters</li>
          <li>Must be 128 or less characters</li>
          <li>
            If less than 20 characters, it must satisfy at least 3 of the following:
            <ul>
              <li>at least one lowercase letter</li>
              <li>at least one uppercase letter</li>
              <li>at least one digit</li>
              <li>at least one special character</li>
            </ul>
          </li>
        </ul>
      </Popover>
    );
    return (
      <div>
        {icon}
        <h2 style={{ fontWeight: 400, marginTop: 20 }}>
          Hi {this.props.name || 'there'}, let's set
          <br />
          your account password
        </h2>
        <p>
          Please create a secure password for your Root account. We strongly suggest using <br />{' '}
          <a href='https://1password.com/'>1Password</a> or <a href='https://www.lastpass.com/'>LastPass</a>
          <OverlayTrigger trigger='click' rootClose placement='bottom' overlay={passwordPopover}>
            <Button variant='link'>View our password requirements</Button>
          </OverlayTrigger>
        </p>
      </div>
    );
  }

  renderForm() {
    return (
      <div style={{ flex: 'auto', padding: 30 }}>
        <form onSubmit={(e: any) => this.openNext(e)}>
          <FormGroup>
            <FormLabel>Enter new password</FormLabel>
            <FormControl
              type='password'
              placeholder='Password'
              value={this.state.password}
              onChange={this.handlePasswordChange.bind(this)}
            />
          </FormGroup>
          <PrimaryButton variant='primary' type='submit' disabled={!this.state.passwordValid || this.state.loading}>
            {buttonText({ loading: this.state.loading, text: 'Next' })}
          </PrimaryButton>
        </form>
      </div>
    );
  }

  render() {
    return (
      <Row>
        <Col sm={5}>
          <div>{this.renderSideBar()}</div>
        </Col>
        <Col sm={7} style={{ background: '#f5f8fa' }}>
          {this.renderForm()}
        </Col>
      </Row>
    );
  }
}
