import { Permission } from './permissions';

export class FeatureContext {
  private static _instance: FeatureContext;

  private _permissions: Permission[];
  private _hasLiveAccess: boolean;
  private _hasSandboxAccess: boolean;

  private constructor() {}

  public static get instance(): FeatureContext {
    if (!FeatureContext._instance) {
      FeatureContext._instance = new FeatureContext();
      (window as any).root.context = FeatureContext._instance;
    }

    return FeatureContext._instance;
  }

  public hasPermission(permission: Permission) {
    return (FeatureContext._instance._permissions || []).includes(permission);
  }

  public static setPermissions(permissions: Permission[]) {
    FeatureContext.instance._permissions = permissions;
  }

  public static setEnvironmentAccess(params: { hasLiveAccess: boolean; hasSandboxAccess: boolean }) {
    FeatureContext.instance._hasLiveAccess = params.hasLiveAccess;
    FeatureContext.instance._hasSandboxAccess = params.hasSandboxAccess;
  }

  public hasLiveAccess() {
    return !!FeatureContext._instance._hasLiveAccess;
  }

  public hasSandboxAccess() {
    return !!FeatureContext._instance._hasSandboxAccess;
  }
}
