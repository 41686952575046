import * as React from 'react';
import uuid from 'uuid';
import { Modal, ModalHeader, ModalBody, LinkButton } from '../../../../../components';
import { EmailNotification } from './notification-created-event';
import { ViewFileModal } from '../../../../documents/view-file-modal';

const removeTrackingPixelImage = (htmlString: string) => {
  const fakeDom = document.createElement('html');

  // breaks the link to avoid calling the receipt endpoint
  fakeDom.innerHTML = htmlString
    .replace('/v1/insurance/policies/receipts', '/t-pixel')
    .replace('/receipt/receipt.png', '/t-pixel');

  const images = [...fakeDom.getElementsByTagName('img')];

  images.forEach((image) => {
    if (image.src.indexOf('/t-pixel') > -1 && image.parentNode) {
      // this only to satisfy typescript
      image.parentNode.removeChild(image);
    }
  });

  return fakeDom.innerHTML;
};

export interface ViewEmailModalProps {
  notification: EmailNotification;
  title: string;
  close: () => void;
}

export class ViewEmailModal extends React.Component<ViewEmailModalProps, {}> {
  iframe() {
    return (
      <iframe
        title={uuid().toString()}
        style={{ border: '1px solid #eee', width: '100%', flex: '1' }}
        srcDoc={removeTrackingPixelImage(this.props.notification.html)}
      />
    );
  }

  render() {
    const to = this.props.notification.to.map((t) => `${t.name} - ${t.email}`).join(', ');
    return (
      <Modal isOpen toggle={this.props.close} className='email-modal'>
        <ModalHeader toggle={this.props.close}>{this.props.title}</ModalHeader>
        <ModalBody style={{ height: '65vh', display: 'flex', flexDirection: 'column' }}>
          <div style={{ flex: '0 0 auto', marginBottom: '10px' }}>
            <table className='summary-table no-hover full-width compressed'>
              <tbody>
                <tr>
                  <td style={{ width: '65px' }}>
                    <b>From:</b>
                  </td>
                  <td>
                    {this.props.notification.fromName} - {this.props.notification.fromEmail}
                  </td>
                </tr>
                {to.length > 0 && (
                  <tr>
                    <td>
                      <b>To:</b>
                    </td>
                    <td>{to}</td>
                  </tr>
                )}
                <tr>
                  <td>
                    <b>Subject:</b>
                  </td>
                  <td>{this.props.notification.subject}</td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
          </div>
          <div style={{ display: 'flex', flex: '1', marginBottom: '24px' }}>{this.iframe()}</div>
        </ModalBody>
      </Modal>
    );
  }
}

export interface EmailViewProps {
  notification: EmailNotification;
  title: string;
  linkText?: string;
}

export interface EmailViewState {
  modalOpen: boolean;
  attachmentModalsOpen: boolean[];
}

export class EmailView extends React.Component<EmailViewProps, EmailViewState> {
  constructor(props: EmailViewProps) {
    super(props);
    const attachments = props.notification.attachments || [];
    const attachmentModalsOpen = attachments.map(() => false);
    this.state = {
      modalOpen: false,
      attachmentModalsOpen,
    };
  }

  openModal = () => {
    this.setState({ modalOpen: true });
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  openAttachmentModal = (index: number) => {
    const attachmentModalsOpen = this.state.attachmentModalsOpen.map((_, i) => i === index);
    this.setState({ attachmentModalsOpen });
  };

  closeAttachmentModals = () => {
    const attachmentModalsOpen = this.state.attachmentModalsOpen.map(() => false);
    this.setState({ attachmentModalsOpen });
  };

  renderAttachments = () => {
    const { attachments } = this.props.notification;
    return (
      <span>
        | Attachments:{' '}
        {attachments && attachments.length > 0
          ? attachments.map((a, i) => {
              return (
                <span key={i}>
                  {a.fileId ? (
                    <span>
                      <LinkButton onClick={() => this.openAttachmentModal(i)}>{a.name}</LinkButton>
                      {this.state.attachmentModalsOpen[i] && (
                        <ViewFileModal
                          fileUrl={a.fileUrl}
                          fileType={a.type}
                          fileName={a.name}
                          close={() => this.closeAttachmentModals()}
                        />
                      )}
                    </span>
                  ) : (
                    <span>
                      <span>{a.name}</span>
                    </span>
                  )}{' '}
                </span>
              );
            })
          : 'None'}
      </span>
    );
  };

  render() {
    return (
      <div>
        <LinkButton onClick={this.openModal}>{this.props.linkText || 'View email'}</LinkButton>{' '}
        {this.renderAttachments()}
        {this.state.modalOpen && <ViewEmailModal close={this.closeModal} {...this.props} />}
      </div>
    );
  }
}
