import React from 'react';
import { withRouter } from 'react-router-dom';
import { Event, DisplayEvent, DisplayEventProps } from '../event';
import { LinkButton } from '../../../../../components';
import { navigateTo } from '../../../../../core';
import { Context, FeatureContext, Permission } from '../../../../framework';

export class ClaimOpenedEvent extends Event<any> {
  public claimId: string;
}

export class ClaimOpenedDisplayEventImpl extends DisplayEvent<DisplayEventProps<ClaimOpenedEvent>> {
  getTitle = () => {
    return 'Claim opened';
  };

  renderFull = () => {
    const history = this.props.history as any;
    const canReadClaims = FeatureContext.instance.hasPermission(Permission.ReadClaims);
    return (
      <>
        {canReadClaims && (
          <LinkButton
            onClick={() =>
              navigateTo({
                url: `/orgs/${Context.organizationId}/insurance/claims/${this.props.event?.entity?.claimId}`,
                history,
              })
            }
          >
            View
          </LinkButton>
        )}
      </>
    );
  };
}

export const ClaimOpenedDisplayEvent = withRouter(ClaimOpenedDisplayEventImpl as any);
