import React, { ReactElement } from 'react';
import { Spinner as StyledSpinner, Dot as StyledDot } from './styles';

export default function Spinner(): ReactElement {
  return (
    <StyledSpinner>
      <StyledDot />
      <StyledDot />
      <StyledDot />
    </StyledSpinner>
  );
}
