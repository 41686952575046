import { IEntity, FetchState, EntityType } from './entity-interface';
import { Identifier } from './identifier';

export abstract class Entity implements IEntity {
  abstract readonly guid: Identifier;
  abstract readonly entityType: EntityType;

  protected _fetchState: FetchState = FetchState.Deferred;

  public get fetchState() {
    if (this._fetchState === FetchState.Deferred) {
      this._load();
      this._fetchState = FetchState.Loading;
      return FetchState.Deferred;
    }

    return this._fetchState;
  }

  public set fetchState(nextState: FetchState) {
    this._fetchState = nextState;
  }

  public canLoad() {
    return this._fetchState === FetchState.Deferred || this._fetchState === FetchState.Error;
  }

  public get hasLoaded() {
    return this.fetchState === FetchState.Loaded;
  }

  public get hasError() {
    return this._fetchState === FetchState.Error;
  }

  public load() {
    this._load();
  }

  protected abstract _load(): void;
}

interface Constructor<T> {
  new (...args: any[]): T;
}

/**
 * See https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
 * and https://github.com/Microsoft/TypeScript/issues/13720
 */
export function BuiltinTypeInheritanceFix(): (target: Constructor<any>) => any {
  return (target: Constructor<any>) => {
    return class extends target {
      constructor(...args: any[]) {
        super(...args);
        Object.setPrototypeOf(this, target.prototype);
      }
    };
  };
}

/**
 * See https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
 * and https://github.com/Microsoft/TypeScript/issues/13720
 */
export function PrivateBuiltinTypeInheritanceFix(): (target: Constructor<any>) => any {
  return (target: Constructor<any>) => {
    return class extends target {
      private constructor(...args: any[]) {
        super(...args);
        Object.setPrototypeOf(this, target.prototype);
      }
    };
  };
}

export abstract class BaseEntityArray<T extends IEntity> extends Array<T> implements IEntity {
  public guid = new Identifier('');
  public entityType = EntityType.Array;

  protected _fetchState: FetchState = FetchState.Deferred;

  public get fetchState() {
    if (this._fetchState === FetchState.Deferred) {
      this._load();
      this._fetchState = FetchState.Loading;
      return FetchState.Deferred;
    }
    return this._fetchState;
  }

  public set fetchState(nextState: FetchState) {
    this._fetchState = nextState;
  }

  public load() {
    this._load();
  }

  public canLoad() {
    return this._fetchState === FetchState.Deferred || this._fetchState === FetchState.Error;
  }

  public get hasLoaded() {
    return this._fetchState === FetchState.Loaded;
  }

  public get hasError() {
    return this._fetchState === FetchState.Error;
  }

  protected abstract _load(): void;

  protected setStateFromEntities() {
    const states = this.map((entity) => (entity as any)._fetchState);
    if (states.length === 0) {
      this._fetchState = FetchState.Loaded;
      return;
    }

    if (states.some((state) => state === FetchState.Error)) {
      this._fetchState = FetchState.Error;
      return;
    }

    if (states.some((state) => state === FetchState.Loading)) {
      this._fetchState = FetchState.Loading;
      return;
    }

    if (states.every((state) => state === FetchState.Loaded)) {
      this._fetchState = FetchState.Loaded;
      return;
    }

    this._fetchState = FetchState.Deferred;
  }
}

export abstract class BaseEventArray<T extends IEntity> extends BaseEntityArray<T> implements IEntity {
  protected setStateFromEntities() {
    const states = this.map((entity) => (entity as any)._fetchState);
    if (states.length === 0) {
      this._fetchState = FetchState.Deferred;
      return;
    }

    if (states.some((state) => state === FetchState.Error)) {
      this._fetchState = FetchState.Error;
      return;
    }

    if (states.some((state) => state === FetchState.Loading)) {
      this._fetchState = FetchState.Loading;
      return;
    }

    if (states.every((state) => state === FetchState.Loaded)) {
      this._fetchState = FetchState.Loaded;
      return;
    }

    this._fetchState = FetchState.Deferred;
  }
}
