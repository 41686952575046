import React from 'react';
import { Event, DisplayEvent } from '../event';
import { ClaimEventType } from './claim-event-type';
import { CreatedByType } from '../../../created-by';
import { Claim } from '../../../../claims/domain/claim';

export class ClaimGoodwillEvent extends Event<any> {
  public static New(reason: string, claim: Claim) {
    return new ClaimGoodwillEvent(
      {
        type: ClaimEventType.ClaimGoodwill,
        reason,
        requestedBy: {
          type: CreatedByType.System, // TODO
        },
      } as any,
      claim,
    );
  }
}

export class ClaimGoodwillDisplayEvent extends DisplayEvent {
  getTitle = () => 'Claim goodwill';
  renderFull = () => (
    <div>
      Decision summary: <br /> {(this.props.event as any).reason}
    </div>
  );
}
