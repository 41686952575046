import { Policy } from 'insurance/policies/domain/policy';
import {
  PolicyCancellationRequestor,
  PolicyClientCancellationOptions,
  PolicyInsurerCancellationOptions,
} from 'insurance/policies/views/policy-view/modals/cancel-policy-modal';
import React from 'react';
import { sentenceCase } from 'sentence-case';
import { DisplayEvent, DisplayEventProps, Event } from '../event';

export class PolicyCancelledEvent extends Event<any> {
  public readonly reasonCancelled: string;
  public readonly cancellationRequestor?: PolicyCancellationRequestor;
  public readonly cancellationType?: PolicyClientCancellationOptions | PolicyInsurerCancellationOptions;
}

type PolicyCancelledEventProps = DisplayEventProps<PolicyCancelledEvent>;

export class PolicyCancelledDisplayEvent extends DisplayEvent<PolicyCancelledEventProps> {
  getTitle = () => 'Cancelled';
  renderFull = () => {
    const { reasonCancelled, cancellationRequestor, cancellationType } = this.props.event.entity as Policy;
    return (
      <div>
        {cancellationRequestor && <p>{`Cancellation requestor: ${sentenceCase(cancellationRequestor)}`}</p>}
        {cancellationType && <p>{`Cancellation type: ${sentenceCase(cancellationType)}`}</p>}
        {reasonCancelled && <p>{`Reason: ${sentenceCase(reasonCancelled)}`}</p>}
      </div>
    );
  };
}
