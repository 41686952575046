import { Construct } from '../../models/construct';
import { ProductModuleDefinition, NetworkProductModuleDefinition } from './product-module-definition';

// should we allow any product modules in this list that does not have a live id?
// should the api call just filter them out so that we have a more predictable set
export class ProductModuleDeprecated {
  readonly productModuleId: string;
  readonly restricted: boolean;
  readonly organizationId: string;
  readonly key: string;
  readonly name: string;
  readonly draftId?: string;
  readonly live: boolean;
  readonly liveId?: string;
  readonly reviewId?: string;
  readonly createdBy: any;
  readonly createdAt: string;
  readonly productModuleDefinition: ProductModuleDefinition;
  readonly ownedByOrganizationId?: string;

  constructor(init: Construct<ProductModuleDeprecated>) {
    Object.assign(this, init);
  }

  static fromNetwork(init: NetworkProductModuleDeprecated) {
    return new ProductModuleDeprecated({
      productModuleId: init.product_module_id,
      organizationId: init.organization_id,
      restricted: init.restricted,
      key: init.key,
      name: init.name,
      draftId: init.draft_id,
      live: init.live,
      liveId: init.live_id,
      productModuleDefinition:
        init.product_module_definition && ProductModuleDefinition.fromNetwork(init.product_module_definition),
      reviewId: init.review_id,
      createdBy: init.created_by,
      createdAt: init.created_at,
      ownedByOrganizationId: init.owned_by_organization_id,
    });
  }
}

export interface NetworkProductModuleDeprecated {
  product_module_id: string;
  restricted: boolean;
  key: string;
  name: string;
  draft_id?: string;
  live: boolean;
  live_id?: string;
  product_module_definition: NetworkProductModuleDefinition;
  review_id?: string;
  created_by: any;
  created_at: string;
  organization_id: string;
  owned_by_organization_id: string;
}
