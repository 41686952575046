import { ApiError } from 'insurance/shared/api';
import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from './modals';

export interface WizardModalProps {
  open: boolean;
  close: () => void;
  valid?: boolean;
}

export interface WizardModalState<TStep> {
  loading: boolean;
  error?: string;
  step: TStep;
}

interface Error {
  error: string;
}

interface AsyncAction {
  onResolve: (callback: any) => any;
  onReject: (callback: any) => any;
}

export abstract class WizardModal<
  TStep extends number,
  TProps extends WizardModalProps = WizardModalProps,
  TState extends WizardModalState<TStep> = WizardModalState<TStep>
> extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);

    this.state = {
      loading: false,
      step: 0,
    } as TState;
  }

  toggle = () => {
    if (this.state.loading) {
      return;
    }

    this.close();
  };

  isDanger = () => false;

  render() {
    return (
      <Modal animated={this.state.loading} danger={this.isDanger()} isOpen={this.props.open} toggle={this.toggle}>
        <ModalHeader toggle={this.close}>{this.title(this.state.step)}</ModalHeader>
        <ModalBody>
          {this.state.error && <div className='modal-body-error'>{this.state.error}</div>}
          {this.body(this.state.step)}
        </ModalBody>
        <ModalFooter>{this.footer(this.state.step)}</ModalFooter>
      </Modal>
    );
  }

  nextStep = () => {
    this.setState({ step: (this.state.step + 1) as TStep });
  };

  prevStep = () => {
    this.setState({ step: (this.state.step - 1) as TStep });
  };

  submitClicked = () => {
    this.setState({ loading: true });

    const promise = this.submit();
    if (promise instanceof Promise) {
      promise
        .then(() => {
          this.close();
        })
        .catch((error: Error | { error: ApiError }) =>
          this.setState({ loading: false, error: typeof error.error === 'string' ? error.error : error.error.message }),
        );
    } else {
      promise
        .onResolve(() => {
          this.close();
        })
        .onReject((error: Error) => this.setState({ loading: false, error: error.error }));
    }
  };

  close = () => {
    this.setState({ step: 0 as TStep });
    this.props.close();
  };

  abstract submit(): Promise<any> | AsyncAction;
  abstract title: (step: TStep) => string | null;
  abstract body: (step: TStep) => JSX.Element | null;
  abstract footer: (step: TStep) => JSX.Element | null;
}
