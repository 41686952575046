// @ts-ignore no type definitions for checkdigit
import * as checkdigit from 'checkdigit';
import { ValidatorResult, Validator } from './types';

export enum ChecksumAlgorithm {
  Mod10 = 'mod10',
}

export class ChecksumValidator<T extends string = string> implements Validator<T> {
  constructor(private readonly name: string, private readonly checksum: ChecksumAlgorithm) {}

  validate(value: T) {
    if (value === undefined) {
      return { valid: true };
    }
    let valid = false;
    switch (this.checksum) {
      case ChecksumAlgorithm.Mod10:
        valid = checkdigit.mod10.isValid(value);
    }

    const result: ValidatorResult = { valid };

    if (!result.valid) {
      result.message = `${this.name} does not pass checksum check.`;
    }

    return result;
  }
}
