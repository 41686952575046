import React from 'react';

export default class Vault extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='110px'
        height='89.574px'
        viewBox='0 0 110 89.574'
        enableBackground='new 0 0 110 89.574'
        xmlSpace='preserve'
      >
        <g>
          <ellipse fill='#0E131D' cx='55' cy='87.299' rx='55' ry='2.275' />
          <g>
            <path
              fill='#9DC3ED'
              d='M38.1,82.794c0,1.848-1.498,3.347-3.347,3.347h-9.029c-1.848,0-3.347-1.499-3.347-3.347l0,0
                    c0-1.849,1.499-3.345,3.347-3.345h9.029C36.602,79.449,38.1,80.945,38.1,82.794L38.1,82.794z'
            />
            <path
              fill='#9DC3ED'
              d='M87.122,82.794c0,1.848-1.498,3.347-3.346,3.347h-9.03c-1.848,0-3.346-1.499-3.346-3.347l0,0
                    c0-1.849,1.498-3.345,3.346-3.345h9.03C85.624,79.449,87.122,80.945,87.122,82.794L87.122,82.794z'
            />
          </g>
          <g>
            <path
              fill='#CAE0FF'
              d='M18.606,82.749c-5.5,0-10-4.5-10-10V18.87c0-5.5,4.5-10,10-10h72.286c5.5,0,10,4.5,10,10v53.879
                    c0,5.5-4.5,10-10,10H18.606z'
            />
          </g>
          <g>
            <path
              fill='none'
              stroke='#202B40'
              strokeWidth='2'
              strokeMiterlimit='10'
              d='M21.103,75.761c-3.3,0-6-2.7-6-6V21.858
                    c0-3.3,2.7-6,6-6h67.294c3.3,0,6,2.7,6,6v47.902c0,3.3-2.7,6-6,6H21.103z'
            />
          </g>
          <g>
            <path
              fill='#9DC3ED'
              stroke='#202B40'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeMiterlimit='10'
              d='
                    M17.869,38.55c0,1.928-1.239,3.493-2.767,3.493l0,0c-1.527,0-2.767-1.565-2.767-3.493V30.98c0-1.93,1.239-3.495,2.767-3.495l0,0
                    c1.527,0,2.767,1.565,2.767,3.495V38.55z'
            />
            <path
              fill='#9DC3ED'
              stroke='#202B40'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeMiterlimit='10'
              d='
                    M17.868,60.639c0,1.93-1.239,3.495-2.767,3.495l0,0c-1.527,0-2.766-1.565-2.766-3.495v-7.567c0-1.93,1.238-3.495,2.766-3.495l0,0
                    c1.527,0,2.767,1.565,2.767,3.495V60.639z'
            />
          </g>
          <g>
            <circle
              fill='#9DC3ED'
              stroke='#202B40'
              strokeWidth='2'
              strokeMiterlimit='10'
              cx='48.201'
              cy='45.81'
              r='14.224'
            />
            <line
              fill='#CAE0FF'
              stroke='#202B40'
              strokeWidth='2'
              strokeLinecap='round'
              strokeMiterlimit='10'
              x1='40.949'
              y1='38.559'
              x2='55.451'
              y2='53.061'
            />
            <line
              fill='#CAE0FF'
              stroke='#202B40'
              strokeWidth='2'
              strokeLinecap='round'
              strokeMiterlimit='10'
              x1='55.451'
              y1='38.559'
              x2='40.949'
              y2='53.061'
            />
            <line
              fill='#CAE0FF'
              stroke='#202B40'
              strokeWidth='2'
              strokeLinecap='round'
              strokeMiterlimit='10'
              x1='48.2'
              y1='35.556'
              x2='48.2'
              y2='56.063'
            />
            <line
              fill='#CAE0FF'
              stroke='#202B40'
              strokeWidth='2'
              strokeLinecap='round'
              strokeMiterlimit='10'
              x1='58.455'
              y1='45.81'
              x2='37.947'
              y2='45.81'
            />
            <circle
              fill='#FFFFFF'
              stroke='#202B40'
              strokeWidth='2'
              strokeMiterlimit='10'
              cx='48.201'
              cy='45.81'
              r='4.877'
            />
          </g>
          <rect
            x='81.301'
            y='26.739'
            fill='#9DC3ED'
            stroke='#202B40'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            width='3.493'
            height='38.141'
          />
          <g>
            <line
              fill='#CAE0FF'
              stroke='#FFFFFF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeMiterlimit='10'
              x1='105.418'
              y1='4.003'
              x2='102.241'
              y2='7.18'
            />
            <line
              fill='#CAE0FF'
              stroke='#FFFFFF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeMiterlimit='10'
              x1='98.168'
              y1='1'
              x2='98.168'
              y2='5.346'
            />
            <line
              fill='#CAE0FF'
              stroke='#FFFFFF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeMiterlimit='10'
              x1='108.423'
              y1='11.254'
              x2='104.061'
              y2='11.254'
            />
          </g>
        </g>
      </svg>
    );
  }
}
