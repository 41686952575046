import { color, Spacing } from 'rootstrap/global-styles';
import styled from 'styled-components';
import React from 'react';

export const LoginPageMainContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(${color.rootWhite}, #e0ebff);
`;

export const LoginPageCenterContainer = styled.div`
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (min-width: 481px) {
    width: 70%;
  }

  @media (min-width: 770px) {
    width: 45%;
  }

  @media (min-width: 1000px) {
    width: 33%;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
  }

  .img-container {
    margin-bottom: ${Spacing.md};
  }
`;

export const LoginPageBodyContainer = styled.div`
  background-color: ${color.rootWhite};
  width: 100%;
  box-shadow: 0px 0px 16px rgba(75, 85, 109, 0.15);
  border-radius: 3px;
  padding: ${Spacing.lg};
  padding-bottom: ${Spacing.xl};

  .form-group {
    margin-bottom: 18px !important;
    position: relative !important;

    small.warning {
      position: absolute !important;
      margin-top: 0;
    }
  }

  @media (min-width: 481px) {
    width: calc(100% - 20px);
    margin-left: 10px;
  }
`;

export const LoginPageFooterContainer = styled.div`
  width: 100%;
  margin-top: ${Spacing.lg};
  display: flex;
  font-size: 12px;

  .divider {
    padding: 0px 10px;
    color: #8e97a4;
  }

  a {
    color: #8e97a4;

    :hover {
      color: #3d3d3d;
    }
  }

  @media (orientation: portrait) {
    display: none;
  }

  @media (max-width: 1160px) {
    font-size: 11px;
  }
`;

export const LoginPageFooterContainerLinks = styled.div`
  width: 100%;
  margin-top: ${Spacing.lg};
  display: flex;
  font-size: 12px;
  margin-left: 10px;

  .divider {
    padding: 0px 10px;
    color: #8e97a4;
  }

  a {
    color: #8e97a4;

    :hover {
      color: #3d3d3d;
    }
  }

  @media (orientation: portrait) {
    display: none;
  }

  @media (max-width: 1160px) {
    font-size: 11px;
  }
`;

export const LoginPageFooterLink = (props: { content: string; link: string; hideDivider?: boolean }) => (
  <p style={{ fontWeight: 'normal' }}>
    <a target='_blank' rel='noreferrer' href={props.link}>
      {props.content}
    </a>
    {!props.hideDivider && <span className='divider'>|</span>}
  </p>
);
