export interface BeneficiaryPaymentDetails {
  type: PaymentType;
  details: BankAccount;
}

export enum PaymentType {
  EFT = 'eft',
  CARD = 'card',
}

export interface BankAccount {
  bankName: Bank;
  branchCode: string;
  accountType: AccountType;
  accountNumber: string;
}

export interface DbBankAccount {
  bank_name: Bank;
  branch_code: string;
  account_type: AccountType;
  account_number: string;
}

export enum Bank {
  ABSA = 'absa',
  Access = 'access',
  AfricanBank = 'african_bank',
  AlBaraka = 'al_baraka',
  BankOfLisbon = 'bank_of_lisbon',
  Bidvest = 'bidvest',
  Capitec = 'capitec',
  DiscoveryBank = 'discovery_bank',
  FinbondMutual = 'finbond_mutual',
  FNB = 'fnb',
  HBZ = 'hbz',
  Investec = 'investec',
  Ithala = 'ithala',
  Mercantile = 'mercantile',
  Nedbank = 'nedbank',
  OldMutual = 'old_mutual',
  Postbank = 'postbank',
  Sasfin = 'sasfin',
  StandardBank = 'standard_bank',
  Sure = 'sure',
  TymeBank = 'tyme_bank',
  Ubank = 'ubank',
  Wizzit = 'wizzit',
}

export const BankInformation: { [key: string]: any } = {
  absa: { name: 'ABSA', branch: '632005' },
  access: { name: 'Access', branch: '410506' },
  african_bank: { name: 'African Bank', branch: '430000' },
  al_baraka: { name: 'Al Baraka', branch: '800000' },
  bank_of_lisbon: { name: 'Bank of Lisbon', branch: '450105' },
  bidvest: { name: 'Bidvest', branch: '462005' },
  capitec: { name: 'Capitec', branch: '470010' },
  discovery_bank: { name: 'Discovery Bank', branch: '679000' },
  finbond_mutual: { name: 'Finbond Mutual', branch: '589000' },
  fnb: { name: 'FNB', branch: '250655' },
  hbz: { name: 'HBZ', branch: '570100' },
  investec: { name: 'Investec', branch: '580105' },
  ithala: { name: 'Ithala', branch: '' },
  mercantile: { name: 'Mercantile', branch: '450105' },
  nedbank: { name: 'Nedbank', branch: '198765' },
  old_mutual: { name: 'Old Mutual', branch: '462005' },
  postbank: { name: 'Postbank', branch: '460005' },
  sasfin: { name: 'Sasfin', branch: '683000' },
  standard_bank: { name: 'Standard Bank', branch: '051001' },
  sure: { name: 'Sure', branch: '' },
  tyme_bank: { name: 'Tyme Bank', branch: '678910' },
  ubank: { name: 'Ubank', branch: '431010' },
  wizzit: { name: 'Wizzit', branch: '410700' },
};

export const prettyBankName = (bank: Bank): string => {
  return BankInformation[bank]?.name ?? bank;
};

export enum AccountType {
  Cheque = 'cheque_account',
  Savings = 'savings_account',
}
