import React from 'react';
import { inject, observer } from 'mobx-react';
import { Theme } from 'rootstrap/components/button';
import styled from 'styled-components';
import { AccountStore } from '../insurance/account/stores/account-store';
import { Form, FormValues, FormGroupHalf, Text, PrimaryButton } from '../components';
import { buttonText } from '../insurance/utils';
import { Alert } from '../components/alerts/alerts';

interface ResetPasswordProps {
  closeForgotPassword: () => void;
}

enum PasswordResetStage {
  ResetPassword = 1,
  ResetPasswordComplete = 2,
}

interface ResetPasswordData {
  email: string;
}
interface ResetPasswordState {
  form: ResetPasswordData;
  loading: boolean;
  error?: boolean;
  step: PasswordResetStage;
  valid: boolean;
  touched: boolean;
}

interface Injected extends ResetPasswordProps {
  accountStore: AccountStore;
}

@inject('accountStore')
@observer
export class ResetPassword extends React.Component<ResetPasswordProps, ResetPasswordState> {
  constructor(props: ResetPasswordProps) {
    super(props);

    this.state = {
      form: {
        email: '',
      },
      valid: false,
      touched: false,
      step: PasswordResetStage.ResetPassword,
      loading: false,
    };
  }

  get injected() {
    return this.props as Injected;
  }

  onPasswordReset = async (params: { email: string }) => {
    const { email } = params;
    this.setState({ loading: true });

    const error = await this.injected.accountStore.forgotPassword({ email });

    if (error) {
      return this.setState({
        loading: false,
        step: PasswordResetStage.ResetPassword,
        error: true,
      });
    }

    this.setState({
      loading: false,
      step: PasswordResetStage.ResetPasswordComplete,
    });
  };

  submit = () => {
    const { form, valid } = this.state;
    if (valid) {
      this.onPasswordReset({ email: form.email });
    }
  };

  changed = (valid: boolean, form: FormValues) => {
    const data = {
      email: form.value<string>('email'),
    };

    this.setState({ form: data, valid });
  };

  initialized = (valid: boolean, form: FormValues) => {
    const data = {
      email: form.value<string>('email'),
    };

    this.setState({ form: data, valid });
  };

  render() {
    if (this.state.step === PasswordResetStage.ResetPasswordComplete) {
      return (
        <div>
          <h3>Reset Password</h3>
          <Alert color='success'>Reset password email sent.</Alert>
          <p>
            <b>A link has been emailed to the submitted email address.</b>
          </p>
          <p>Please follow the link in the email reset your password. </p>
          <br />
          <br />
          <PrimaryButton
            outline
            style={{ width: '100%' }}
            theme={Theme.secondary}
            onClick={this.props.closeForgotPassword}
          >
            Back to login
          </PrimaryButton>
        </div>
      );
    }

    return (
      <div>
        <h3>Reset Password</h3>
        <p>
          <b>Enter your email address and we will send you a link to reset your password.</b>
        </p>
        {this.state.error && (
          <Alert color='warning'>
            <span>Something went wrong. Please try again.</span>
          </Alert>
        )}
        <Form
          onSubmit={this.submit}
          onValueChanged={this.changed}
          initialized={this.initialized}
          touched={this.state.touched}
        >
          <StyledResetEmailFormGroupHalf>
            <Text type='email' name='email' label='Email' defaultValue='' required />
          </StyledResetEmailFormGroupHalf>
          <PrimaryButton
            type='submit'
            disabled={this.state.loading || !this.state.valid}
            style={{ width: '100%', height: 48, marginBottom: 15 }}
            id='resetPasswordButton'
            className='btn btn-primary'
          >
            {buttonText({
              loading: this.state.loading,
              text: 'Reset password',
            })}
          </PrimaryButton>
          <PrimaryButton
            outline
            style={{ width: '100%', height: 48, marginLeft: 0, marginRight: 0 }}
            theme={Theme.secondary}
            onClick={this.props.closeForgotPassword}
          >
            Back to login
          </PrimaryButton>
        </Form>
      </div>
    );
  }
}

const StyledResetEmailFormGroupHalf = styled(FormGroupHalf)`
  margin-bottom: 10px;
`;
