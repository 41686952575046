export const color = {
  // Primary colors
  rootBlue: '#2969ff',
  rootWhite: '#ffffff',
  rootBlueHover: 'rgba(41, 105, 255, 0.08)',
  spaceBlue: '#4078fa',
  activeBlue: '#eef3ff',
  rootDarkBlue: 'rgba(46, 60, 116, 0.89)',
  rootGray: '#2d2d2d',
  lightBlue: '#A5C0FF',

  // Interface colors
  success: '#1fc881',
  warning: '#ffab00',
  error: '#ff2b38',
  border: '#dedede',
  neutral: '#e9e9e9',
  danger: '#fd3238',

  // Emphasis
  emHigh: 'rgba(0, 0, 0, 0.87)',
  emMed: 'rgba(0, 0, 0, 0.6)',
  inactive: 'rgba(0, 0, 0, 0.54)',
  emDisabled: 'rgba(0, 0, 0, 0.38)',
  emptySection: '#F8FAFC',
};
