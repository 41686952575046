import styled, { css } from 'styled-components';
import { ModalHeader as ReactstrapModalHeader } from 'reactstrap';
import { Spacing } from '../../rootstrap/global-styles';

export const ModalHeader = styled(ReactstrapModalHeader)<{ centerHeader: boolean }>`
  ${({ centerHeader }) =>
    centerHeader &&
    css`
       {
        margin: auto;
        padding-right: ${Spacing.md};
      }
    `}
`;
