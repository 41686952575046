import React from 'react';
import { Event, DisplayEvent } from '../event';
import { ClaimEventType } from './claim-event-type';
import { CreatedByType } from '../../../created-by';
import { Claim } from '../../../../claims/domain/claim';

export class ClaimNoClaimEvent extends Event<any> {
  // dpo not leave like this
  public static New(reason: string, claim: Claim) {
    return new ClaimNoClaimEvent(
      {
        type: ClaimEventType.ClaimNoClaim,
        reason,
        requestedBy: {
          type: CreatedByType.System, // TODO
        },
      } as any,
      claim,
    );
  }
}

export class ClaimNoClaimDisplayEvent extends DisplayEvent {
  getTitle = () => 'Claim marked as no-claim';
  renderFull = () => (
    <div>
      Decision summary: <br /> {(this.props.event as any).reason}
    </div>
  );
}
