import momentTimezone, { Moment } from 'moment-timezone';
import { Construct } from '../../models/construct';

export enum IdentificationType {
  Id = 'id',
  Passport = 'passport',
  Custom = 'custom',
  Email = 'email',
  Cellphone = 'cellphone',
}

export interface Identification {
  type: IdentificationType;
  number: string;
  country: string;
  expirationDate?: Moment;
}

export class PolicyholderIdentification {
  public type: IdentificationType;
  public number: string;
  public country: string;
  public expirationDate?: Moment;

  constructor(init: Construct<PolicyholderIdentification>) {
    Object.assign(this, init);
  }

  static fromNetwork(init: any): PolicyholderIdentification {
    return new PolicyholderIdentification({
      type: init.type,
      number: init.number,
      country: init.country,
      expirationDate: init.expiration_date ? momentTimezone(init.expiration_date) : undefined,
    });
  }
}

export class NetworkPolicyholderIdentification {
  type: IdentificationType;
  number: string;
  country: string;
  expiration_date?: string;
}
