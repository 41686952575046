export enum PolicyEventType {
  PolicyImported = 'policy_imported',
  PolicyCancelled = 'policy_cancelled',
  PolicyReceiptCreated = 'policy_receipt_created',
  PolicyIssued = 'policy_issued',
  PolicyLapsed = 'policy_lapsed',
  PolicyExpired = 'policy_expired',
  PolicyNotTakenUp = 'policy_not_taken_up',
  PolicyAltered = 'policy_altered',
  PolicyLinked = 'policy_linked',
  PolicyScheduleUpdated = 'policy_schedule_updated',
  PolicyTermsUpdated = 'policy_terms_updated',
  PolicyEmailSent = 'policy_email_sent',
  PolicyRefundRequested = 'policy_refund_requested',
  PolicyActivated = 'policy_activated',
  PolicyRequoted = 'policy_requoted',
  PolicyAlterationPackageApplied = 'policy_alteration_package_applied',

  BeneficiaryUpdated = 'beneficiary_updated',

  MemberCreated = 'member_created',
  MemberUpdated = 'member_updated',
  MemberArchived = 'member_archived',

  BillingFailure = 'billing_failure',
  PaymentSuccessful = 'payment_successful',
  PaymentFailed = 'payment_failed',

  ClaimLinked = 'claim_linked',

  ComplaintLinked = 'complaint_linked',
  MonitorRequest = 'monitor_request',

  PaymentMethodRemoved = 'payment_method_removed',
}
