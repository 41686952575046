import React from 'react';

export class FlexContainer extends React.Component<any, any> {
  render() {
    return <div className={`flex-container${this.props.padded ? ' padded' : ''}`}>{this.props.children}</div>;
  }
}

export class FlexContainerFlex extends React.Component {
  render() {
    return <div className='flex-container flex'>{this.props.children}</div>;
  }
}

export class FlexContainerNoOverflow extends React.Component {
  render() {
    return <div className='flex-container no-overflow flex'>{this.props.children}</div>;
  }
}
