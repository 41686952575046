import { NetworkEvent } from '../network-event';
import { Event } from '../event';
import { ApplicationCreatedDisplayEvent, ApplicationCreatedEvent } from './application-completed';
import { ApplicationEventType } from './application-event-type';
import { Application } from '../../../../applications/domain/application';
import { ApplicationUpdatedDisplayEvent, ApplicationUpdatedEvent } from './aplication-updated';

export class ApplicationEventFactory {
  public static fromNetwork(event: NetworkEvent, entity: Application) {
    switch (event.type) {
      case ApplicationEventType.ApplicationCreated:
        return new ApplicationCreatedEvent(event as any, entity);
      case ApplicationEventType.ApplicationUpdated:
        return new ApplicationUpdatedEvent(event as any, entity);
      case ApplicationEventType.ApplicationBeneficiariesUpdated:
      default:
        return null;
    }
  }

  public static toDisplayEvent(event: Event<any>) {
    switch (event.type) {
      case ApplicationEventType.ApplicationCreated:
        return ApplicationCreatedDisplayEvent;
      case ApplicationEventType.ApplicationUpdated:
        return ApplicationUpdatedDisplayEvent;
      case ApplicationEventType.ApplicationBeneficiariesUpdated:
      default:
        return null;
    }
  }
}
