import React from 'react';
import {
  Modal as ReactstrapModal,
  ModalProps as ReactstrapModalProps,
  ModalHeaderProps as ReactstrapModalHeaderProps,
} from 'reactstrap';
import { ModalHeader as RootStrapModalHeader } from './styles';
import { Svg } from '../svg';

export interface ModalProps extends ReactstrapModalProps {
  animated?: boolean;
  danger?: boolean;
  disableCloseIcon?: boolean;
}

export const Modal = (props: ModalProps) => {
  const unfrozenProps = { ...props };
  const { animated } = unfrozenProps;
  if (animated !== undefined) {
    delete unfrozenProps.animated;
  }
  const { danger } = unfrozenProps;
  if (danger !== undefined) {
    delete unfrozenProps.danger;
  }

  return (
    <ReactstrapModal
      {...unfrozenProps}
      className={(animated ? 'animated ' : '') + (danger ? 'danger-animation ' : '') + (unfrozenProps.className || '')}
    />
  );
};

export type ModalHeaderProps = ReactstrapModalHeaderProps;

export const ModalHeader = (props: ModalHeaderProps) => {
  const unfrozenProps = { ...props };
  const { children } = unfrozenProps;
  const { toggle } = unfrozenProps;
  unfrozenProps.centerHeader = unfrozenProps.disableCloseIcon;
  delete unfrozenProps.children;
  delete unfrozenProps.toggle;

  return (
    <RootStrapModalHeader {...unfrozenProps}>
      {children}
      {!unfrozenProps.disableCloseIcon && <Svg onClick={toggle} href='/assets/images/icons/icon-24-px-cross.svg' />}
    </RootStrapModalHeader>
  );
};

export { ModalBody, ModalFooter } from 'reactstrap';
