import { Moment } from 'moment-timezone';
import { FormValuesWithKeys } from './form-keyed';
import { ChecksumAlgorithm } from './validation/checksum';

export enum MultiInputType {
  Text = 'text',
  Number = 'number',
  Email = 'email',
  Select = 'select',
  Switch = 'switch',
  Checkbox = 'checkbox',
  Date = 'date',
  Multi = 'multi',
  Radio = 'radio',
  SAID = 'id',
}

export interface FormMultiInput {
  /** All inputs */
  label: string;
  type: MultiInputType;
  required?: boolean;
  requiredIf?: string;
  defaultValue?: string | number | Moment | FormValuesWithKeys[] | boolean;
  inputStep: MultiInputStep;
  hideInSummary?: boolean;

  /** Text inputs */
  minLength?: number;
  maxLength?: number;
  pattern?: string;

  /** Number inputs */
  min?: number;
  max?: number;
  step?: number;
  currency?: boolean;
  checksum?: ChecksumAlgorithm;

  /** Select inputs */
  options?: { label: string; value: string }[];

  /** Multi inputs */
  schema?: MultiInput[];
  requireAtLeast?: number;
  requireAtMost?: number;
  preventAddOrUpdate?: boolean;
  addAnotherLabel?: string;

  /** Date inputs */
  mustEqualAge?: string;
  isPolicyholderAge?: boolean;
  isPolicyholderGender?: boolean;
  onlyAllowEditing?: string[];
}

export interface MultiInput extends FormMultiInput {
  name: string;
}

export enum MultiInputStep {
  All = 'all',
  Quote = 'quote',
  Application = 'application',
  ClaimDescription = 'claim_description',
}
