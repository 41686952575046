import React from 'react';
import { Event, DisplayEvent, DisplayEventProps } from '../event';

export class PolicyMonitorRequestEvent extends Event<any> {
  action: string;
  response: any;

  constructor(init: any, entity: any) {
    super(init, entity);
    Object.assign(this, init);
  }
}

type PolicyMonitorRequestEventProps = DisplayEventProps<PolicyMonitorRequestEvent>;

export class PolicyMonitorRequestDisplayEvent extends DisplayEvent<PolicyMonitorRequestEventProps, unknown> {
  constructor(props: PolicyMonitorRequestEventProps) {
    super(props);
  }
  getTitle = () => 'Monitor Request';
  renderFull = () => {
    const { action, response } = this.props.event;
    return (
      <>
        <b>Action:</b> {action}
        <br />
        <b>Response:</b> {typeof response === 'string' ? response : JSON.stringify(response)}
      </>
    );
  };
}
