import { Event, DisplayEvent } from '../event';
import { ComplaintEventType } from './complaint-event-type';
import { CreatedByType } from '../../../created-by';
import { Complaint } from '../../../../complaints/domain/complaint';

export class ComplaintReopenedEvent extends Event<any> {
  public static New(reason: string, complaint: Complaint) {
    return new ComplaintReopenedEvent(
      {
        type: ComplaintEventType.ComplaintReopened,
        reason,
        requestedBy: {
          type: CreatedByType.System, // TODO
        },
      } as any,
      complaint,
    );
  }
}

export class ComplaintReopenedDisplayEvent extends DisplayEvent {
  getTitle = () => 'Complaint reopened';
  renderFull = () => {
    return `Reason: ${(this.props.event as any).reason}`;
  };
}
