import React, { MouseEvent } from 'react';
import { FormGroup } from 'reactstrap';
import { FormElement, FormElementProps, FormElementState } from './form-element';
import { Switch } from './form-element-switch';
import { Checkbox } from './form-element-checkbox';
import { Select } from './form-element-select';
import { Date, DatePickerProps } from './form-element-date';
import { Text } from './form-element-text';
import { PrimaryButton, LinkButton } from '../buttons';
import { Validator, ValidatorResult } from './validation';
import * as Validation from './validation';
import { FormValuesWithKeys, FormKeyed } from './form-keyed';
import { MultiInputType, MultiInput } from './form-multi-input';
import { Radio } from './form-element-radio';
import { FormValues } from './form';

export interface MultiProps extends FormElementProps<FormValuesWithKeys[]> {
  schema: MultiInput[];
  requireAtLeast?: number;
  requireAtMost?: number;
  preventAddOrUpdate?: boolean;
  referenceValues: FormValuesWithKeys;
  addAnotherLabel?: string;
}

export class Multi extends FormElement<FormValuesWithKeys[], MultiProps, FormElementState<FormValuesWithKeys[]>> {
  onChange = (index: number, valid: boolean, newValue: FormValues) => {
    const n = [...this.state.value];
    n[index] = newValue as FormValuesWithKeys;

    this.valueChanged(n);
  };

  protected isValid = (values: FormValuesWithKeys[], props: MultiProps): ValidatorResult => {
    for (const value of values) {
      for (const element of props.schema) {
        if (!value.valid(element.name)) {
          return { valid: false };
        }
      }
    }

    return { valid: true };
  };

  render() {
    return (
      <FormGroup key={this.props.name} className={this.props.className}>
        {this._render()}
      </FormGroup>
    );
  }

  protected _render() {
    const values = this.state.value;
    return (
      <>
        {values.map((val, index) => {
          return (
            <div key={val.id} id={val.id}>
              {!this.props.hidden && (
                <div style={{ position: 'relative' }}>
                  <h5>
                    {this.props.label} {this.props.requireAtMost !== 1 ? index + 1 : ''}
                  </h5>
                  {index !== 0 && !this.props.preventAddOrUpdate && (
                    <LinkButton style={{ position: 'absolute', right: 0, top: 8 }} onClick={() => this.remove(index)}>
                      remove
                    </LinkButton>
                  )}
                </div>
              )}
              <FormKeyed
                values={val}
                initialized={(valid: boolean, v: FormValues) => this.onChange(index, valid, v)}
                onValueChanged={(valid: boolean, v: FormValues) => this.onChange(index, valid, v)}
                touched={this.props.touched}
                skipFormTag
                className={`no-margin${this.props.hidden ? ' hidden' : ''}`}
              >
                {this.props.schema.map((x, i) =>
                  this.mapToFormElement(val, x, i === 0 && (this.props.autoFocus || false)),
                )}
              </FormKeyed>
            </div>
          );
        })}
        {!this.props.hidden && values.length !== this.props.requireAtMost && !this.props.preventAddOrUpdate && (
          <div>
            <PrimaryButton
              onClick={(e: MouseEvent) => {
                e.preventDefault();
                this.addNew();
              }}
            >
              Add another {(this.props.addAnotherLabel || this.props.label).toLocaleLowerCase()}
            </PrimaryButton>
          </div>
        )}
      </>
    );
  }

  protected getValueFromProps(props: MultiProps) {
    return this.getValue(props.defaultValue as FormValuesWithKeys[]);
  }

  private getValue(value: FormValuesWithKeys[]) {
    return value;
  }

  addNew = () => {
    this.valueChanged([...this.state.value, this.state.value[0].clone().reset()]);
  };

  remove = (index: number) => {
    this.state.value.splice(index, 1);
    this.valueChanged([...this.state.value]);
  };

  mapToFormElement(value: FormValuesWithKeys, input: MultiInput, autoFocus: boolean) {
    const defaultValue = value.value(input.name);
    const key = value.key(input.name);

    if (
      input.type === MultiInputType.Text ||
      input.type === MultiInputType.Number ||
      input.type === MultiInputType.SAID ||
      input.type === MultiInputType.Email
    ) {
      return (
        <Text
          key={key}
          id={key}
          name={input.name}
          label={input.label}
          type={input.type}
          required={input.required}
          min={input.min}
          max={input.max}
          step={input.step}
          minLength={input.minLength}
          maxLength={input.maxLength}
          defaultValue={defaultValue as any}
          autoFocus={autoFocus}
        />
      );
    }

    if (input.type === MultiInputType.Date) {
      const otherProps: Partial<DatePickerProps> = {};
      if (input.mustEqualAge) {
        otherProps.mustEqualAge = value.value(input.mustEqualAge);
      }

      return (
        <Date
          key={key}
          id={key}
          name={input.name}
          label={input.label}
          required={input.required}
          {...otherProps}
          defaultValue={defaultValue as any}
        />
      );
    }

    if (input.type === MultiInputType.Switch) {
      return (
        <Switch
          key={key}
          id={key}
          name={input.name}
          label={input.label}
          required={input.required}
          defaultValue={defaultValue as any}
        />
      );
    }

    if (input.type === MultiInputType.Checkbox) {
      return (
        <Checkbox
          key={key}
          id={key}
          name={input.name}
          label={input.label}
          required={input.required}
          defaultValue={defaultValue as any}
          inline
        />
      );
    }

    if (input.type === MultiInputType.Radio && input.options) {
      return (
        <Radio
          options={input.options}
          autoFocus={autoFocus}
          key={key}
          id={key}
          name={input.name}
          label={input.label}
          required={input.required}
          defaultValue={defaultValue as any}
          inline
        />
      );
    }

    return (
      <Select
        key={key}
        id={key}
        name={input.name}
        label={input.label}
        required={input.required}
        options={input.options}
        defaultValue={defaultValue as any}
        autoFocus={autoFocus}
      />
    );
  }

  public getSpecificValidators(props: MultiProps): Validator<FormValuesWithKeys[]>[] {
    return [new Validation.MultiValuesValidator(props.label)];
  }
}
