import React from 'react';
import { Badge as ReactstrapBadge, BadgeProps } from 'reactstrap';

export interface CustomBadgeProps extends BadgeProps {
  primary?: boolean;
  success?: boolean;
  danger?: boolean;
  warning?: boolean;
  info?: boolean;
  purple?: boolean;
  black?: boolean;
  blue?: boolean;
  yellow?: boolean;
}

export const Badge = (props: CustomBadgeProps) => (
  <ReactstrapBadge className={props.className} style={props.style} color={colorPromProps(props)}>
    {props.children}
  </ReactstrapBadge>
);

export function colorPromProps(props: CustomBadgeProps) {
  if (props.primary) {
    return 'primary';
  }

  if (props.success) {
    return 'success';
  }

  if (props.danger) {
    return 'danger';
  }

  if (props.warning) {
    return 'warning';
  }

  if (props.info) {
    return 'info';
  }

  if (props.purple) {
    return 'purple';
  }

  if (props.black) {
    return 'black';
  }

  if (props.blue) {
    return 'blue';
  }

  if (props.yellow) {
    return 'yellow';
  }

  return 'secondary';
}

export const TableBadge = (props: CustomBadgeProps) => (
  <span onClick={props.onClick} className={`table-badge ${colorPromProps(props)}`}>
    {props.children}
  </span>
);
