import React from 'react';

export class MainLayout extends React.Component {
  render() {
    return <div className='main-layout'>{this.props.children}</div>;
  }
}

export class MainLayoutContent extends React.Component {
  render() {
    return <div className='main-layout-content'>{this.props.children}</div>;
  }
}
