import { ClaimEventType } from './claim-event-type';
import { NetworkEvent } from '../network-event';
import { Event } from '../event';

import {
  ClaimApprovalAcknowledgedEvent,
  ClaimApprovalAcknowledgedDisplayEvent,
} from './claim-approval-acknowledged-event';
import {
  ClaimApprovalNotAcknowledgedEvent,
  ClaimApprovalNotAcknowledgedDisplayEvent,
} from './claim-approval-not-acknowledged-event';
import { ClaimApprovedEvent, ClaimApprovedDisplayEvent } from './claim-approved-event';
import { ClaimCauseEvent, ClaimCauseDisplayEvent } from './claim-cause-event';
import { ClaimClosedEvent, ClaimClosedDisplayEvent } from './claim-closed-event';
import {
  ClaimGoodwillAcknowledgedEvent,
  ClaimGoodwillAcknowledgedDisplayEvent,
} from './claim-goodwill-acknowledged-event';
import { ClaimGoodwillEvent, ClaimGoodwillDisplayEvent } from './claim-goodwill-event';
import {
  ClaimGoodwillNotAcknowledgedEvent,
  ClaimGoodwillNotAcknowledgedDisplayEvent,
} from './claim-goodwill-not-acknowledged-event';
import { ClaimFinalizedEvent, ClaimFinalizedDisplayEvent } from './claim-finalized-event';
import {
  ClaimNoClaimAcknowledgedEvent,
  ClaimNoClaimAcknowledgedDisplayEvent,
} from './claim-no-claim-acknowledged-event';
import { ClaimNoClaimEvent, ClaimNoClaimDisplayEvent } from './claim-no-claim-event';
import {
  ClaimNoClaimNotAcknowledgedEvent,
  ClaimNoClaimNotAcknowledgedDisplayEvent,
} from './claim-no-claim-not-acknowledged-event';
import { ClaimOpenedEvent, ClaimOpenedDisplayEvent } from './claim-opened-event';
import { ClaimPayoutPaidEvent, ClaimPayoutPaidDisplayEvent } from './claim-payout-paid-event';
import { ClaimPayoutRequestedEvent, ClaimPayoutRequestedDisplayEvent } from './claim-payout-requested-event';
import { ClaimReopenedEvent, ClaimReopenedDisplayEvent } from './claim-reopened-event';
import { ClaimRepudiatedEvent, ClaimRepudiatedDisplayEvent } from './claim-repudiated-event';
import {
  ClaimRepudiationAcknowledgedEvent,
  ClaimRepudiationAcknowledgedDisplayEvent,
} from './claim-repudiation-acknowledged-event';
import {
  ClaimRepudiationNotAcknowledgedEvent,
  ClaimRepudiationNotAcknowledgedDisplayEvent,
} from './claim-repudiation-not-acknowledged-event';
import { ClaimTypeEvent, ClaimTypeDisplayEvent } from './claim-type-event';
import { ClaimUpdatedEvent, ClaimUpdatedDisplayEvent } from './claim-updated-event';
import { ClaimantUpdatedEvent, ClaimantUpdatedDisplayEvent } from './claimant-updated-event';
import { ClaimSentToReviewEvent, ClaimSentToReviewDisplayEvent } from './claim-sent-to-review-event';
import { ClaimImportedEvent, ClaimImportedDisplayEvent } from './claim-imported-event';
import { Claim } from '../../../../claims/domain/claim';

export class ClaimEventFactory {
  public static fromNetwork(event: NetworkEvent, claim: Claim) {
    switch (event.type) {
      case ClaimEventType.ClaimImported:
        return new ClaimImportedEvent(event as any, claim);
      case ClaimEventType.ClaimOpened:
        return new ClaimOpenedEvent(event as any, claim);
      case ClaimEventType.ClaimUpdated:
        return new ClaimUpdatedEvent(event as any, claim);
      case ClaimEventType.ClaimFinalized:
        return new ClaimFinalizedEvent(event as any, claim);
      case ClaimEventType.ClaimClosed:
        return new ClaimClosedEvent(event as any, claim);
      case ClaimEventType.ClaimReopened:
        return new ClaimReopenedEvent(event as any, claim);

      case ClaimEventType.ClaimCause:
        return new ClaimCauseEvent(event as any, claim);
      case ClaimEventType.ClaimType:
        return new ClaimTypeEvent(event as any, claim);
      case ClaimEventType.ClaimantUpdated:
        return new ClaimantUpdatedEvent(event as any, claim);

      case ClaimEventType.ClaimApproved:
        return new ClaimApprovedEvent(event as any, claim);
      case ClaimEventType.ClaimApprovalAcknowledged:
        return new ClaimApprovalAcknowledgedEvent(event as any, claim);
      case ClaimEventType.ClaimApprovalNotAcknowledged:
        return new ClaimApprovalNotAcknowledgedEvent(event as any, claim);

      case ClaimEventType.ClaimNoClaim:
        return new ClaimNoClaimEvent(event as any, claim);
      case ClaimEventType.ClaimNoClaimAcknowledged:
        return new ClaimNoClaimAcknowledgedEvent(event as any, claim);
      case ClaimEventType.ClaimNoClaimNotAcknowledged:
        return new ClaimNoClaimNotAcknowledgedEvent(event as any, claim);

      case ClaimEventType.ClaimRepudiated:
        return new ClaimRepudiatedEvent(event as any, claim);
      case ClaimEventType.ClaimRepudiationAcknowledged:
        return new ClaimRepudiationAcknowledgedEvent(event as any, claim);
      case ClaimEventType.ClaimRepudiationNotAcknowledged:
        return new ClaimRepudiationNotAcknowledgedEvent(event as any, claim);

      case ClaimEventType.ClaimGoodwill:
        return new ClaimGoodwillEvent(event as any, claim);
      case ClaimEventType.ClaimGoodwillAcknowledged:
        return new ClaimGoodwillAcknowledgedEvent(event as any, claim);
      case ClaimEventType.ClaimGoodwillNotAcknowledged:
        return new ClaimGoodwillNotAcknowledgedEvent(event as any, claim);

      case ClaimEventType.ClaimPayoutRequested:
        return new ClaimPayoutRequestedEvent(event as any, claim);
      case ClaimEventType.ClaimPayoutPaid:
        return new ClaimPayoutPaidEvent(event as any, claim);

      case ClaimEventType.ClaimSentToReview:
        return new ClaimSentToReviewEvent(event as any, claim);
      default:
        return null;
    }
  }

  public static toDisplayEvent(event: Event<any>) {
    switch (event.type) {
      case ClaimEventType.ClaimImported:
        return ClaimImportedDisplayEvent;
      case ClaimEventType.ClaimOpened:
        return ClaimOpenedDisplayEvent;
      case ClaimEventType.ClaimUpdated:
        return ClaimUpdatedDisplayEvent;
      case ClaimEventType.ClaimFinalized:
        return ClaimFinalizedDisplayEvent;
      case ClaimEventType.ClaimClosed:
        return ClaimClosedDisplayEvent;
      case ClaimEventType.ClaimReopened:
        return ClaimReopenedDisplayEvent;

      case ClaimEventType.ClaimCause:
        return ClaimCauseDisplayEvent;
      case ClaimEventType.ClaimType:
        return ClaimTypeDisplayEvent;
      case ClaimEventType.ClaimantUpdated:
        return ClaimantUpdatedDisplayEvent;

      case ClaimEventType.ClaimApproved:
        return ClaimApprovedDisplayEvent;
      case ClaimEventType.ClaimApprovalAcknowledged:
        return ClaimApprovalAcknowledgedDisplayEvent;
      case ClaimEventType.ClaimApprovalNotAcknowledged:
        return ClaimApprovalNotAcknowledgedDisplayEvent;

      case ClaimEventType.ClaimNoClaim:
        return ClaimNoClaimDisplayEvent;
      case ClaimEventType.ClaimNoClaimAcknowledged:
        return ClaimNoClaimAcknowledgedDisplayEvent;
      case ClaimEventType.ClaimNoClaimNotAcknowledged:
        return ClaimNoClaimNotAcknowledgedDisplayEvent;

      case ClaimEventType.ClaimRepudiated:
        return ClaimRepudiatedDisplayEvent;
      case ClaimEventType.ClaimRepudiationAcknowledged:
        return ClaimRepudiationAcknowledgedDisplayEvent;
      case ClaimEventType.ClaimRepudiationNotAcknowledged:
        return ClaimRepudiationNotAcknowledgedDisplayEvent;

      case ClaimEventType.ClaimGoodwill:
        return ClaimGoodwillDisplayEvent;
      case ClaimEventType.ClaimGoodwillAcknowledged:
        return ClaimGoodwillAcknowledgedDisplayEvent;
      case ClaimEventType.ClaimGoodwillNotAcknowledged:
        return ClaimGoodwillNotAcknowledgedDisplayEvent;

      case ClaimEventType.ClaimPayoutRequested:
        return ClaimPayoutRequestedDisplayEvent;
      case ClaimEventType.ClaimPayoutPaid:
        return ClaimPayoutPaidDisplayEvent;

      case ClaimEventType.ClaimSentToReview:
        return ClaimSentToReviewDisplayEvent;

      default:
        return null;
    }
  }
}
