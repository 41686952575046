import React from 'react';
import { Input as ReactstrapInput } from 'reactstrap';
import { getCurrencySymbol } from 'insurance/shared/utils';
import { FormElement, FormElementProps, FormElementState } from './form-element';
import { Validator } from './validation';
import * as Validation from './validation';
import { ChecksumAlgorithm } from './validation/checksum';

export type InputValueType = string | number | undefined;

export interface TextProps extends FormElementProps<InputValueType> {
  type: string;
  minLength?: number;
  maxLength?: number;
  min?: number;
  max?: number;
  isCurrency?: boolean;
  currency?: string;
  pattern?: string;
  checkSum?: ChecksumAlgorithm;
  mustEqualAge?: number;
  mustEqualGender?: string;
  value?: any;
  id?: string;
  isValid?: boolean;
}

interface State extends FormElementState<InputValueType> {
  insertPoint: boolean;
}

export const Input = ReactstrapInput;

export class Text<P extends TextProps = TextProps> extends FormElement<InputValueType, P, State> {
  private id: string;

  constructor(props: P) {
    super(props);

    if (!['text', 'textarea', 'password', 'number', 'email', 'id', 'creditcard', 'textOnly'].includes(props.type)) {
      throw new Error(`Input type for ${props.label} is not supported.`);
    }

    this.id = props.id || `input${Math.round(Math.random() * 100000)}`;

    this.state = {
      ...this.state,
      insertPoint: false,
    };
  }

  componentDidUpdate(oldProps: P, oldState: State) {
    super.componentDidUpdate(oldProps, oldState);

    if (this.props.autoFocus && !this.state.touched) {
      (document.getElementById(this.id) as HTMLInputElement).focus();
    }

    if (oldProps.type !== this.props.type) {
      setTimeout(() => {
        this.valueChanged(this.state.value, this.state.touched);
      });
    }

    if (this.props.value && this.props.value !== this.state.value) {
      this.valueChanged(this.props.value, this.state.touched);
    }
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = this.getValueFromType(event.target.value, this.props.type);
    this.valueChanged(value);
  };

  protected _render() {
    const currencySymbol = getCurrencySymbol(this.props.currency || 'ZAR');
    if (this.props.isCurrency) {
      return (
        <div className='input-group'>
          <div className='input-group-prepend'>
            <span className='input-group-text'>{currencySymbol}</span>
          </div>
          {this._renderInput()}
        </div>
      );
    }

    return this._renderInput();
  }

  get val() {
    if (this.props.type === 'number') {
      return isNaN(Number(this.state.value)) ? '' : this.state.value;
    }
    return this.state.value || '';
  }

  protected _renderInput() {
    let { val } = this;
    if (this.props.isCurrency) {
      const currencySymbol = getCurrencySymbol(this.props.currency || 'ZAR');

      val = (val as number)
        .toLocaleString('en-ZA', {
          style: 'currency',
          currency: this.props.currency,
          currencyDisplay: 'narrowSymbol',
        })
        .replace(currencySymbol, '')
        .replace(',00', '')
        .trim();
      if (this.state.insertPoint) {
        val += ',';
      }

      val = val.replace(/,([0-9]{1})0/gi, `,${val[val.length - 2]}`);
    }

    if (this.props.type === 'creditcard' && typeof val === 'string') {
      val = val.replace(/\s/g, '');

      let newVal = '';
      for (let i = 0; i < val.length; i++) {
        newVal += val[i];

        if ((i + 1) % 4 === 0) {
          newVal += ' ';
        }
      }

      val = newVal;
      if (val.length === 20) {
        val = val.trim();
      }
    }

    const disabled = !!(
      this.props.disabled &&
      this.props.defaultValue !== null &&
      this.props.defaultValue !== undefined &&
      !this.state.changed
    );

    return (
      <ReactstrapInput
        id={this.id}
        valid={(this.state.touched && this.state.valid) || this.props.isValid === true}
        invalid={(this.state.touched && !this.state.valid) || this.props.isValid === false}
        name={this.props.name}
        type={
          this.props.isCurrency
            ? 'text'
            : this.props.type === 'id' || this.props.type === 'creditcard'
            ? 'text'
            : (this.props.type as any)
        }
        placeholder={this.props.placeholder}
        value={val}
        required={this.props.required}
        readOnly={this.props.readonly}
        disabled={disabled}
        autoFocus={this.props.autoFocus}
        autoComplete={this.props.autocomplete}
        min={this.props.min}
        max={this.props.max}
        minLength={this.props.minLength}
        maxLength={this.props.type === 'creditcard' ? 19 : this.props.maxLength}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onFocus={this.onFocus}
      />
    );
  }

  protected getValueFromProps(props: TextProps): InputValueType {
    return this.getValueFromType(props.defaultValue, props.type);
  }

  public getSpecificValidators(props: TextProps): Validator<InputValueType>[] {
    const validators = [];
    const { type } = props;

    if (type === 'text' || type === 'textarea' || type === 'password') {
      if (props.minLength !== undefined) {
        validators.push(new Validation.MinLengthValidator(props.label, props.minLength));
      }

      if (props.maxLength !== undefined) {
        validators.push(new Validation.MaxLengthValidator(props.label, props.maxLength));
      }

      if (props.pattern !== undefined) {
        validators.push(new Validation.PatternValidator(props.label, props.pattern));
      }

      if (props.checkSum !== undefined) {
        validators.push(new Validation.ChecksumValidator(props.label, props.checkSum));
      }
    } else if (type === 'number') {
      if (props.min !== undefined) {
        validators.push(
          new Validation.MinValidator(
            props.label,
            typeof props.min === 'string' ? parseFloat(props.min) : props.min,
            !!this.props.isCurrency,
            this.props.currency as string, // IDK why ts is complaining here!
          ),
        );
      }

      if (props.max !== undefined) {
        validators.push(
          new Validation.MaxValidator(
            props.label,
            typeof props.max === 'string' ? parseFloat(props.max) : props.max,
            !!this.props.isCurrency,
            this.props.currency as string, // IDK why ts is complaining here!
          ),
        );
      }

      if (props.checkSum !== undefined) {
        validators.push(new Validation.ChecksumValidator(props.label, props.checkSum));
      }
    } else if (type === 'email') {
      validators.push(new Validation.EmailValidator(props.label));
    } else if (type === 'id') {
      validators.push(new Validation.IdValidator(props.label));

      if (props.mustEqualAge) {
        validators.push(new Validation.IdAgeValidator(props.label, props.mustEqualAge));
      }

      if (props.mustEqualGender) {
        validators.push(new Validation.IdGenderValidator(props.label, props.mustEqualGender));
      }
    } else if (type === 'creditcard') {
      validators.push(new Validation.CreditCardValidator(props.label));
    }

    return validators;
  }

  public getValueFromType(value: InputValueType, type: string) {
    if (type === 'number' && typeof value === 'string') {
      if (this.props.isCurrency) {
        if (value.endsWith('.') || value.endsWith(',')) {
          this.setState({ insertPoint: true });
        } else {
          this.setState({ insertPoint: false });
        }
      }

      value = parseFloat(value.replace(/\s+/gi, '').replace(/,/gi, '.'));
      if (isNaN(value)) {
        value = undefined;
      }
    } else if (type === 'textOnly' && typeof value === 'string') {
      value = value.replace(/[0-9]/g, '');
    } else if (value === '') {
      value = undefined;
    }

    return value;
  }
}

interface CurrencySymbolsType {
  [key: string]: string;
}

export const CurrencySymbols: CurrencySymbolsType = {
  ZAR: 'R',
  USD: 'US$',
  GBP: '£',
  EUR: '€',
  KRW: '₩',
  JPY: '‎¥‎',
  CNY: '¥',
  INR: '₹',
  MUR: 'Rs',
};
