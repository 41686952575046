import { GeneralEventType } from './general-event-type';
import { NetworkEvent } from '../network-event';
import { Event } from '../event';
import { NotificationCreatedEvent, NotificationCreatedDisplayEvent } from './notification-created-event';

export class GeneralEventFactory {
  public static fromNetwork(event: NetworkEvent, entity: any) {
    switch (event.type) {
      case GeneralEventType.NotificationCreated:
        return new NotificationCreatedEvent(event as any, entity);
      default:
        return null;
    }
  }

  public static toDisplayEvent(event: Event<any>) {
    switch (event.type) {
      case GeneralEventType.NotificationCreated:
        return NotificationCreatedDisplayEvent;
      default:
        return null;
    }
  }
}
