import { RequestedBy } from 'insurance/models';
import momentTimezone, { Moment } from 'moment-timezone';
import { ProductModuleDefinitionSettings } from './product-module-definition-settings';
import { Construct } from '../../models/construct';
import { ProductModuleDefinitionEmbeddedConfig } from './product-module-embedded-config';

export interface ProductModuleDefinitionFilters {
  hasBeenPublished?: boolean;
  search?: string;
}

export enum VersionType {
  Draft = 'Latest draft',
  Published = 'Published',
}

export class ProductModuleDefinition {
  readonly productModuleDefinitionId: string;
  readonly productModuleId: string;
  readonly claimSchemaId: string;
  readonly claimBlocksSchemaId: string;
  readonly quoteSchemaId: string;
  readonly applicationSchemaId: string;
  readonly applicationSchema: any;
  readonly settings: ProductModuleDefinitionSettings;
  readonly versionMinor: number;
  readonly versionMajor: number;
  readonly termsFileId: string;
  readonly scheduleId: string;
  readonly quoteSummaryId: string;
  readonly welcomeLetterId: string;
  readonly memberCertificateId: string;
  readonly publishedAt?: Moment;
  readonly publishedBy?: RequestedBy;
  readonly documentationId?: string;
  readonly createdAt: Moment;
  readonly createdBy: RequestedBy;
  readonly codeId: string;
  readonly readmeMarkdownId?: string;
  readonly changelogMarkdownId?: string;
  readonly productModuleEmbedConfig?: ProductModuleDefinitionEmbeddedConfig;

  constructor(init: Construct<ProductModuleDefinition>) {
    Object.assign(this, init);
  }

  static fromNetwork(init: NetworkProductModuleDefinition) {
    return new ProductModuleDefinition({
      productModuleDefinitionId: init.product_module_definition_id,
      productModuleId: init.product_module_id,
      claimSchemaId: init.claim_schema_id,
      claimBlocksSchemaId: init.claim_blocks_schema_id,
      quoteSchemaId: init.quote_schema_id,
      applicationSchemaId: init.application_schema_id,
      applicationSchema: init.application_schema,
      settings: ProductModuleDefinitionSettings.fromNetwork(init.settings),
      codeId: init.code_id,
      createdAt: momentTimezone(init.created_at),
      createdBy: init.created_by,
      memberCertificateId: init.member_certificate_id,
      quoteSummaryId: init.quote_summary_id,
      scheduleId: init.schedule_id,
      termsFileId: init.terms_file_id,
      versionMajor: init.version_major,
      versionMinor: init.version_minor,
      welcomeLetterId: init.welcome_letter_id,
      publishedAt: init.published_at ? momentTimezone(init.published_at) : undefined,
      publishedBy: init.published_by,
      readmeMarkdownId: init.readme_markdown_id ? init.readme_markdown_id : undefined,
      changelogMarkdownId: init.changelog_markdown_id ? init.changelog_markdown_id : undefined,
      documentationId: init.documentation_id,
      productModuleEmbedConfig: init.product_module_embed_config,
    });
  }
}

export interface NetworkProductModuleDefinition {
  product_module_definition_id: string;
  product_module_id: string;
  claim_schema_id: string;
  claim_blocks_schema_id: string;
  quote_schema_id: string;
  application_schema_id: string;
  application_schema: any;
  settings?: ProductModuleDefinitionSettings;
  version_minor: number;
  version_major: number;
  terms_file_id: string;
  schedule_id: string;
  quote_summary_id: string;
  welcome_letter_id: string;
  member_certificate_id: string;
  code_id: string;
  created_at: string;
  created_by: RequestedBy;
  published_at?: string;
  published_by?: RequestedBy;
  readme_markdown_id?: string;
  changelog_markdown_id?: string;
  documentation_id?: string;
  product_module_embed_config?: ProductModuleDefinitionEmbeddedConfig;
}
