import React from 'react';
import { Input as ReactstrapInput } from 'reactstrap';
import ReactSelect, { Option, Options } from 'react-select';
import { CountryCode, parsePhoneNumberWithError } from 'libphonenumber-js/mobile';
import { FormElement, FormElementProps, FormElementState } from './form-element';
import { Countries } from './countries';
import { Validator, ValidatorResult } from './validation';

export interface CellphoneValue {
  country: string;
  number: string | undefined;
}

export type CellphoneProps = FormElementProps<CellphoneValue>;

export class Cellphone extends FormElement<CellphoneValue, CellphoneProps, FormElementState<CellphoneValue>> {
  onCountryChange = (newValue: Option | null) => {
    if (newValue) {
      this.valueChanged({
        country: newValue.value as string,
        number: this.state.value.number,
      });
    }
  };

  onCellphoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.valueChanged({
      country: this.state.value.country,
      number: event.target.value === '' ? undefined : event.target.value,
    });
  };

  onFilter = (options: Options, filter: string) => {
    return options.filter((opt: any) => opt.name.toLowerCase().includes(filter.toLowerCase()));
  };

  protected _render() {
    const disabled = !!(
      this.props.disabled &&
      this.props.defaultValue &&
      this.props.defaultValue.number &&
      !this.state.changed
    );

    return (
      <div className='select-cellphone-country'>
        <ReactSelect
          className={this.getValidationClassName() + (this.state.focused ? ' is-focused' : '')}
          {...this.props}
          disabled={disabled}
          onBlur={this.onBlur}
          onChange={this.onCountryChange}
          onFocus={this.onFocus}
          value={this.state.value.country}
          clearable={false}
          filterOptions={this.onFilter}
          options={
            Countries.map((c) => {
              return {
                name: c.name,
                label: (
                  <span>
                    <span className={`flag-icon flag-icon-${c.code.toLowerCase()}`} />
                    <span>&nbsp;&nbsp;{c.name}</span>
                  </span>
                ),
                value: c.code,
              };
            }) as any
          }
        />
        <div className='select-cellphone-country-text-container'>
          <ReactstrapInput
            valid={this.state.touched && this.state.valid}
            invalid={this.state.touched && !this.state.valid}
            className={this.state.focused ? 'focus' : ''}
            name={this.props.name}
            type='text'
            placeholder={this.props.placeholder || 'Enter mobile number'}
            defaultValue={this.state.value.number}
            required={this.props.required}
            readOnly={this.props.readonly}
            disabled={disabled}
            autoFocus={this.props.autoFocus}
            onBlur={this.onBlur}
            onChange={this.onCellphoneChange}
            onFocus={this.onFocus}
          />
        </div>
      </div>
    );
  }

  protected getValueFromProps(props: CellphoneProps) {
    return this.getValue(props.defaultValue);
  }

  public getSpecificValidators(props: CellphoneProps) {
    const validators = [];
    validators.push(new CellphoneValidator(props.label, props.required === true));
    return validators;
  }

  private getValue(value: CellphoneValue | undefined) {
    return (
      value ||
      ({
        country: 'ZA',
        number: undefined,
      } as CellphoneValue)
    );
  }
}

export class CellphoneValidator implements Validator<CellphoneValue> {
  constructor(private readonly name: string, private readonly required: boolean) {}

  validate(value: CellphoneValue) {
    if (!this.required && !value.number) {
      return { valid: true };
    }

    if (typeof value.country !== 'string') {
      return {
        valid: false,
        message: 'Invalid country selected.',
      };
    }

    const result: ValidatorResult = {
      valid: value.number !== undefined,
    };

    if (!result.valid) {
      result.message = `${this.name} is required.`;
      return result;
    }

    if (!value.number || value.number === '') {
      result.message = `${this.name} is required.`;
      return result;
    }

    const countryCode = value.country as CountryCode;
    try {
      const numberForCountry = parsePhoneNumberWithError(value.number, countryCode);
      if (!numberForCountry.isValid() || countryCode !== numberForCountry.country) {
        result.valid = false;
        result.message = `${this.name} should be valid number for country ${value.country}.`;
        return result;
      }
    } catch (error) {
      result.message = `${this.name} is ${(error.message as string).replaceAll('_', ' ').toLocaleLowerCase()}.`;
      result.valid = false;
      return result;
    }

    return result;
  }
}
