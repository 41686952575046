import React, { MouseEventHandler, RefObject } from 'react';
import momentTimezone, { Moment } from 'moment-timezone';
import { RouteComponentProps } from 'react-router-dom';
import { EventType } from './event-type';
import { EntityType } from '../../../framework';
import { Transition } from '../../../../components';
import { RequestedBy } from '../../created-by';
import { EventUser } from './event-user';

export class Event<E = any, T extends EventType = EventType> {
  readonly entityType = EntityType.Event;

  public type: T;
  public claimId?: string;
  public complaintId?: string;
  public memberId?: string;
  public callId?: string;
  public createdAt: Moment;
  public requestedBy: RequestedBy;
  public entity: E;

  constructor(init: Partial<Event<any, T>>, entity: E) {
    // eslint-disable-next-line no-param-reassign
    init.createdAt = momentTimezone(init.createdAt || (init as any).actionedAt);
    Object.assign(this, init);
    this.entity = entity;

    if (this.claimId) {
      this.entity = { ...this.entity, claimId: this.claimId };
    }

    if (this.complaintId) {
      this.entity = { ...this.entity, complaintId: this.complaintId };
    }

    if (this.memberId) {
      this.entity = { ...this.entity, memberId: this.memberId };
    }

    if (this.callId) {
      this.entity = { ...this.entity, callId: this.callId };
    }
  }
}

export enum DisplayEventContext {
  Policy,
  Policyholder,
  Claim,
  Complaint,
  Member,
  Application,
}

export interface DisplayEventProps<T extends Event<any> = Event<any>> {
  event: T;
  claimId?: string;
  context: DisplayEventContext;
  full?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  selected?: boolean;
  history?: RouteComponentProps;
}

export abstract class DisplayEvent<P extends DisplayEventProps = DisplayEventProps, S = any> extends React.Component<
  P,
  S
> {
  private readonly ref: RefObject<HTMLDivElement>;

  constructor(props: P) {
    super(props);

    this.ref = React.createRef();
  }

  scrollTo(element: HTMLDivElement, to: number, duration: number) {
    if (to < 0) {
      to = 0;
    }

    const start = element.scrollTop;
    const change = to - start;
    const increment = 20;
    let currentTime = 0;

    const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
      t /= d / 2;
      if (t < 1) {
        return (c / 2) * t * t + b;
      }
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const animateScroll = () => {
      currentTime += increment;
      const val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }

  scroll = () => {
    setTimeout(() => {
      const element = this.ref.current as HTMLDivElement;
      if (!element) {
        return;
      }
      const parent = element.parentElement as HTMLDivElement;
      this.scrollTo(parent, element.offsetTop - 300, 250);
    });
  };

  componentWillReceiveProps(nextProps: P) {
    if (!this.props.full || nextProps.selected === this.props.selected || !nextProps.selected) {
      return;
    }

    this.scroll();
  }

  componentDidMount() {
    if (this.props.selected === true) {
      this.scroll();
    }
  }

  public render() {
    if (this.props.full) {
      const subtitle = this.getSubtitle();

      return (
        <div
          ref={this.ref}
          className={`full-event${this.props.selected ? ' selected ' : ''}`}
          key={this.props.event.type + this.props.event.createdAt.toISOString()}
          onClick={this.props.onClick}
        >
          <div className='full-event-header'>
            <div className='full-event-title'>
              {this.getTitle()}
              {subtitle && <div className='full-event-subtitle'>{subtitle}</div>}
            </div>
            <div className='full-event-common'>
              <div className='full-event-date'>
                <span>{this.props.event.createdAt.format('DD MMM YYYY')}</span> at{' '}
                <span>{this.props.event.createdAt.format('HH:mm:ss')}</span>
              </div>
              <div className='full-event-agent'>
                <EventUser prefix='by' requestedBy={this.props.event.requestedBy} />
              </div>
            </div>
          </div>
          <div className='full-event-content'>{this.renderFull()}</div>
        </div>
      );
    }

    return this.renderBubble();
  }

  public renderBubble() {
    const subtitle = this.getSubtitle();
    return (
      <div
        className={`event${this.props.selected ? ' selected' : ''}`}
        key={this.props.event.type + this.props.event.createdAt.toISOString()}
        onClick={this.props.onClick}
      >
        <div className='event-common'>
          <div className='event-date'>{this.props.event.createdAt.format('DD MMM YYYY')}</div>
          <div className='event-agent'>
            <EventUser prefix='by' requestedBy={this.props.event.requestedBy} />
          </div>
        </div>
        <div className='event-icon'>
          <div className='event-icon-line-top' />
          <div className='event-icon-icon' />
          <div className='event-icon-line-bottom' />
        </div>
        <div className='event-data'>
          <div className='event-data-content'>
            <div className='event-data-content-title' style={{ fontWeight: 'bold' }}>
              {this.getTitle()}
            </div>
            {subtitle && (
              <div className='event-data-content-subtitle'>
                <small>{subtitle}</small>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  protected getSubtitle(): string | null | JSX.Element {
    return null;
  }

  abstract getTitle: () => string | null | JSX.Element;

  abstract renderFull: () => JSX.Element | string | null;
}

export class EmptyEvent extends React.Component {
  public render() {
    return (
      <div className='event no-hover'>
        <div className='event-common'>
          <div className='event-date'>
            <Transition in={false} width={60} />
          </div>
          <div className='event-agent'>
            <Transition in={false} width={40} />
          </div>
        </div>
        <div className='event-icon'>
          <div className='event-icon-line-top' />
          <div className='event-icon-icon' />
          <div className='event-icon-line-bottom' />
        </div>
        <div className='event-data'>
          <div className='event-data-content'>
            <Transition in={false} width={130} />
          </div>
        </div>
      </div>
    );
  }
}
