import React, { useState } from 'react';
import { AccountErrorType } from 'insurance/account/domain/login-result';
import { ResetPassword } from './reset-password';
import { Form, Text, FormValues, FormGroupHalf, LinkButton, PrimaryButton } from '../components';
import { buttonText } from '../insurance/utils';
import { Alert } from '../components/alerts/alerts';

interface EmailAndPasswordProps {
  showLoginError: boolean;
  accountErrorType?: AccountErrorType;
  showSystemError: boolean;
  onSubmit: (email: string, password: string) => void;
  loading: boolean;
  updateShowForgotPassword: (params: { showForgotPassword: boolean }) => void;
}

export const EmailAndPassword = (props: EmailAndPasswordProps) => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [touched, setTouched] = useState(false);
  const [form, setForm] = useState({
    email: '',
    password: '',
  });
  const [valid, setValid] = useState(false);

  const submit = () => {
    if (valid) {
      props.onSubmit(form.email, form.password);
    }
    setTouched(true);
  };

  const changed = (valid: boolean, form: FormValues) => {
    const data = {
      email: form.value<string>('email'),
      password: form.value<string>('password'),
    };

    setForm(data);
    setValid(valid);
  };

  const initialized = (valid: boolean, form: FormValues) => {
    const data = {
      email: form.value<string>('email'),
      password: form.value<string>('password'),
    };

    setForm(data);
    setValid(valid);
  };

  const toggleForgotPasswordModal = () => {
    setShowForgotPassword(!showForgotPassword);
    props.updateShowForgotPassword({
      showForgotPassword: !showForgotPassword,
    });
  };

  const getErrorMessage = (accountErrorType?: AccountErrorType) => {
    let errorMessage;
    switch (accountErrorType) {
      case AccountErrorType.IncorrectEmailOrPassword:
        errorMessage = <>Incorrect email or password. Please try again.</>;
        break;
      case AccountErrorType.AccountLocked:
        errorMessage = <>Your account has been locked. Please reset your password.</>;
        break;
      case AccountErrorType.AccountSetupIncomplete:
        errorMessage = <>Your account setup is not complete. Please check your email to complete your account setup.</>;
        break;
      default:
        errorMessage = <span>An error has occurred. Please try again.</span>;
    }
    return errorMessage;
  };

  if (showForgotPassword) return <ResetPassword closeForgotPassword={toggleForgotPasswordModal} />;

  const isUserActivatedError = window.location.search.substr(1).includes('error=user_activated');
  const resetPasswordSuccess = window.location.search.includes('password-reset');

  return (
    <div>
      <h3 id='login-header'>Sign in</h3>
      {props.showLoginError && <Alert color='danger'>{getErrorMessage(props.accountErrorType)}</Alert>}
      {props.showSystemError && (
        <Alert color='danger'>An error has occurred while sending the OTP. Please try again.</Alert>
      )}
      {resetPasswordSuccess && <Alert color='success'>Password was successfully reset.</Alert>}
      {isUserActivatedError && <Alert color='info'>Your account is already activated. Login to continue.</Alert>}
      <Form onSubmit={submit} onValueChanged={changed} initialized={initialized} touched={touched}>
        <FormGroupHalf>
          <Text id='email' type='email' autocomplete='username' name='email' label='Email' defaultValue='' required />
        </FormGroupHalf>
        <FormGroupHalf>
          <Text
            id='password'
            type='password'
            autocomplete='current-password'
            name='password'
            label='Password'
            required
            minLength={10}
          />
        </FormGroupHalf>
        <div>
          <LinkButton
            id='forgot-password-button'
            style={{ color: '#4078fa', cursor: 'pointer' }}
            onClick={() => toggleForgotPasswordModal()}
          >
            Forgot password?
          </LinkButton>
        </div>
        <br />
        <br />
        <PrimaryButton
          type='submit'
          disabled={props.loading}
          style={{ width: '100%', height: 48 }}
          id='loginButton'
          className='btn btn-primary'
        >
          {buttonText({ loading: props.loading, text: 'Sign in' })}
        </PrimaryButton>
      </Form>
    </div>
  );
};
