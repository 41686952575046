import qs from 'qs';
import { PhoneNumberFieldValue } from 'rootstrap/components/forms/new-fields/phone-number-field';
import { CountryCode, isValidPhoneNumber, ParseError, parsePhoneNumberWithError } from 'libphonenumber-js';
import { DataTableProps, DataTableColumn } from 'rootstrap/components/data-table/data-table';
import { Cellphone } from 'insurance/general';
import { Currency } from 'insurance/product-modules/domain/product-module-definition-settings';
import { getTimeZones, TimeZone } from '@vvo/tzdb';
import Moment from 'moment-timezone';
import React from 'react';
import { Policyholder, PolicyholderType } from '../policyholders/domain/policyholder';
import { BeneficiaryEntityType } from '../beneficiaries/domain/beneficiary-entity-type';
import { Beneficiary } from '../beneficiaries/domain/beneficiary';

export type JSONPrimitive = string | number | boolean | null | undefined;
export type JSONObject = { [member: string]: JSONValue };
export type JSONArray = Array<JSONValue>;
export type JSONValue = JSONPrimitive | JSONObject | JSONArray;

export interface CurrencyOptions {
  currencyCode?: Currency | string | undefined;
  excludeCents?: boolean;
  excludeSymbol?: boolean;
}

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export const getCurrentUrl = () => {
  return window.location.href;
};

export const getCurrentUrlParams = () => {
  const parsedQueryParams = qs.parse(getCurrentUrl().split('?')[1]);
  return parsedQueryParams;
};

export const sum = (...args: number[]) => {
  return args.reduce((acc, curr) => acc + curr, 0);
};

export const buildApiUrl = (url: string, queryPrams: JSONObject) => {
  // Use comma as array format on Platform, indices on Dashboard.
  return `${url}${`?${qs.stringify(queryPrams, { arrayFormat: 'comma', encode: false })}`}`;
};

export const buildDashboardUrl = (url: string, queryPrams: JSONObject) => {
  // Use comma as array format on Platform, indices on Dashboard.
  return `${url}${`?${qs.stringify(queryPrams, { arrayFormat: 'indices', encode: false })}`}`;
};

export const tryCatch = async <R,>(
  f: (...args: any[]) => R | Promise<R>,
): Promise<{ result: R; error?: undefined } | { result?: undefined; error: any }> => {
  try {
    const result = await f();
    return {
      result,
      error: undefined,
    };
  } catch (error) {
    return {
      result: undefined,
      error,
    };
  }
};

export const parseCellphoneNumber = (number: string, countryCode?: string) => {
  try {
    const ph = parsePhoneNumberWithError(number, countryCode as any);

    if (countryCode && countryCode !== ph.country) {
      throw new Error('Invalid country code supplied');
    }

    if (!ph.country) {
      throw new Error('Unable to determine country code');
    }

    if (!ph.isValid()) {
      throw new Error('Invalid international number');
    }

    const value: PhoneNumberFieldValue = {
      number: toNationalFormat(ph.number.toString()),
      countryCode: ph.country,
      internationalNumber: ph.number.toString(),
    };
    return value;
  } catch (error) {
    if (error instanceof ParseError) {
      // eslint-disable-next-line unicorn/prefer-type-error
      throw new Error(`Invalid cellphone number: ${error.message.replaceAll('_', ' ').toLowerCase()}`);
    } else {
      console.error(`Error parsing cellphone number: ${error as string}`);
      throw new Error('Unable to determine international number');
    }
  }
};

export const isValidCellphoneNumber = (number: string, countryCode?: string) => {
  const country = countryCode as CountryCode;
  try {
    const parsedCellphone = parsePhoneNumberWithError(number, country);
    if (!parsedCellphone.isValid() || country !== parsedCellphone.country) {
      return false;
    }

    return isValidPhoneNumber(number, countryCode as any);
  } catch (error) {
    if (error instanceof ParseError) {
      console.error(`Error parsing cellphone number: ${error.message}`);
    } else {
      console.error(`Error parsing cellphone number: ${error as string}`);
    }
    return undefined;
  }
};

export function toCellphoneObject(init: string) {
  try {
    if (init.charAt(0) === '+') {
      const validated = parsePhoneNumberWithError(init);
      const cellphone: Cellphone = {
        number: validated.formatNational().replaceAll(' ', ''),
        country: validated.country as string,
      };
      return cellphone;
    }

    const validated = parsePhoneNumberWithError(init, 'ZA');
    const cellphone: Cellphone = {
      number: validated.formatNational().replaceAll(' ', ''),
      country: validated.country as string,
    };
    return cellphone;
  } catch (error) {
    console.error(error);
    return { number: init, country: 'ZZ' };
  }
}

export function toInternationalFormat(init: Cellphone | string) {
  // const number = this.cellphone && this.cellphone.number;
  if (typeof init !== 'string' && !init.number) {
    return undefined;
  }

  try {
    if (typeof init !== 'string') {
      const countryCode = init.country as CountryCode;
      return parsePhoneNumberWithError(init.number, countryCode).number.toString();
    }

    if (init.charAt(0) === '+') {
      return parsePhoneNumberWithError(init).number.toString();
    }

    return parsePhoneNumberWithError(init, 'ZA').number.toString();
  } catch (error) {
    if (error instanceof ParseError) {
      console.error(`Error parsing cellphone number: ${error.message}`);
    } else {
      console.error(`Error parsing cellphone number: ${error as string}`);
    }
    return undefined;
  }
}

export function toInternationalObject(init: Cellphone | string) {
  // const number = this.cellphone && this.cellphone.number;
  if (typeof init !== 'string' && !init.number) {
    return undefined;
  }

  try {
    if (typeof init !== 'string') {
      return { number: toInternationalFormat(init) as string, country: init.country };
    }

    if (init.charAt(0) === '+') {
      const parsedNumber = parsePhoneNumberWithError(init);
      return { number: parsedNumber.number.toString(), country: parsedNumber.country as string };
    }

    const parsedNumber = parsePhoneNumberWithError(init, 'ZA');
    return { number: parsedNumber.number.toString(), country: 'ZA' };
  } catch (error) {
    if (error instanceof ParseError) {
      console.error(`Error parsing cellphone number: ${error.message}`);
    } else {
      console.error(`Error parsing cellphone number: ${error as string}`);
    }
    return undefined;
  }
}

export function toPrettyInternationalFormat(init: Cellphone | string) {
  // const number = this.cellphone && this.cellphone.number;
  if (typeof init !== 'string' && !init.number) {
    return undefined;
  }

  try {
    if (typeof init !== 'string') {
      const countryCode = init.country as CountryCode;
      return parsePhoneNumberWithError(init.number, countryCode).formatInternational();
    }

    if (init.charAt(0) === '+') {
      return parsePhoneNumberWithError(init).formatInternational();
    }

    return parsePhoneNumberWithError(init, 'ZA').formatInternational();
  } catch (error) {
    if (error instanceof ParseError) {
      console.error(`Error parsing cellphone number: ${error.message}`);
    } else {
      console.error(`Error parsing cellphone number: ${error as string}`);
    }
    return undefined;
  }
}

export function toNationalFormat(init: string) {
  try {
    return init.charAt(0) === '+' ? parsePhoneNumberWithError(init).formatNational().replaceAll(' ', '') : init;
  } catch (error) {
    if (error instanceof ParseError) {
      console.error(`Error parsing cellphone number: ${error.message}`);
    } else {
      console.error(`Error parsing cellphone number: ${error as string}`);
    }
    return init;
  }
}

export function getCellphoneCountry(init: string) {
  try {
    return init.charAt(0) === '+' ? (parsePhoneNumberWithError(init).country as string) : 'ZA';
  } catch (error) {
    if (error instanceof ParseError) {
      console.error(`Error parsing cellphone number: ${error.message}`);
    } else {
      console.error(`Error parsing cellphone number: ${error as string}`);
    }
    return init;
  }
}

export function getTimezone() {
  const orgId = window.location.pathname.split('/')[2];
  const organizations = JSON.parse(localStorage.getItem('organizations') || '[]');
  const currentOrgTimezone = organizations.find((item: { organizationId: string }) => item.organizationId === orgId)
    .timezone;
  const userTimezone = JSON.parse(localStorage.getItem('user') as string).timezone;

  if (!userTimezone) {
    return currentOrgTimezone;
  }

  return userTimezone;
}

export const requireEnvVar = (envVar: string, value?: string) => {
  if (!value) {
    throw new Error(`Required environment variable '${envVar}' not defined.`);
  }

  return value;
};

export type TableProps<E, F> = Omit<DataTableProps<E, F>, 'rowData' | 'columns' | 'getRowKey'> & {
  columns?: DataTableColumn<E>[];
  rowData: {
    rows: E[];
    rowCount: number;
  };
};

export const sentenceify = (message: string) => {
  const s = message;
  return s.endsWith('.') ? `${s}` : `${s}.`;
};

export const mapBeneficiaries = ({
  beneficiaries,
  policyholder,
}: {
  beneficiaries: Beneficiary[];
  policyholder?: Policyholder;
}) => {
  const entityTypeMap: { [key in PolicyholderType]: BeneficiaryEntityType } = {
    [PolicyholderType.Individual]: BeneficiaryEntityType.Individual,
    [PolicyholderType.Company]: BeneficiaryEntityType.Company,
  };

  return beneficiaries.map((b) => {
    return b.policyholderId === policyholder?.policyholderId
      ? new Beneficiary({
          ...b,
          firstName: policyholder?.firstName,
          lastName: policyholder?.lastName,
          email: policyholder?.email,
          id: policyholder?.identification,
          entityType: b.entityType || (policyholder ? entityTypeMap[policyholder.type] : undefined),
        })
      : b;
  });
};

export const formatToCurrency = (number: number | string, currencyOptions?: CurrencyOptions) => {
  const formattedNumber = typeof number === 'string' ? parseInt(number.replace(/[^0-9-.]/g, '')) : number;
  const { currencyCode, excludeCents, excludeSymbol } = currencyOptions || {};

  const validCurrencyCode = Object.keys(Currency).includes(currencyOptions?.currencyCode || '');
  const shouldExcludeSymbol = validCurrencyCode ? !!excludeSymbol : true;

  const locale = navigator.language;

  const formatterOptions = {
    style: 'currency',
    currency: (validCurrencyCode && currencyCode) || 'ZAR',
    currencyDisplay: shouldExcludeSymbol ? 'code' : 'narrowSymbol',
    maximumFractionDigits: excludeCents ? 0 : 2,
  };
  const formatter = new Intl.NumberFormat(locale, formatterOptions);

  let formattedCurrency = shouldExcludeSymbol
    ? formatter
        .format(formattedNumber)
        .replace(/[A-Za-z]/g, '')
        .trim()
    : formatter.format(formattedNumber);

  if (!validCurrencyCode && typeof currencyCode === 'string') {
    formattedCurrency = `${currencyCode} ${formattedCurrency}`;
  }

  return formattedCurrency;
};

export const getCurrencySymbol = (currencyCode: Currency | string | undefined) => {
  return formatToCurrency(0, { currencyCode })
    .replace(/[\d\s.,]+/g, '')
    .trim();
};

export const getTimezoneOptions = () => {
  const utc = 'etc/utc';
  return getTimeZones({ includeUtc: true }).reduce((array: { [key: string]: any }[], timezone: TimeZone) => {
    if ((timezone.countryCode || timezone.name.toLowerCase() === utc) && Moment.tz.zone(timezone.name)) {
      const momentTimeZone = Moment.tz(timezone.name);
      array.push({
        value: timezone.name,
        label: (
          <span>
            <span
              className={`flag-icon flag-icon-${
                (timezone.countryCode && timezone.countryCode.toLowerCase()) || 'none'
              }`}
            />
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;(UTC {momentTimeZone.format('Z')}) {timezone.name.replaceAll('_', ' ')}
            </span>
          </span>
        ),
        name: timezone.name,
      });
    }
    return array;
  }, []);
};

export const formatToNumber = (params: { number: number | string }) => {
  const { number } = params;
  const formattedNumber = typeof number === 'string' ? parseInt(number.replace(/[^0-9-.]/g, '')) : number;

  const locale = navigator.language;

  const formatter = new Intl.NumberFormat(locale);

  return formatter
    .format(formattedNumber)
    .replace(/[A-Za-z]/g, '')
    .trim();
};
