import { ValidatorResult, Validator } from './types';

export class RequiredValidator<T> implements Validator<T> {
  constructor(private readonly name: string, private readonly message?: string) {}

  validate(value: T) {
    const valid = value !== null && value !== undefined;
    const result: ValidatorResult = { valid };

    if (!valid) {
      result.message = this.message || `${this.name} is required.`;
    }

    return result;
  }
}
