import React from 'react';
import { FormGroup, Label, Input as ReactstrapInput } from 'reactstrap';
import { FormElement, FormElementProps, FormElementState } from './form-element';

export type CheckboxValue = boolean;

export interface CheckboxProps extends FormElementProps<CheckboxValue> {
  inline?: boolean;
  className?: string;
}

export class Checkbox extends FormElement<CheckboxValue, CheckboxProps, FormElementState<CheckboxValue>> {
  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.valueChanged(event.target.checked);
  };

  groupClicked = () => {
    if (!this.props.disabled) {
      this.valueChanged(!this.state.value);
    }
  };

  render() {
    const { disabled, name, label, className } = this.props;
    if (this.props.inline) {
      return (
        <FormGroup key={name} onClick={this.groupClicked} className={className}>
          <div className='input-wrapper inline'>{this._render()}</div>
          <Label
            for={name}
            className={`checkbox-label${disabled ? '-disabled' : ''}${this.state.value ? ' checked' : ''}`}
          >
            {label}
          </Label>
        </FormGroup>
      );
    }

    return (
      <FormGroup key={name} onClick={this.groupClicked} className={className}>
        <Label
          for={name}
          className={`checkbox-label${disabled ? '-disabled' : ''}${this.state.value ? ' checked' : ''}`}
        >
          {label}
        </Label>
        <div className='input-wrapper'>{this._render()}</div>
      </FormGroup>
    );
  }

  protected _render() {
    return (
      <ReactstrapInput
        valid={this.state.touched && this.state.valid}
        invalid={this.state.touched && !this.state.valid}
        className='form-control'
        name={this.props.name}
        type='checkbox'
        placeholder={this.props.placeholder}
        checked={this.state.value}
        required={this.props.required}
        readOnly={this.props.readonly}
        autoFocus={this.props.autoFocus}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onFocus={this.onFocus}
      />
    );
  }

  protected getValueFromProps(props: CheckboxProps): CheckboxValue {
    return props.defaultValue || false;
  }
}
