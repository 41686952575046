import { Event, DisplayEvent } from '../event';
import { ClaimEventType } from './claim-event-type';
import { CreatedByType } from '../../../created-by';
import { Claim } from '../../../../claims/domain/claim';

export class ClaimSentToReviewEvent extends Event<any> {
  public static New(claim: Claim) {
    return new ClaimSentToReviewEvent(
      {
        type: ClaimEventType.ClaimSentToReview,
        requestedBy: {
          type: CreatedByType.System, // TODO
        },
      } as any,
      claim,
    );
  }
}

export class ClaimSentToReviewDisplayEvent extends DisplayEvent {
  getTitle = () => 'Claim sent to review';
  renderFull = () => null;
}
