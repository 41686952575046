import React from 'react';
import { FormElementProps, FormElementLifecycle } from './form-element';

interface Props extends FormElementLifecycle {
  children?: any;
  style?: any;
  className?: string;
}

export const FormGroupHalf = (props: Props) => {
  const childrenWithProps = React.Children.map(props.children, (child: React.ReactElement<FormElementProps<any>>) => {
    if (!child || typeof child.type === 'string') {
      return child;
    }

    const newProps = { ...props };
    delete newProps.children;

    return React.cloneElement(child, { ...child.props, ...newProps });
  });

  return (
    <div className={`form-group-half ${props.className && props.className}`} style={props.style}>
      {childrenWithProps}
    </div>
  );
};

export const FormGroupHalfSpacer = () => <div className='form-group-half-spacer' />;
