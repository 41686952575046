import momentTimezone, { Moment } from 'moment-timezone';
import { Construct } from '../../models/construct';
import { Policyholder } from '../../policyholders/domain/policyholder';

export enum CallEventType {
  CallCompleted = 'call_completed',
  CallFailed = 'call_failed',
  IncomingCallAdded = 'incoming_call_added',
  OutgoingCallAdded = 'outgoing_call_added',
}

export enum CallStatus {
  Initiated = 'initiated',
  Ringing = 'ringing',
  InProgress = 'in-progress',
  Completed = 'completed',
  Busy = 'busy',
  NoAnswer = 'no-answer',
}

export enum CallDirection {
  Inbound = 'inbound',
  Outbound = 'outbound',
}

export enum CallTopic {
  Claims = 'claims',
  Complaints = 'complaints',
  General = 'general',
}

export class Call {
  readonly callId: string;
  readonly createdAt: Moment;
  readonly callStatus: CallStatus;
  readonly direction?: CallDirection;
  readonly fromNumber?: string;
  readonly toNumber?: string;
  readonly topic?: CallTopic;
  readonly callDuration?: string;
  readonly policyholderId?: string;
  readonly policyholder?: Policyholder;

  constructor(init: Construct<Call>) {
    Object.assign(this, init);
  }

  prettyStatus() {
    return prettyCallStatus(this.callStatus);
  }

  public static fromNetwork(init: NetworkCall) {
    return new Call({
      callId: init.call_id,
      createdAt: momentTimezone(init.created_at),
      callStatus: init.call_status,
      direction: init.direction,
      fromNumber: init.from_number,
      toNumber: init.to_number,
      topic: init.topic,
      callDuration: init.call_duration,
      policyholderId: init.policyholder_id,
      policyholder: init.policyholder ? Policyholder.fromNetwork(init.policyholder) : undefined,
    });
  }
}

export class NetworkCall {
  call_id: string;
  created_at: string;
  call_status: CallStatus;
  direction?: CallDirection;
  from_number?: string;
  to_number?: string;
  topic: CallTopic;
  call_duration?: string;
  policyholder_id?: string;
  policyholder?: any;
}

export const prettyCallStatus = (status: CallStatus) => {
  switch (status) {
    case CallStatus.Initiated:
      return 'initiated';
    case CallStatus.Ringing:
      return 'ringing';
    case CallStatus.InProgress:
      return 'in progress';
    case CallStatus.Completed:
      return 'completed';
    case CallStatus.Busy:
      return 'busy';
    case CallStatus.NoAnswer:
      return 'missed';
  }
};
