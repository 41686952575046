import React from 'react';
import momentTimezone from 'moment-timezone';
import { lowerCase, upperFirst } from 'lodash';
import { sentenceCase } from 'sentence-case';
import { UncontrolledTooltip } from 'reactstrap';
import {
  PolicyNotificationType,
  PaymentNotificationType,
  PaymentMethodNotificationType,
  ClaimNotificationType,
  MemberNotificationType,
  ComplaintNotificationType,
  ApplicationNotificationType,
  CustomNotificationType,
} from 'insurance/communications/domain/notification-type';
import { Event, DisplayEvent } from '../event';
import { EmailView } from './email-view';
import { SmsView } from './sms-view';
import { NotificationStatus } from '../../../../notifications/domain/notification-status';
import { NotificationChannel } from '../../../../notifications/domain/notification';

export interface OtherNotification {
  to: string[];
  content: string;
}

export interface SmsNotification {
  to: string[];
  content: string;
}

export interface EmailNotification {
  html: string;
  to: { name: string; email: string }[];
  fromName: string;
  fromEmail: string;
  subject: string;
  attachments?: {
    name: string;
    type: string;
    fileId?: string;
    fileUrl: string;
  }[];
}

interface NotificationStatusUpdate {
  status: NotificationStatus;
  createdAt: string;
  description?: string;
}

interface Notification {
  channel: NotificationChannel;
  notificationId: string;
  notificationType: NotificationCreatedEventType;
  status: NotificationStatus;
  statusUpdates: NotificationStatusUpdate[];
  data: EmailNotification | SmsNotification | OtherNotification;
}

export type NotificationCreatedEventType =
  | PolicyNotificationType
  | PaymentNotificationType
  | PaymentMethodNotificationType
  | ClaimNotificationType
  | MemberNotificationType
  | ComplaintNotificationType
  | ApplicationNotificationType
  | CustomNotificationType;

const channelMap: { [key in NotificationChannel]: string } = {
  [NotificationChannel.Email]: 'Email notification',
  [NotificationChannel.Sms]: 'SMS notification',
  [NotificationChannel.Other]: 'External notification',
};

const getPrettyNotificationType = (notificationType: NotificationCreatedEventType) =>
  upperFirst(lowerCase(notificationType).replace('eft', 'EFT').replace('pdf', 'PDF'));

const statusUpdateToDescription = (channel: NotificationChannel, status: NotificationStatus) => {
  const channelMap: { [key in NotificationChannel]: string } = {
    [NotificationChannel.Email]: 'Email',
    [NotificationChannel.Sms]: 'SMS',
    [NotificationChannel.Other]: 'Notification',
  };

  const prettyChannel = channelMap[channel];

  const statusMap: { [key in NotificationStatus]: string } = {
    [NotificationStatus.Sent]: `${prettyChannel} sent`,
    [NotificationStatus.Queued]: `${prettyChannel} queued for sending`,
    [NotificationStatus.Delivered]: `${prettyChannel} delivered`,
    [NotificationStatus.Rejected]: `${prettyChannel} rejected`,
    [NotificationStatus.Opened]: `${prettyChannel} opened`,
    [NotificationStatus.UnknownError]: `${prettyChannel} failed to send due to unknown error`,
  };

  return statusMap[status];
};

export class NotificationCreatedEvent extends Event<any> {
  notification: Notification;

  constructor(init: any, entity: any) {
    super(init, entity);
    Object.assign(this, init);
  }
}

export class NotificationCreatedDisplayEvent extends DisplayEvent {
  getTitle = () => {
    const { notification } = this.props.event as NotificationCreatedEvent;

    return channelMap[notification.channel];
  };

  getSubtitle() {
    const { notification } = this.props.event as NotificationCreatedEvent;

    const [latestUpdate] = notification.statusUpdates
      .sort((a, b) => momentTimezone(b.createdAt).diff(momentTimezone(a.createdAt)))
      .map((statusUpdate) => ({
        ...statusUpdate,
        createdAt: momentTimezone(statusUpdate.createdAt),
      }));

    const type = getPrettyNotificationType(notification.notificationType);

    return (
      <span>
        {type}
        {type && <br />}
        <span>
          {sentenceCase(latestUpdate ? latestUpdate.status : notification.status)}
          {latestUpdate && latestUpdate.createdAt.format('[ at] HH:mm [on] DD MMM YYYY')}
        </span>
      </span>
    );
  }

  renderFull = () => {
    const { notification } = this.props.event as NotificationCreatedEvent;
    const { notificationId } = notification;

    return (
      <>
        {notification.channel === NotificationChannel.Email && (
          <EmailView notification={notification.data as EmailNotification} title={this.getTitle()} />
        )}
        {notification.channel === NotificationChannel.Sms && (
          <SmsView notification={notification.data as SmsNotification} title={this.getTitle()} />
        )}
        <br />
        {notification.statusUpdates.length > 0 && (
          <p>
            <strong>Status updates:</strong>
          </p>
        )}
        {notification.statusUpdates
          .sort((a, b) => momentTimezone(b.createdAt).diff(momentTimezone(a.createdAt)))
          .map((statusUpdate, i) => (
            <div className='event-content-line'>
              <span>
                {momentTimezone(statusUpdate.createdAt).format('DD MMM YYYY [at] HH:mm:ss')}
                {' - '}
              </span>
              <span className='event-content-grey'>
                {statusUpdateToDescription(notification.channel, statusUpdate.status)}
              </span>
              {statusUpdate.description && (
                <span>
                  <img
                    id={`statusUpdate-${notificationId}-${i}`}
                    src='/assets/images/icons/icon-16-px-information.svg'
                    alt=''
                  />
                  <UncontrolledTooltip placement='right' target={`statusUpdate-${notificationId}-${i}`}>
                    {statusUpdate.description}
                  </UncontrolledTooltip>
                </span>
              )}
            </div>
          ))}
      </>
    );
  };
}
