import React from 'react';
import { FormGroup, Label, UncontrolledTooltip, Col } from 'reactstrap';
import { ValidatorResult, Validator, RequiredValidator } from './validation';

export interface FormElementLifecycle {
  onValidityChanged?(formElement: any): void;
  onValueChanged?(formElement: any): void;
  registerWithForm?(formElement: any): void;
}

export interface FormElementProps<TValue> extends FormElementLifecycle {
  name: string;
  label: string;
  description?: string;
  placeholder?: string;
  className?: string;
  id?: string;
  defaultValue?: TValue;
  touched?: boolean;
  required?: boolean;
  requiredMessage?: string;
  readonly?: boolean;
  disabled?: boolean;
  inline?: boolean;
  autoFocus?: boolean;
  autocomplete?: string;
  ommitLabelInErrorMessage?: boolean;

  formValid?: boolean;
  sublabel?: string;
  hidden?: boolean;

  validators?: Validator<TValue> | Validator<TValue>[];
  isValid?: boolean;

  onBlur?(event: React.FocusEvent<HTMLInputElement>): void;
  onFocus?(event: React.FocusEvent<HTMLInputElement>): void;
  onChange?(newValue: TValue): void;
}

export interface FormElementState<TValue> {
  valid: boolean;
  error?: string;
  touched: boolean;
  focused: boolean;
  lostFocus: boolean;
  autoFocus: boolean;
  changed: boolean;
  value: TValue;
  tooltipOpen: boolean;
}

export abstract class FormElement<
  T,
  P extends FormElementProps<T>,
  S extends FormElementState<T>
> extends React.Component<P, S> {
  constructor(props: P) {
    super(props);

    this.state = {
      valid: false,
      touched: props.touched || false,
      focused: false,
      autoFocus: props.autoFocus,
      changed: false,
      value: this.getValueFromProps(props),
      tooltipOpen: false,
    } as S;

    const validation = this.isValid(this.state.value, props);
    (this.state as any).valid = validation.valid;
    (this.state as any).error = validation.message;
  }

  componentWillReceiveProps(nextProps: P) {
    if (this.props.defaultValue !== nextProps.defaultValue || this.props.required !== nextProps.required) {
      const value = this.getValueFromProps(nextProps) as S['value'];
      let error;
      const result = this.isValid(value, nextProps);
      if (!result.valid) {
        error = result.message;
      }
      this.setState({ error, valid: result.valid, value });
    }

    this.setState({ touched: nextProps.touched || this.state.touched });
  }

  componentDidMount() {
    if (this.props.registerWithForm) {
      this.props.registerWithForm(this);
    }
  }

  componentDidUpdate(oldProps: P, oldState: S) {
    if (
      (oldState.valid !== this.state.valid ||
        (oldState.value !== this.state.value && oldProps.defaultValue === this.props.defaultValue)) &&
      this.props.onValidityChanged
    ) {
      this.props.onValidityChanged(this);
    }
  }

  toggleTooltip = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };

  render() {
    const invalid = this.state.touched && !this.state.valid;
    const labelId = `${this.props.name}-tooltip-icon`.replace(/\s/g, '-').toLowerCase();

    if (this.props.inline) {
      return (
        <FormGroup
          id={this.props.id}
          key={this.props.name}
          className={(this.props.hidden ? 'hidden ' : '') + (this.props.className || '')}
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <Label style={{ width: '100%', paddingTop: 8 }} for={this.props.name}>
            {this.props.label}
            {this.props.sublabel ? ` ${this.props.sublabel}` : ''} {this.props.required ? ' *' : ''}
            {this.props.description && (
              <img
                alt='/assets/images/icons/icon-16-px-information.svg'
                style={{ marginLeft: '8px' }}
                id={labelId}
                src='/assets/images/icons/icon-16-px-information.svg'
              />
            )}
          </Label>
          <Col sm={6} style={{ padding: 0 }}>
            {this.props.description && (
              <UncontrolledTooltip
                target={labelId}
                isOpen={this.state.tooltipOpen}
                toggle={this.toggleTooltip}
                innerClassName='tooltip-small'
                placement='right'
              >
                {this.props.description}
              </UncontrolledTooltip>
            )}
            <div style={{ width: '100%', padding: 0 }} className={`input-wrapper ${invalid ? 'invalid' : ''}`}>
              {this._render()}
            </div>
            {invalid && <small className='warning'>{this.state.error}</small>}
          </Col>
        </FormGroup>
      );
    }

    return (
      <FormGroup key={this.props.name} className={(this.props.hidden ? 'hidden ' : '') + (this.props.className || '')}>
        <Label for={this.props.name} style={{ display: 'flex' }}>
          {this.props.label}
          {this.props.sublabel ? ` ${this.props.sublabel}` : ''} {this.props.required ? ' *' : ''}
          {this.props.description && (
            <img
              alt='/assets/images/icons/icon-16-px-information.svg'
              style={{ marginLeft: '8px' }}
              id={labelId}
              src='/assets/images/icons/icon-16-px-information.svg'
            />
          )}
        </Label>

        {this.props.description && (
          <UncontrolledTooltip
            target={labelId}
            isOpen={this.state.tooltipOpen}
            toggle={this.toggleTooltip}
            innerClassName='tooltip-small'
            placement='right'
          >
            {this.props.description}
          </UncontrolledTooltip>
        )}

        <div className={`input-wrapper ${invalid || this.props.isValid === false ? 'invalid' : ''}`}>
          {this._render()}
        </div>

        {invalid && <small className='warning'>{this.state.error}</small>}
      </FormGroup>
    );
  }

  onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    this.setState({ focused: true });
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  };

  onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    this._onBlur(event);
  };

  protected _onBlur(event: React.FocusEvent<HTMLInputElement>) {
    if (this.state.autoFocus) {
      this.setState({ autoFocus: false });
    } else {
      this.setState({ touched: true, focused: false, lostFocus: true });
    }

    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  }

  protected abstract getValueFromProps(props: P): T;

  protected abstract _render(): JSX.Element;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public getSpecificValidators(props: any): Validator<T>[] {
    return [];
  }

  public valueChanged(value: T, touched = true) {
    let valid = false;
    let error;
    const result = this.isValid(value, this.props);
    valid = result.valid;
    if (!valid) {
      error = result.message;
    }

    this.setState({
      value: value as S['value'],
      changed: true,
      touched: this.state.lostFocus && touched,
      valid,
      error,
    });

    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  protected isValid = (value: T, props: P): ValidatorResult => {
    for (const validator of [...this._getValidators(props), ...this.getSpecificValidators(props)]) {
      const result = validator.validate(value);
      if (!result.valid) {
        return result;
      }
    }

    return { valid: true };
  };

  protected getValidationClassName() {
    const valid = this.state.touched && this.state.valid;
    const invalid = this.state.touched && !this.state.valid;
    let className = '';
    if (valid) {
      className = 'is-valid';
    }
    if (invalid) {
      className = 'is-invalid';
    }

    return className;
  }

  private _getValidators(props: P): Validator<T>[] {
    const validators = [];

    if (props.validators) {
      if (Array.isArray(props.validators)) {
        validators.push(...props.validators);
      } else {
        validators.push(props.validators);
      }
    }

    if (props.required === true) {
      validators.push(
        new RequiredValidator<T>(!props.ommitLabelInErrorMessage ? props.label : '', props.requiredMessage),
      );
    }

    return validators;
  }
}
