import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Event, DisplayEvent, DisplayEventContext, DisplayEventProps } from '../event';
import { LinkButton } from '../../../../../components';
import { navigateTo } from '../../../../../core';
import { Context } from '../../../../framework';
import { PolicyViewStore } from '../../../../policies/stores/policy-view-store';

export class PolicyImportedEvent extends Event<any> {
  public readonly policyId: string;
}

type PolicyImportedDisplayEventProps = DisplayEventProps<PolicyImportedEvent>;

interface Injected extends PolicyImportedDisplayEventProps {
  policyViewStore: PolicyViewStore;
}

@inject('policyViewStore')
@observer
export class PolicyImportedDisplayEventImpl extends DisplayEvent<PolicyImportedDisplayEventProps> {
  get injected() {
    return this.props as Injected;
  }

  getTitle = () => {
    if (this.props.context === DisplayEventContext.Policy) {
      return 'Policy imported';
    }

    const { isLoading, policy } = this.injected.policyViewStore;

    return (
      <>
        Policy <i>{!isLoading && policy ? policy.packageName : ''}</i> imported
      </>
    );
  };

  renderFull = () => {
    const { isLoading, policy } = this.injected.policyViewStore;
    const { history } = this.props as any;
    return (
      <>
        Policy <i>{!isLoading && policy ? policy.policyNumber : ''}</i> imported.
        {this.props.context !== DisplayEventContext.Policy && (
          <LinkButton
            disabled={!policy?.policyId}
            onClick={() =>
              navigateTo({
                url: `/orgs/${Context.organizationId}/insurance/policies/${policy?.policyId}`,
                history,
              })
            }
          >
            View policy
          </LinkButton>
        )}
      </>
    );
  };
}

export const PolicyImportedDisplayEvent = withRouter(PolicyImportedDisplayEventImpl as any);
