import momentTimezone from 'moment-timezone';
import { ValidatorResult, Validator } from './types';

export class IdValidator<T extends string = string> implements Validator<T> {
  constructor(private readonly name: string) {}

  validate(value: T) {
    if (!value) {
      return { valid: true };
    }

    const result: ValidatorResult = { valid: isValidIdNumber(value) };

    if (!result.valid) {
      result.message = `${this.name} should be a valid South African ID number.`;
    }

    return result;
  }
}

export class IdAgeValidator<T extends string = string> implements Validator<T> {
  constructor(private readonly name: string, private readonly age: number) {}

  validate(value: T) {
    if (!value) {
      return { valid: true };
    }

    const result: ValidatorResult = { valid: isValidAge(value, this.age) };

    if (!result.valid) {
      result.message = `${this.name} should reflect an age of ${this.age}.`;
    }

    return result;
  }
}

export class IdGenderValidator<T extends string = string> implements Validator<T> {
  constructor(private readonly name: string, private readonly gender: string) {}

  validate(potentialId: T) {
    if (!potentialId) {
      return { valid: true };
    }

    if (!isValidIdNumber(potentialId)) {
      return {
        valid: false,
        message: `${this.name} should be a valid South African ID number.`,
      };
    }

    const genderCode = parseInt(potentialId.substr(6, 4), 10);
    const isFemale = genderCode <= 4999;
    const gender = isFemale ? 'female' : 'male';

    const result: ValidatorResult = { valid: gender === this.gender };

    if (!result.valid) {
      result.message = `${this.name} should reflect a gender of ${this.gender}.`;
    }

    return result;
  }
}

export const isValidIdNumber = (potentialId: string) => {
  if (/[^0-9]+/.test(potentialId)) {
    return false;
  }

  if (!momentTimezone(potentialId.substring(0, 6), 'YYMMDD', true).isValid()) {
    return false;
  }

  if (potentialId.length !== 13) {
    return false;
  }

  const month = parseInt(potentialId.substr(2, 2), 10);
  if (month === 0 || month > 12) {
    return false;
  }

  const day = parseInt(potentialId.substr(4, 2), 10);
  if (day === 0 || day > 31) {
    return false;
  }

  // The Luhn Algorithm for the check digit
  // tslint:disable-next-line:no-shadowed-variable
  const [sum] = potentialId
    .split('')
    .map((x) => parseInt(x, 10))
    .reduce(
      ([sum, isOdd]: any, digit) => {
        if (isOdd) {
          const doubledDigit = digit * 2;
          return [sum + (doubledDigit > 9 ? doubledDigit - 9 : doubledDigit), !isOdd];
        }
        return [sum + digit, !isOdd];
      },
      [0, false],
    );

  return sum % 10 === 0;
};

export const isValidAge = (potentialId: string, age: number) => {
  if (!isValidIdNumber(potentialId)) {
    return false;
  }

  /**
   * See http://momentjs.com/docs/#/parsing/string-format/ -> Parsing two digit years
   *
   * Moment switches the century for any date of birth before 1969.
   * Therefore, if the calculated date of birth is a future date,
   * we assume it's due to this bug. Therefore, manually convert to
   * the correct century.
   */
  let birthDate = momentTimezone(potentialId.slice(0, 6), 'YYMMDD');

  if (birthDate.isSameOrAfter(momentTimezone())) {
    birthDate = momentTimezone(`19${potentialId.slice(0, 6)}`, 'YYYYMMDD');
  }

  return momentTimezone().diff(birthDate, 'years', false) === age;
};
