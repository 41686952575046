import styled, { keyframes } from 'styled-components/macro';

const fader = keyframes`
  0%,
  100%,
  80% {
    opacity: 0
  }

  40% {
    opacity: 1
  }
`;

export const Dot = styled.span`
  width: 5px;
  height: 5px;
  background-color: white;
  display: inline-block;
  border-radius: 50%;
  margin: 0 3px;
  animation-fill-mode: both;
  animation: ${fader} 1.8s infinite ease-in-out;
  vertical-align: middle;
`;

export const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${Dot} {
    transform: translateZ(0);

    :first-child {
      animation-delay: -0.32s;
    }

    :nth-child(2) {
      animation-delay: -0.16s;
    }
  }
`;
