import React from 'react';
import { FormGroup, Label, Input as ReactstrapInput } from 'reactstrap';
import { FormElement, FormElementProps, FormElementState } from './form-element';

export type RadioValue = string | undefined;

export interface RadioProps extends FormElementProps<RadioValue> {
  inline?: boolean;
  bold?: boolean;
  inlineLabel?: boolean;
  options: {
    label: string;
    value: string;
    description?: string;
  }[];
}

export class Radio extends FormElement<RadioValue, RadioProps, FormElementState<RadioValue>> {
  onChange = (value: RadioValue) => {
    if (this.props.disabled || value === this.state.value) {
      return;
    }

    this.valueChanged(value);
  };

  render() {
    return (
      <FormGroup key={this.props.name} tag='fieldset' className={this.props.inlineLabel ? 'inline-label' : ''}>
        {this.props.label !== '' && (
          <Label style={{ marginBottom: 8 }} for={this.props.name} className={this.props.bold ? 'bold' : ''}>
            {this.props.label} {this.props.required ? ' *' : ''}
          </Label>
        )}
        <div className={`input-wrapper${this.props.inline ? ' inline-radio ' : ''}${this.getValidationClassName()}`}>
          {this._render()}
        </div>
      </FormGroup>
    );
  }

  protected _render() {
    return (
      <>
        {this.props.options.map((o) => (
          <FormGroup check key={o.value} onClick={() => this.onChange(o.value)}>
            <Label check style={{ cursor: 'default' }}>
              <ReactstrapInput
                className='form-control'
                style={{ cursor: 'default' }}
                name={this.props.name}
                type='radio'
                placeholder={this.props.placeholder}
                checked={o.value === this.state.value}
                required={this.props.required}
                readOnly={this.props.readonly}
                disabled={this.props.disabled}
                autoFocus={this.props.autoFocus}
                onChange={() => {}}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                inline='true'
              />
              <div>{o.label}</div>
            </Label>
            {o.description && <small style={{ marginLeft: '24px' }}>{o.description}</small>}
          </FormGroup>
        ))}
      </>
    );
  }

  protected getValueFromProps(props: RadioProps): RadioValue {
    return props.defaultValue;
  }
}
