import { rootApiRequest } from '../shared/api';
import { Pagination } from '../shared/pagination';
import { Call, CallStatus } from './domain/call';
import { EventFactory } from '../models';

export interface CallFilters {
  search?: string;
  callStatus?: CallStatus[];
}

export interface CallIncludes {
  policyholder?: boolean;
}

export class CallApi {
  public static async all(params: {
    includes?: CallIncludes;
    filters?: CallFilters;
    pagination: Pagination;
  }): Promise<{ calls: Call[]; total: number }> {
    const query: [string, string | number][] = [];

    if (params.filters) {
      if (params.filters.search) {
        query.push(['search', params.filters.search]);
      }
      if (params.filters.callStatus && params.filters.callStatus.length !== 0) {
        query.push(['call_status', params.filters.callStatus.join(',')]);
      }
    }

    if (params.includes && Object.keys(params.includes).length > 0) {
      query.push(['include', Object.keys(params.includes).join(',')]);
    }

    query.push(['page', params.pagination.page], ['page_size', params.pagination.pageSize]);

    const queryString = query.map(([key, value]) => `${key}=${value}`).join('&');

    const result = await rootApiRequest(
      `insurance/calls?${queryString}`,
      {
        raw: true,
      },
      '/v1/apps/',
    );

    const calls = (await result.json()) as any[];
    const total = result.headers.get('X-Total-Count');

    return {
      calls: (calls || []).map((element) => Call.fromNetwork(element)),
      total: parseInt(total || '0'),
    };
  }

  public static async getEvents(params: { call: Call }) {
    const { call } = params;

    const result = await rootApiRequest(`insurance/calls/${call.callId}/events`, {}, '/v1/apps/');

    return EventFactory.fromNetwork(result, call);
  }

  public static async getPlaybackUrl(params: { callId: string }) {
    const { callId } = params;

    try {
      return await rootApiRequest(`insurance/calls/${callId}/playback-url`, {}, '/v1/apps/');
    } catch (error) {
      console.log({ err: error });
      throw error;
    }
  }
}
