export enum OTPType {
  App = 'app_based',
  SMS = 'sms_based',
}

export enum AccountErrorType {
  IncorrectEmailOrPassword = 'incorrect_email_or_password',
  AccountLocked = 'account_locked',
  AccountSetupIncomplete = 'account_setup_incomplete',
  TwoFaIncorrect = 'two_fa_incorrect',
  TwoFaRequired = 'two_fa_required',
  Login = 'login',
}

export class LoginTwoFARequired {
  constructor(public type: OTPType, public error: AccountErrorType) {}
}

export class LoginSuccess {
  constructor(public loginToken: string, public userId: string) {}
}

export class LoginError {
  constructor(public error?: AccountErrorType) {}
}

export type LoginResult = LoginTwoFARequired | LoginSuccess | LoginError;
