import { Context } from '../framework/context';
import { accountStore } from '../account/stores/account-store';
import { camelToSnake } from '../utils';
import { Environment } from '../models/environment';

const { REACT_APP_ROOT_SANDBOX_API: rootSandboxApi, REACT_APP_ROOT_PROD_API: rootProductionApi } = process.env;

export const getApiUrl = () => {
  if (Context.environment === Environment.Sandbox) {
    return rootSandboxApi;
  }
  return rootProductionApi;
};

const getApiUrlFromParams = (environment: Environment) => {
  if (environment === Environment.Sandbox) {
    return rootSandboxApi;
  }
  if (environment === Environment.Production) {
    return rootProductionApi;
  }
};

export const getApiBaseUrl = ({ environment }: { environment: Environment }) => {
  const mapper: { [key in Environment]: string | undefined } = {
    [Environment.Sandbox]: rootSandboxApi,
    [Environment.Production]: rootProductionApi,
  };

  const apiBaseUrl = mapper[environment];
  if (!apiBaseUrl) {
    throw new Error('Cannot determine api base url');
  }

  return apiBaseUrl;
};

export interface FieldError {
  path: string;
  message: string;
}

export class ApiError extends Error {
  type: string;
  fieldErrors?: FieldError[];

  constructor(
    { message, type, fieldErrors }: { message: string; type: string; fieldErrors?: FieldError[] },
    ...params: any[]
  ) {
    super(...params);
    this.name = 'ApiError';
    this.message = message;
    this.type = type;
    this.fieldErrors = fieldErrors;
  }
}

const defaultErrorMessage =
  'Something went wrong. Please try again or contact support@root.co.za if the problem persists.';

export const apiRequest = async ({
  url,
  method,
  headers,
  body,
  loginToken,
}: {
  url: string;
  method: 'get' | 'post' | 'put' | 'patch' | 'delete' | 'head';
  headers?: Record<string, string>;
  body?: Record<string, string | number | boolean | null | undefined | Record<string, any>> | Record<string, any>[];
  loginToken?: string;
}) => {
  const defaultHeaders = {
    'content-type': 'application/json',
    ...(loginToken ? { authorization: `Bearer ${loginToken}` } : {}),
  };

  try {
    const response = await fetch(url, {
      method: method.toUpperCase(),
      headers: { ...defaultHeaders, ...headers },
      body: body && JSON.stringify(body),
    });
    if (!response.ok) {
      if (response.headers.get('content-type')?.includes('application/json')) {
        const json = await response.json();
        throw new ApiError({
          message: json?.error?.message || defaultErrorMessage,
          fieldErrors: json?.error?.errors,
          type: json?.error?.type || 'unknown_error',
        });
      } else {
        throw new ApiError({
          message: defaultErrorMessage,
          type: 'unknown_error',
        });
      }
    }

    return response;
  } catch (error) {
    throw new ApiError({
      message:
        error.message === 'Failed to fetch'
          ? 'Unable to make request, please check your network and try again.'
          : error.message || defaultErrorMessage,
      type: error.type || 'unknown_error',
      fieldErrors: error.fieldErrors,
    });
  }
};

export const rootApiRequest = async (
  url: string,
  options: {
    method?: string;
    headers?: { [k: string]: string };
    body?: { [k: string]: any };
    raw?: boolean;
    noOrganization?: boolean;
    environment?: Environment;
  },
  pathOverride = '/v1/insurance/organizations/',
) => {
  const token = accountStore.loginToken;
  const { organizationId } = Context;
  const fetchOptions = {
    headers: {
      authorization: `Bearer ${token}`,
      'content-type': 'application/json',
      ...(options.headers ? { ...options.headers } : {}),
    },
    method: options.method || 'GET',
    ...(options.body ? { body: JSON.stringify(camelToSnake(options.body)) } : {}),
  };

  const baseUrl = options.environment ? getApiUrlFromParams(options.environment) : getApiUrl();
  let path = `${baseUrl}${pathOverride}${organizationId}/${url}`;

  if (options.noOrganization) {
    path = `${baseUrl}${pathOverride}/${url}`;
  }

  const result = await fetch(path, fetchOptions);

  if (options.raw) {
    if (result.status === 401) {
      return (window.location.href = '/');
    }
    return result;
  }

  const contentType = result.headers.get('content-type');
  const body = await (contentType && contentType.includes('application/json') ? result.json() : result.text());

  if (result.status >= 400) {
    if (result.status === 401) {
      window.location.href = '/';
    }
    throw body;
  }

  return body;
};
