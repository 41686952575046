import React from 'react';
import { Link } from 'react-router-dom';
import { Transition } from '../animations/transition';

export interface BreadcrumbEntry {
  title: string;
  href: string;
  loaded: boolean;
}

interface BreadcrumbProps {
  entries: BreadcrumbEntry[];
}

export class Breadcrumbs extends React.Component<BreadcrumbProps> {
  render() {
    const lastIndex = this.props.entries.length - 1;

    return (
      <div className='breadcrumbs'>
        {this.props.entries.map((e, i) => (
          <div key={e.title} className='entry'>
            {lastIndex !== i && (
              <Link to={this.getHref(e.href)}>
                <Transition in={e.loaded} width={100}>
                  {e.title}
                </Transition>
              </Link>
            )}
            {lastIndex === i && (
              <Transition in={e.loaded} width={100}>
                {e.title}
              </Transition>
            )}
          </div>
        ))}
      </div>
    );
  }

  getHref(href: string) {
    if (href.includes('..')) {
      const currentParts = window.location.pathname.split('/');
      const numberOfBacks = href.split('..').filter((x) => x === '').length;
      currentParts.splice(currentParts.length - numberOfBacks, numberOfBacks);
      const newHref = currentParts.join('/');
      return newHref;
    }

    return href;
  }
}
