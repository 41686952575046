import React from 'react';

export default class Logo extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='80px'
        height='28px'
        viewBox='0 0 80 28'
        enableBackground='new 0 0 80 28'
        xmlSpace='preserve'
      >
        <g>
          <g>
            <g className='logo-word'>
              <path
                d='M46.035,10.555c-0.293-0.189-0.576-0.309-0.846-0.36c-1.031-0.201-1.859-0.014-2.502,0.397
                    c-0.232,0.149-0.445,0.327-0.629,0.533v-0.461c0-0.171-0.137-0.312-0.303-0.312h-1.73c-0.168,0-0.304,0.141-0.304,0.312v9.36
                    c0,0.174,0.136,0.314,0.304,0.314h1.812c0.166,0,0.303-0.141,0.303-0.314v-4.891c0-0.384,0.043-0.751,0.125-1.096
                    c0.082-0.333,0.205-0.633,0.371-0.889c0.16-0.249,0.357-0.44,0.598-0.583c0.488-0.288,1.164-0.288,1.938,0.071
                    c0.078,0.035,0.164,0.037,0.24,0.006c0.078-0.035,0.139-0.098,0.168-0.178l0.578-1.533C46.211,10.79,46.158,10.632,46.035,10.555
                    z'
              />
              <path
                d='M56.74,11.542c-0.461-0.457-1.021-0.811-1.67-1.054c-0.643-0.245-1.367-0.367-2.154-0.367
                    c-0.758,0-1.469,0.122-2.102,0.367c-0.643,0.244-1.199,0.597-1.66,1.054c-0.459,0.458-0.83,1.015-1.09,1.658
                    c-0.26,0.644-0.395,1.364-0.395,2.145c0,0.791,0.123,1.512,0.361,2.145c0.238,0.641,0.598,1.198,1.061,1.658
                    c0.461,0.455,1.02,0.812,1.662,1.056c0.633,0.241,1.361,0.364,2.162,0.364c0.748,0,1.451-0.123,2.094-0.364
                    c0.646-0.244,1.209-0.601,1.67-1.056s0.826-1.01,1.09-1.647c0.26-0.637,0.393-1.361,0.393-2.155c0-0.775-0.119-1.496-0.359-2.136
                    C57.561,12.562,57.203,12.001,56.74,11.542z M52.916,12.203c0.828,0,1.479,0.268,1.986,0.815c0.506,0.545,0.76,1.328,0.76,2.327
                    c0,0.485-0.068,0.925-0.207,1.312c-0.137,0.381-0.328,0.71-0.566,0.979c-0.24,0.271-0.527,0.479-0.859,0.627
                    c-0.66,0.296-1.564,0.296-2.24-0.002c-0.334-0.147-0.625-0.355-0.861-0.623c-0.242-0.268-0.426-0.598-0.557-0.978
                    c-0.129-0.385-0.201-0.827-0.201-1.314c0-0.477,0.072-0.916,0.203-1.309c0.129-0.386,0.312-0.717,0.555-0.983
                    c0.236-0.267,0.527-0.477,0.861-0.625C52.127,12.28,52.506,12.203,52.916,12.203z'
              />
              <path
                d='M69.674,11.542c-0.463-0.457-1.021-0.811-1.67-1.054c-0.645-0.245-1.365-0.367-2.152-0.367
                    c-0.762,0-1.469,0.122-2.105,0.367c-0.639,0.244-1.197,0.597-1.656,1.054c-0.463,0.456-0.828,1.014-1.09,1.658
                    c-0.266,0.644-0.396,1.364-0.396,2.145c0,0.789,0.123,1.511,0.361,2.145c0.24,0.641,0.598,1.198,1.062,1.658
                    c0.459,0.455,1.016,0.812,1.66,1.056c0.633,0.241,1.361,0.364,2.164,0.364c0.744,0,1.451-0.123,2.09-0.364
                    c0.648-0.244,1.209-0.601,1.67-1.057c0.461-0.455,0.826-1.009,1.09-1.646c0.264-0.639,0.396-1.362,0.396-2.155
                    c0-0.775-0.125-1.496-0.363-2.136C70.496,12.562,70.137,12.001,69.674,11.542z M65.852,12.203c0.826,0,1.477,0.268,1.984,0.815
                    c0.502,0.545,0.762,1.327,0.762,2.327c0,0.483-0.074,0.925-0.209,1.312c-0.137,0.381-0.328,0.71-0.57,0.98
                    c-0.238,0.269-0.523,0.477-0.854,0.625c-0.664,0.296-1.564,0.296-2.242-0.002c-0.334-0.146-0.625-0.354-0.865-0.623
                    c-0.234-0.268-0.42-0.597-0.551-0.977c-0.131-0.387-0.199-0.829-0.199-1.315c0-0.474,0.068-0.916,0.199-1.309
                    c0.131-0.386,0.316-0.718,0.551-0.983c0.24-0.267,0.531-0.477,0.865-0.625C65.064,12.28,65.439,12.203,65.852,12.203z'
              />
              <path
                d='M79.697,12.358c0.166,0,0.303-0.139,0.303-0.312v-1.381c0-0.171-0.137-0.312-0.303-0.312h-2.334V7.827
                    c0-0.174-0.135-0.313-0.303-0.313H75.25c-0.168,0-0.305,0.139-0.305,0.313v2.525h-1.588c-0.168,0-0.305,0.141-0.305,0.312v1.381
                    c0,0.173,0.137,0.312,0.305,0.312h1.588v4.982c0,1.012,0.285,1.776,0.85,2.271c0.549,0.484,1.363,0.729,2.422,0.729h1.48
                    c0.166,0,0.303-0.141,0.303-0.314v-1.457c0-0.173-0.137-0.312-0.303-0.312h-1.234c-0.408,0-0.707-0.078-0.865-0.231
                    c-0.154-0.148-0.234-0.432-0.234-0.837v-4.829H79.697z'
              />
            </g>
          </g>
          <g className='logo-icon'>
            <path
              d='M13.737,19.912v-5.943h1.75c0.394,0.592,1.058,0.952,1.777,0.952c1.179,0,2.136-0.958,2.136-2.137
                c0-1.178-0.957-2.137-2.136-2.137c-0.719,0-1.383,0.359-1.777,0.952h-3.774c-0.191,0-0.347,0.155-0.347,0.347v8.806
                c0,0.09,0.037,0.18,0.102,0.244l6.968,6.688c0.066,0.067,0.153,0.104,0.244,0.104h2.375c0.139,0,0.267-0.084,0.32-0.215
                c0.053-0.129,0.022-0.277-0.076-0.379L13.737,19.912z'
            />
            <path
              d='M30.301,27.402L30.299,0.346c0-0.19-0.157-0.346-0.348-0.346H0.348C0.156,0,0,0.156,0,0.346v27.113
                c0,0.19,0.156,0.346,0.348,0.346h0.441c0.036,0,0.07-0.009,0.104-0.017h1.131c0.191,0,0.348-0.157,0.348-0.35V2.37h25.553v23.34
                l-6.274-5.686h2.595c0.192,0,0.348-0.152,0.348-0.344V5.917c0-0.191-0.156-0.345-0.348-0.345H6.008
                c-0.191,0-0.346,0.154-0.346,0.345v21.521c0,0.192,0.154,0.35,0.346,0.35h1.679c0.191,0,0.347-0.157,0.347-0.35V7.943h14.187
                v9.713h-4.338c-0.191,0-0.345,0.155-0.345,0.347v1.251c0,0.093,0.039,0.186,0.108,0.252l8.666,8.188
                c0.064,0.062,0.149,0.095,0.239,0.095h3.406c0.098,0,0.192-0.043,0.259-0.116C30.28,27.599,30.313,27.499,30.301,27.402z'
            />
          </g>
        </g>
      </svg>
    );
  }
}
