import { PolicyEventType } from './policy-event-type';
import { NetworkEvent } from '../network-event';
import { Event } from '../event';

import { MemberCreatedEvent, MemberCreatedDisplayEvent } from './member-created-event';
import { MemberUpdatedEvent, MemberUpdatedDisplayEvent } from './member-updated-event';
import { MemberArchivedEvent, MemberArchivedDisplayEvent } from './member-archived-event';
import { Member } from '../../../../members/domain/member';

export class MemberEventFactory {
  public static fromNetwork(event: NetworkEvent, entity: Member) {
    switch (event.type) {
      case PolicyEventType.MemberCreated:
        return new MemberCreatedEvent(event as any, entity);
      case PolicyEventType.MemberUpdated:
        return new MemberUpdatedEvent(event as any, entity);
      case PolicyEventType.MemberArchived:
        return new MemberArchivedEvent(event as any, entity);
      default:
        return null;
    }
  }

  public static toDisplayEvent(event: Event<any>) {
    switch (event.type) {
      case PolicyEventType.MemberCreated:
        return MemberCreatedDisplayEvent;
      case PolicyEventType.MemberUpdated:
        return MemberUpdatedDisplayEvent;
      case PolicyEventType.MemberArchived:
        return MemberArchivedDisplayEvent;
      default:
        return null;
    }
  }
}
