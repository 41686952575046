import * as React from 'react';

export const InsuranceLoadingPage: React.SFC = () => (
  <div
    style={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
    }}
  >
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 180 180' width='50' height='46' style={{ margin: 'auto' }}>
      <path
        d='M176.54,0H3.46A3.46,3.46,0,0,0,0,3.46V176.54A3.46,3.46,0,0,0,3.46,
        180h6.92a3.46,3.46,0,0,0,3.47-3.46V13.85h152.3V158.63l-40.94-40.94h16.71a3.46,
        3.46,0,0,0,3.46-3.46V38.08a3.46,3.46,0,0,0-3.46-3.46H38.08a3.46,3.46,0,0,0-3.46,
        3.46V176.54A3.46,3.46,0,0,0,38.08,180H45a3.46,3.46,0,0,0,
        3.46-3.46V48.46h83.08v55.39H107.31a3.46,3.46,0,0,0-3.46,3.46v6.92a4.54,4.54,
        0,0,0,1.78,3.46l58.83,58.83c1.44,1.44,3.4,3.48,5.13,3.48h6.95a3.46,3.46,0,0,
        0,3.46-3.46V3.46A3.46,3.46,0,0,0,176.54,0ZM83.08,125.94V83.08h8.79a13.85,
        13.85,0,1,0,0-13.85H72.69a3.46,3.46,0,0,0-3.46,3.46v55.47c0,3.6.55,4.06,
        1.69,5.2L116.55,179a3.43,3.43,0,0,0,2.25,1h11.87a2.59,2.59,0,0,0,2.6-2.6c0-1.22-.84-2.05-1.71-2.93Z'
        style={{ fill: '#4078fa' }}
      />
    </svg>
  </div>
);
