import React from 'react';
import { inject, observer } from 'mobx-react';
import { RequestedBy, CreatedBy, CreatedByType } from '../../created-by';
import { Transition } from '../../../../components';
import { User } from '../../../users/domain/user';
import { UsersListStore } from '../../../management/users/users/stores/users-list-store';

interface EventUserProps {
  requestedBy: RequestedBy | CreatedBy;
  user?: User;
  prefix?: string;
}

interface Injected extends EventUserProps {
  usersListStore: UsersListStore;
}

@inject('usersListStore')
@observer
export class EventUser extends React.Component<EventUserProps> {
  get injected() {
    return this.props as Injected;
  }

  render() {
    if (this.props.requestedBy.type === CreatedByType.System) {
      return <span>{this.props.prefix} System</span>;
    }

    if (
      this.props.requestedBy.type === CreatedByType.ApiKey ||
      this.props.requestedBy.type === CreatedByType.LegacyApiKey
    ) {
      return <span>{this.props.prefix} API</span>;
    }

    const { usersListStore } = this.injected;
    const user = usersListStore.users.find((user) => user.userId === this.props.requestedBy.id);

    return (
      <Transition width={40} in>
        {this.props.prefix} {user ? user.firstName : ''} {user ? user.lastName : ''}
      </Transition>
    );
  }
}
