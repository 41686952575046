export enum ClaimEventType {
  ClaimImported = 'claim_imported',
  ClaimOpened = 'claim_opened',
  ClaimUpdated = 'claim_updated',
  ClaimFinalized = 'claim_finalized',
  ClaimClosed = 'claim_closed',
  ClaimReopened = 'claim_reopened',

  ClaimCause = 'claim_cause',
  ClaimType = 'claim_type',
  ClaimantUpdated = 'claimant_updated',

  ClaimApproved = 'claim_approved',
  ClaimApprovalAcknowledged = 'claim_approval_acknowledged',
  ClaimApprovalNotAcknowledged = 'claim_approval_not_acknowledged',

  ClaimNoClaim = 'claim_no_claim',
  ClaimNoClaimAcknowledged = 'claim_no_claim_acknowledged',
  ClaimNoClaimNotAcknowledged = 'claim_no_claim_not_acknowledged',

  ClaimRepudiated = 'claim_repudiated',
  ClaimRepudiationAcknowledged = 'claim_repudiation_acknowledged',
  ClaimRepudiationNotAcknowledged = 'claim_repudiation_not_acknowledged',

  ClaimGoodwill = 'claim_goodwill',
  ClaimGoodwillAcknowledged = 'claim_goodwill_acknowledged',
  ClaimGoodwillNotAcknowledged = 'claim_goodwill_not_acknowledged',

  ClaimPayoutRequested = 'claim_payout_requested',
  ClaimPayoutPaid = 'claim_payout_paid',

  ClaimSentToReview = 'claim_sent_to_review',
}
