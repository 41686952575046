import React from 'react';
import { Button, ButtonProps, ButtonGroup as ReactstrapButtonGroup } from 'reactstrap';

export interface CustomButtonProps extends ButtonProps {
  small?: boolean;
  inline?: boolean;
}

export interface PrimaryButtonProps extends CustomButtonProps {
  loading?: boolean;
  lean?: boolean;
}

export const DangerButton = (props: PrimaryButtonProps) => {
  const p = { ...props };
  delete p.inline;
  delete p.small;
  delete p.loading;
  if (props.loading) {
    return (
      <Button
        {...(p as any)}
        className={`btn-loading ${props.inline ? 'inline ' : ''}${props.className || ''}`}
        color='danger'
        size={props.small ? 'sm' : ''}
      >
        <span>{props.children}</span>
        <span className='loading-dots'>
          <span className='dot dot-one' />
          <span className='dot dot-two' />
          <span className='dot dot-three' />
        </span>
      </Button>
    );
  }

  return (
    <Button
      {...(p as any)}
      className={(props.inline ? 'inline ' : '') + (props.className || '')}
      color='danger'
      size={props.small ? 'sm' : ''}
    >
      {props.children}
    </Button>
  );
};

export const PrimaryButton = (props: PrimaryButtonProps) => {
  const p = { ...props };
  delete p.inline;
  delete p.small;
  delete p.loading;
  delete p.lean;

  if (props.loading) {
    return (
      <Button
        {...(p as any)}
        className={`btn-loading ${props.inline ? 'inline ' : ''} ${props.className || ''} ${
          props.lean ? 'button-lean' : ''
        }`}
        color='primary'
        size={props.small ? 'sm' : ''}
      >
        <span>{props.children}</span>
        <span className='loading-dots'>
          <span className='dot dot-one' />
          <span className='dot dot-two' />
          <span className='dot dot-three' />
        </span>
      </Button>
    );
  }

  return (
    <Button
      {...(p as any)}
      className={`${props.inline ? 'inline ' : ''} ${props.className || ''} ${props.lean ? 'button-lean' : ''}`}
      color='primary'
      size={props.small ? 'sm' : ''}
    >
      {props.children}
    </Button>
  );
};

export const BlankButton = (props: CustomButtonProps) => {
  const p = { ...props };
  delete p.inline;
  delete p.small;
  return (
    <Button
      {...(p as any)}
      className={(props.inline ? 'inline ' : '') + (props.className || '')}
      color='blank'
      size={props.small ? 'sm' : ''}
    >
      {props.children}
    </Button>
  );
};

export const LinkButton = (props: CustomButtonProps) => {
  const p = { ...props };
  delete p.inline;
  delete p.small;
  return (
    <Button
      {...(p as any)}
      className={(props.inline ? 'inline ' : '') + (props.className || '')}
      color='link'
      size={props.small ? 'sm' : ''}
    >
      {props.children}
    </Button>
  );
};

export const DefaultButton = (props: CustomButtonProps) => {
  const p = { ...props };
  delete p.inline;
  delete p.small;
  return (
    <Button
      {...(p as any)}
      className={(props.inline ? 'inline ' : '') + (props.className || '')}
      color='default'
      size={props.small ? 'sm' : ''}
    >
      {props.children}
    </Button>
  );
};

export const DocsButton = (props: CustomButtonProps) => {
  const p = { ...props };
  return (
    <Button {...(p as any)} className={props.className || ''} color='docs'>
      {props.children}
    </Button>
  );
};

export interface SubmitButtonProps extends CustomButtonProps {
  formValid?: boolean;
}

export class SubmitButton extends React.Component<SubmitButtonProps> {
  render() {
    return (
      <PrimaryButton type='submit' {...this.props} disabled={!this.props.formValid}>
        {this.props.children}
      </PrimaryButton>
    );
  }
}

export const ButtonGroup = ReactstrapButtonGroup;
