import { observable, action, makeObservable } from 'mobx';
import { camelToSnake } from 'insurance/utils';
import { User } from 'insurance/users/domain/user';
import { OrganizationUser } from '../../organizations/domain/organization-user';
import { organizationStore } from '../../organizations/stores/organizations-store';
import { productModuleListStore } from '../../product-modules/stores/product-module-list-store';
import { LoginSuccess, LoginTwoFARequired, OTPType, LoginError } from '../domain/login-result';
import { AccountApi } from '../account-api';
import { Context } from '../../framework';
import { clearLocalStorage } from '../../utils';
import { Organization } from '../../general';

interface UserNotifications {
  [k: string]: string | boolean;
}
export class AccountStore {
  @observable error = '';
  @observable isLoaded = false;
  @observable userNotifications: UserNotifications = {};
  @observable loginType?: OTPType;

  loginToken = '';
  selfOnCurrentOrg: OrganizationUser;

  @action init = async (params: { loginToken: string; isLoaded?: boolean }) => {
    const { loginToken, isLoaded } = params;

    this.isLoaded = !!isLoaded;
    this.loginToken = loginToken;
    if (Context.organizationId) {
      await organizationStore.getUserOrganizations({ isLoaded });
      await this.initializeUserForOrg({ isLoaded });
    }
    this.isLoaded = true;
  };

  setError = (error: string) => {
    this.error = error;
  };

  @action initializeUserForOrg = async (params: { isLoaded?: boolean }) => {
    this.isLoaded = !!params.isLoaded;

    try {
      this.isLoaded ? await productModuleListStore.load() : productModuleListStore.updateFromPusher();
      this.isLoaded = true;
    } catch (error) {
      this.isLoaded = true;
    }
  };

  @action forgotPassword = async (params: { email: string }) => {
    const { email } = params;
    this.isLoaded = false;
    try {
      const result = await AccountApi.forgotPassword({
        email,
      });

      if (result && result.error) {
        return result.error;
      }
    } catch (error) {
      return error;
    }
  };

  @action resetPassword = async (params: { password: string; code?: string; appToken: string }) => {
    const { password, code, appToken } = params;
    this.isLoaded = false;
    try {
      const result = await AccountApi.resetPassword({
        password,
        code,
        appToken,
      });

      if (result.error && result.error.otp) {
        this.loginType = result.error.otp;
        return result.error;
      }
    } catch (error) {
      return error;
    }
    this.isLoaded = true;
    return null;
  };

  @action sendAppToken = async (params: { otpCode: string; loginToken: string }) => {
    const { otpCode, loginToken } = params;
    try {
      const result = await AccountApi.sendOtpToken({
        otpCode,
        loginToken,
      });
      return result;
    } catch (error) {
      return error;
    }
  };

  @action getUserNotifications = async (params: { loginToken?: string; userId?: string }) => {
    const { loginToken, userId } = params;
    try {
      if (loginToken && userId) {
        const result = await AccountApi.getUserNotifications({
          loginToken,
          userId,
        });
        this.userNotifications = result;
      }
      if (!loginToken) {
        window.location.href = '/';
      }
    } catch (error) {
      return error;
    }
  };

  @action updateUserNotifications = async (params: { loginToken?: string; data: any }) => {
    const { loginToken, data } = params;
    try {
      if (loginToken) {
        const result = await AccountApi.updateUserNotifications({
          loginToken,
          data,
        });
        this.userNotifications = result;
      }
      if (!loginToken) {
        window.location.href = '/';
        return undefined;
      }
    } catch (error) {
      return error;
    }

    return null;
  };

  @action updateUserTimezone = async (params: { loginToken?: string; data: any }) => {
    const { loginToken, data } = params;
    try {
      if (loginToken) {
        const user: User = JSON.parse(localStorage.getItem('user') || '{}');

        const result = AccountApi.updateUserTimezone({
          loginToken,
          data,
        }).then(() => {
          const updatedUserTimezone = { ...camelToSnake(user), timezone: data };
          localStorage.setItem('user', JSON.stringify(updatedUserTimezone));
        });

        return result;
      }

      if (!loginToken) {
        window.location.href = '/';
        return undefined;
      }
    } catch (error) {
      return error;
    }

    return null;
  };

  @action enabledAppOtp = async (params: { otpCode: string; appToken: string; loginToken: string }) => {
    const { otpCode, appToken, loginToken } = params;
    try {
      return AccountApi.enabledAppOtp({
        otpCode,
        appToken,
        loginToken,
      });
    } catch (error) {
      console.log('error');
      return error;
    }
  };

  @action disableAppOtp = async (params: { loginToken: string; OTPCode: string }) => {
    const { loginToken, OTPCode } = params;
    try {
      return AccountApi.disableAppOtp({
        loginToken,
        OTPCode,
      });
    } catch (error) {
      console.log('error');
      return error;
    }
  };

  @action changePassword = async (params: {
    oldPassword: string;
    newPassword: string;
    code: string;
    loginToken: string;
  }) => {
    const { code, newPassword, oldPassword, loginToken } = params;

    return AccountApi.changePassword({
      code: code || '',
      newPassword: newPassword || '',
      oldPassword: oldPassword || '',
      loginToken,
    });
  };

  isLoginExpired = () => {
    const loginTime = localStorage.getItem('login_time') as string;
    const loginToken = window.localStorage.getItem('login_token');

    // token already expired
    if (!loginTime || timeTooOld(new Date(loginTime), 45)) {
      clearLocalStorage();
      window.location.href = '/';
      return;
    }
    // token close to expiring
    if (timeTooOld(new Date(loginTime), 30)) {
      this.refreshLogin({
        oldToken: loginToken || undefined,
      });
    }
  };

  @action refreshLogin = async (params: { oldToken?: string }) => {
    const { oldToken } = params;
    if (!oldToken) {
      clearLocalStorage();
      return (window.location.href = '/');
    }

    const result = await AccountApi.refreshLogin({ oldToken });
    if (result instanceof LoginError) {
      clearLocalStorage();
      window.location.href = '/';
    }
    if (result instanceof LoginSuccess) {
      localStorage.setItem('login_token', result.loginToken);
      localStorage.setItem('login_time', new Date().toISOString());
      this.init({ loginToken: result.loginToken });
    }
  };

  @action tryLogin = async (params: { email: string; password: string; twoFACode?: string }) => {
    const result = await AccountApi.login(params);
    if (result instanceof LoginTwoFARequired) {
      this.loginType = result.type;
    }
    if (result instanceof LoginSuccess) {
      try {
        const init = await AccountApi.accountInit({
          loginToken: result.loginToken,
        });

        const organizations = await Promise.all(
          init.client_apps.map(async (c: any) => ({
            organizationId: c.client_id,
            productName: c.product_name,
            description: c.product_description,
            icon: c.product_icon,
            website: c.product_website,
            reviewed: c.reviewed,
            user: await AccountApi.getSelfForOrg({
              organizationId: c.client_id,
              loginToken: result.loginToken,
            }),
            enabledFeatures: (c.enabled_features || []).map((f: any) => ({
              featureKey: f.feature_key,
              organizationId: f.organization_id,
              sandbox: f.sandbox,
              production: f.production,
            })),
            timezone: c.timezone,
          })),
        );

        localStorage.setItem('login_token', result.loginToken);
        localStorage.setItem('login_time', new Date().toISOString());
        localStorage.setItem('first_time_login', init.first_time_login);
        localStorage.setItem('user_id', result.userId);
        localStorage.setItem('user_state', 'activated');
        localStorage.setItem('organizations', JSON.stringify(organizations || []));

        localStorage.setItem('user', JSON.stringify(init.user));
        localStorage.setItem('user_email', init.user.email);
      } catch (error) {
        return error;
      }
    }

    return result;
  };

  @action refreshClientAppFeatures = async () => {
    try {
      const loginToken = localStorage.getItem('login_token');
      if (!loginToken) {
        return false;
      }

      const clientApps = await AccountApi.getClientApps({ loginToken });
      const orgs = JSON.parse(localStorage.getItem('organizations') as string) as Organization[];

      for (const clientApp of clientApps) {
        const org = orgs.find((org) => org.organizationId === clientApp.client_id);
        if (org) {
          org.enabledFeatures = (clientApp.enabled_features || []).map((f: any) => ({
            featureKey: f.feature_key,
            organizationId: f.organization_id,
            sandbox: f.sandbox,
            production: f.production,
          }));
        }
      }

      localStorage.setItem('organizations', JSON.stringify(orgs));
      return true;
    } catch (error) {
      console.debug(error);
      return error;
    }
  };

  constructor() {
    makeObservable(this);
  }
}

function timeTooOld(date: Date, minutes: number) {
  const DELTA = 1000 * 60 * minutes;
  return Date.now() - date.getTime() >= DELTA;
}

export const accountStore = new AccountStore();
