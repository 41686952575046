import React, { ReactNode } from 'react';
import { Svg } from '../svg/svg';

interface FormOverlayProps {
  loading?: boolean;
  customStyles?: React.CSSProperties;
  className?: string;
}

export class FormOverlay extends React.Component<FormOverlayProps> {
  render() {
    return (
      <div
        className={`overlay-form ${this.props.className || ''} ${this.props.loading ? ' loading' : ''}`}
        style={this.props.customStyles}
      >
        {this.props.children}
      </div>
    );
  }
}

interface FormOverlayHeaderProps {
  close: () => void;
  customStyles?: React.CSSProperties;
  className?: string;
  extendedWidth?: boolean;
}

export class FormOverlayHeader extends React.Component<FormOverlayHeaderProps> {
  render() {
    return (
      <div className={`overlay-form-header ${this.props.className}`} style={{ ...this.props.customStyles }}>
        <div className='spacer-left' />
        <div
          className={`${
            this.props.extendedWidth ? 'overlay-form-inner-content-extended-width' : 'overlay-form-inner-content'
          }`}
        >
          <h3>
            {this.props.children}
            <span style={{ cursor: 'pointer' }}>
              <Svg onClick={this.props.close} href='/assets/images/icons/icon-24-px-cross.svg' />
            </span>
          </h3>
        </div>
        <div className='spacer-right' />
      </div>
    );
  }
}

interface FormOverlayContentProps {
  padded?: boolean;
  leftColumn?: ReactNode;
  rightColumn?: ReactNode;
  extendedWidth?: boolean;
}

export class FormOverlayContent extends React.Component<FormOverlayContentProps> {
  render() {
    return (
      <div className={`overlay-form-content${this.props.padded ? ' padded' : ''}`}>
        <div className='spacer-left'>{this.props.leftColumn}</div>
        <div
          className={`${
            this.props.extendedWidth ? 'overlay-form-inner-content-extended-width' : 'overlay-form-inner-content'
          }`}
        >
          {this.props.children}
        </div>
        <div className='spacer-right'>{this.props.rightColumn}</div>
      </div>
    );
  }
}

export class FormOverlayFooter extends React.Component<{
  id?: string;
  extendedWidth?: boolean;
}> {
  render() {
    return (
      <div className='overlay-form-footer' id={this.props.id}>
        <div className='spacer-left' />
        <div
          className={`${
            this.props.extendedWidth ? 'overlay-form-inner-content-extended-width' : 'overlay-form-inner-content'
          }`}
        >
          {this.props.children}
        </div>
        <div className='spacer-right' />
      </div>
    );
  }
}
