import styled from 'styled-components';
import { color } from 'rootstrap/global-styles';

export const MainSidebarStyle = styled.div<{ isActive: boolean; isTooling: boolean }>`
  display: grid;
  height: ${({ isTooling }) => (isTooling ? '45px' : '55px')};

  div {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }

  div a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }

  div span {
    font-size: 9px;
    text-align: center;
    margin-bottom: 5px;
    padding-top: 5px;
    cursor: pointer;
    color: ${({ isActive }) => (isActive ? color.rootWhite : color.emHigh)};
  }
`;
