export const fontFamily = {
  bodyFont: "'Lato', Arial, Helvetica, sans-serif",
  codeFont: "'Source Code Pro', 'Courier New', Courier, monospace",
};

export enum FontSize {
  Body = '14px',
  Caption = '12px',
  TableColumnHeading = '12px',
  BodyLarge = '16px',
}
