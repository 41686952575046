import { withRouter } from 'react-router-dom';
import { Event, DisplayEvent } from '../event';

export class ApplicationUpdatedEvent extends Event<any> {}

class ApplicationUpdatedDisplayEventImpl extends DisplayEvent {
  getTitle = () => 'Application updated';
  renderFull = () => null;
}

export const ApplicationUpdatedDisplayEvent = withRouter(ApplicationUpdatedDisplayEventImpl as any);
