import * as React from 'react';
import { Event, DisplayEvent } from '../event';
import { ClaimEventType } from './claim-event-type';
import { CreatedByType } from '../../../created-by';

export class ClaimNoClaimNotAcknowledgedEvent extends Event<any> {
  public static New(reason: string, claim: any) {
    return new ClaimNoClaimNotAcknowledgedEvent(
      {
        type: ClaimEventType.ClaimNoClaimNotAcknowledged,
        requestedBy: {
          type: CreatedByType.System, // TODO
        },
        reason,
      } as any,
      claim,
    );
  }
}

export class ClaimNoClaimNotAcknowledgedDisplayEvent extends DisplayEvent {
  getTitle = () => 'No claim not acknowledged';
  renderFull = () => (
    <div>
      Reason: <br />
      {(this.props.event as any).reason}{' '}
    </div>
  );
}
