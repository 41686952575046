import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { TwoFactorAuthentication } from './2fa';
import { Password } from './password';
import { SmsOtpPage } from './sms-otp';
import { AccountApi } from '../account-api';
import { AccountStore } from '../stores/account-store';
import SinglePageCardLayout from './single-page-card-layout';
import { Transition } from '../../../components';
import { clearLocalStorage } from '../../utils';

interface Props extends RouteComponentProps {
  match: {
    path: string;
    url: string;
    isExact: boolean;
    params: {
      token: string;
    };
  };
}

interface Injected extends Props {
  accountStore: AccountStore;
}

interface State {
  email?: string;
  name?: string;
  password?: string;
  userId?: string;
  noCard?: boolean;
  step: number;
  loading?: boolean;
  otpType?: string;
  appToken?: string;
  cardActivatorId?: string;
  error?: string;
}

@inject('accountStore')
@observer
class ActivateContainerImpl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const token = this.props.match?.params?.token;
    let dataJSON;
    let data;

    try {
      dataJSON = window.atob(token);
      data = JSON.parse(dataJSON);
    } catch (error) {
      window.location.href = '/';
    }
    const { user_id, email, card_activator_id } = data;

    this.state = {
      userId: user_id,
      email,
      cardActivatorId: card_activator_id,
      otpType: 'app',
      step: 1,
      loading: true,
    };
  }

  get injected() {
    return this.props as Injected;
  }

  async componentDidMount() {
    const { email } = this.state;
    const { userId } = this.state;
    const loginToken = this.props.match.params.token;

    if (!loginToken) {
      return (window.location.href = '/');
    }

    const result = await AccountApi.status({
      email,
      userId,
      loginToken,
    });

    if (result.error) {
      if (result && result.type === 'user_activated') {
        clearLocalStorage();
        return (window.location.href = '/?error=user_activated');
      }
      clearLocalStorage();
      return (window.location.href = '/');
    }

    this.setState({ name: result.name, step: 1, loading: false });
  }

  login = async (code: string) => {
    const { accountStore } = this.injected;

    const { email } = this.state;
    const { password } = this.state;

    this.setState({ loading: true });

    if (email && password) {
      const result = await accountStore.tryLogin({
        email,
        password,
        twoFACode: code,
      });

      if (result.message) {
        return this.setState({ error: result.message, loading: false });
      }
      this.setState({ error: '', loading: false }, () => {
        this.nextStep();
      });
    }
  };

  nextStep = () => {
    const nextStep = this.state.step + 1;
    if (nextStep === 3 || nextStep === 4) {
      window.location.href = this.state.noCard ? '/organizations' : '/';
    } else {
      this.setState({ step: nextStep });
    }
  };

  previousStep = () => {
    this.setState({ step: this.state.step - 1 });
  };

  renderEmptyRow() {
    return (
      <div>
        <tbody>
          <tr style={{ padding: 30, height: 60 }}>
            <td style={{ height: 60 }}>
              <Transition in={false} width={800} />
            </td>
          </tr>
        </tbody>
      </div>
    );
  }

  render() {
    const { step } = this.state;
    if (this.state.loading) {
      return (
        <SinglePageCardLayout>
          {this.renderEmptyRow()}
          {this.renderEmptyRow()}
          {this.renderEmptyRow()}
        </SinglePageCardLayout>
      );
    }

    if (step === 1) {
      return (
        <SinglePageCardLayout>
          <Password
            loginToken={this.props.match.params.token}
            nextStep={this.nextStep}
            setState={this.setState.bind(this)}
            userId={this.state.userId}
            email={this.state.email}
            name={this.state.name}
          />
        </SinglePageCardLayout>
      );
    }

    if (step === 2) {
      if (this.state.otpType === 'app') {
        return (
          <SinglePageCardLayout>
            <TwoFactorAuthentication
              loginToken={this.props.match.params.token}
              nextStep={this.nextStep}
              previousStep={this.previousStep}
              appToken={this.state.appToken}
              setState={this.setState.bind(this)}
              userId={this.state.userId}
              login={this.login}
            />
          </SinglePageCardLayout>
        );
      }

      return (
        <SinglePageCardLayout>
          <SmsOtpPage
            nextStep={this.nextStep}
            loginToken={this.props.match.params.token}
            previousStep={this.previousStep}
            appToken={this.state.appToken}
            setState={this.setState.bind(this)}
            userId={this.state.userId}
            email={this.state.email}
            login={this.login}
            error={this.state.error}
            isLoadingNext={this.state.loading}
          />
        </SinglePageCardLayout>
      );
    }
    return <div>Loading</div>;
  }
}

export const ActivateContainer = withRouter(ActivateContainerImpl as any);
