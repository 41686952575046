import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { FormElement, FormElementProps, FormElementState, FormElementLifecycle } from './form-element';

export type CheckboxValue = boolean;

export type CheckboxProps = FormElementProps<CheckboxValue>;

export class Switch extends FormElement<CheckboxValue, CheckboxProps, FormElementState<CheckboxValue>> {
  render() {
    return (
      <FormGroup key={this.props.name} className='switch-form-group'>
        <div className='switch-label'>{this.props.label}</div>
        {this._render()}
      </FormGroup>
    );
  }

  protected _render() {
    return (
      <div
        className='switch-container'
        onClick={() => {
          this.valueChanged(!this.state.value);
        }}
      >
        <div className={`switch-button ${this.state.value === true ? 'active' : ''}`} />
      </div>
    );
  }

  protected getValueFromProps(props: CheckboxProps): CheckboxValue {
    return props.defaultValue || false;
  }
}

export interface SwitchGroupProps extends FormElementLifecycle {
  label: string;
  children: any;
}

export const SwitchGroup = (props: SwitchGroupProps) => {
  const childrenWithProps = React.Children.map(props.children, (child: React.ReactElement<FormElementProps<any>>) => {
    if (typeof child.type === 'string') {
      return child;
    }

    const newProps = { ...props };
    delete newProps.label;
    delete newProps.children;

    return React.cloneElement(child, newProps);
  });

  return (
    <FormGroup>
      <Label>{props.label}</Label>
      {childrenWithProps}
    </FormGroup>
  );
};
