import * as React from 'react';
import { Modal, ModalHeader, ModalBody, LinkButton } from '../../../../../components';
import { SmsNotification } from './notification-created-event';

export interface ViewSmsModalProps {
  notification: SmsNotification;
  title: string;
  close: () => void;
}

export class ViewSmsModal extends React.Component<ViewSmsModalProps, {}> {
  render() {
    const to = this.props.notification.to.join(', ');
    return (
      <Modal isOpen toggle={this.props.close} className='sms-modal'>
        <ModalHeader toggle={this.props.close}>{this.props.title}</ModalHeader>
        <ModalBody style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ flex: '0 0 auto', marginBottom: '10px' }}>
            <table className='summary-table no-hover full-width compressed'>
              <tbody>
                <tr>
                  <td>
                    <b>To:</b>
                  </td>
                  <td>{to}</td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
          </div>
          <div className='sms-modal-content'>{this.props.notification.content}</div>
        </ModalBody>
      </Modal>
    );
  }
}

export interface SmsViewProps {
  notification: SmsNotification;
  title: string;
  linkText?: string;
}

export interface SmsViewState {
  modalOpen: boolean;
}

export class SmsView extends React.Component<SmsViewProps, SmsViewState> {
  constructor(props: SmsViewProps) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  openModal = () => {
    this.setState({ modalOpen: true });
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    return (
      <div>
        <LinkButton onClick={this.openModal}>{this.props.linkText || 'View SMS'}</LinkButton>{' '}
        {this.state.modalOpen && <ViewSmsModal close={this.closeModal} {...this.props} />}
      </div>
    );
  }
}
