/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { MainSidebarStyle } from './styles/main-sidebar-style';

export const MainSidebar = (props: { children: JSX.Element | JSX.Element[] }) => {
  return (
    <div className='main-sidebar'>
      <div className='main-sidebar-icon'>
        <a href='/organizations'>
          <img src='/assets/images/root-icon.svg' alt='' />
        </a>
      </div>
      <div className='main-sidebar-content'>{props.children}</div>
    </div>
  );
};

export interface MainSidebarLinkProps {
  icon: string;
  name: string;
  href: string;
  fallbackHref?: string[];
  iconWithName?: boolean;
}

export const MainSidebarLink = (props: MainSidebarLinkProps) => {
  const { href, name, icon, fallbackHref } = props;
  const history = useHistory();
  const { pathname } = history.location;
  const pathnameFromHref = href.split('?')[0];
  const startsWith = pathname.startsWith(pathnameFromHref);
  const isActive = startsWith || (fallbackHref && fallBackIncludes({ fallbackHref, pathname }));
  const [iconSize, setIconSize] = useState<number>(20);
  const isTooling = ['data-management', 'workbench', 'organization'].some((str) => pathnameFromHref.endsWith(str));

  const onMouseOver = () => {
    setIconSize(24);
  };

  const onMouseLeave = () => {
    setIconSize(20);
  };

  return (
    <MainSidebarStyle isActive={!!isActive} isTooling={isTooling}>
      <div>
        <Link to={href}>
          <img
            width={iconSize}
            height={iconSize}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            src={icon}
            style={{ display: isActive ? 'none' : 'block' }}
            alt=''
          />
          <img
            width={iconSize}
            height={iconSize}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            style={{ display: isActive ? 'block' : 'none' }}
            src={icon.replace('.svg', '-active.svg')}
            alt=''
          />
        </Link>
        {props.iconWithName && <span onClick={() => history.push(href)}>{name}</span>}
      </div>
    </MainSidebarStyle>
  );
};

const fallBackIncludes = (params: { fallbackHref?: string[]; pathname: string }) => {
  const { fallbackHref, pathname } = params;
  if (!fallbackHref) return false;

  return !!fallbackHref.some((href) => pathname.startsWith(href));
};

export const MainSidebarSpacer = () => <div className='main-sidebar-spacer' />;
