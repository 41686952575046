import { Construct } from '../../models/construct';

export class OrganizationUser {
  readonly userId: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly cellphone: string;

  constructor(init: Construct<OrganizationUser>) {
    Object.assign(this, init);
  }

  static fromNetwork(init: NetworkOrganizationUser) {
    return new OrganizationUser({
      userId: init.user_id,
      firstName: init.first_name,
      lastName: init.last_name,
      email: init.email,
      cellphone: init.cellphone,
    });
  }
}

export interface NetworkOrganizationUser {
  readonly user_id: string;
  readonly first_name: string;
  readonly last_name: string;
  readonly email: string;
  readonly cellphone: string;
}
