import React from 'react';
import { FormValuesWithKeys } from './form-keyed';
import { FormMultiInput, MultiInputType, MultiInputStep, MultiInput } from './form-multi-input';
import { FormElementFactory } from './form-element-factory';

export enum PremiumType {
  OnceOff = 'Once off',
  Monthly = 'Monthly premium',
}

export interface InputTemplateEntry {
  heading?: string;
  showAsAssuredInSummary?: {
    label: string;
    fields: string[];
    showIf?: string;
  };
  input: {
    [key: string]: FormMultiInput;
  };
}

interface SectionElements {
  sectionName: string;
  section: InputTemplateEntry;
  sectionIndex: number;
}

interface InputElements {
  inputName: string;
  input: FormMultiInput;
  inputIndex: number;
}

interface FilteredElements extends SectionElements, InputElements {}

export class InputTemplate {
  entries: { [key: string]: InputTemplateEntry };
  premiumType: PremiumType;

  constructor(premiumType: PremiumType, init: { [key: string]: InputTemplateEntry }) {
    this.entries = init;
    this.premiumType = premiumType;
  }

  getDefaultFormValues() {
    const values = new FormValuesWithKeys();

    this.forAll((inputElement) => {
      if (inputElement.input.type === MultiInputType.Multi) {
        const { schema } = inputElement.input;
        if (!schema) {
          throw new Error('An error occurred');
        }
        values.add(
          inputElement.inputName,
          inputElement.input.defaultValue || [
            new FormValuesWithKeys(
              schema.map((multiInput) => {
                return {
                  name: multiInput.name,
                  value: multiInput.defaultValue,
                };
              }),
            ),
          ],
        );
      } else {
        values.add(inputElement.inputName, inputElement.input.defaultValue || undefined);
      }
    });
    return values;
  }

  forEachSection = (cb: (sectionElement: SectionElements) => void) => {
    let index = 0;
    for (const sectionName in this.entries) {
      cb({
        sectionName,
        section: this.entries[sectionName],
        sectionIndex: index++,
      });
    }
  };

  forEachInput = (section: InputTemplateEntry, cb: (inputElement: InputElements) => void) => {
    const sectionInputs = section.input;
    let index = 0;
    for (const inputName in sectionInputs) {
      cb({
        inputName,
        input: sectionInputs[inputName],
        inputIndex: index++,
      });
    }
  };

  forAll = (cb: (item: FilteredElements) => void) => {
    this.forEachSection((sectionElement: SectionElements) => {
      this.forEachInput(sectionElement.section, (inputElement: InputElements) => {
        cb({
          ...sectionElement,
          ...inputElement,
        });
      });
    });
  };

  mapAll<T>(cb: (item: FilteredElements) => T) {
    const result: T[] = [];

    this.forAll((filteredElement) => {
      result.push(cb(filteredElement));
    });

    return result;
  }

  filterAll = (cb: (item: FilteredElements) => boolean) => {
    const result: FilteredElements[] = [];

    this.forAll((item) => {
      if (cb(item)) {
        result.push(item);
      }
    });

    return result;
  };

  getElementsForStep = (step: MultiInputStep) => {
    return this.filterAll((item) => item.input.inputStep === step || item.input.inputStep === MultiInputStep.All);
  };

  isSchemaRequiredIfObject = (value: any): value is RequiredIfObject => {
    return !!value.path && !!value.equals;
  };

  getElementsToRender = (elements: FilteredElements[], values: any) => {
    return elements.filter((e) => {
      const reqIf = e.input.requiredIf;
      if (reqIf) {
        if (this.isSchemaRequiredIfObject(reqIf)) {
          const value = values.value(reqIf.path);
          return reqIf.equals === value;
        }

        if (!values.value(reqIf)) {
          return false;
        }
      }

      return true;
    });
  };

  getElementsForSummary = (values: any) => {
    const elements = this.getElementsToRender(
      this.filterAll(() => true),
      values,
    );
    const groupedElements: {
      [key: string]: {
        section: InputTemplateEntry;
        singular: FilteredElements[];
        multiple: FilteredElements[];
      };
    } = {};

    for (const element of elements) {
      if (!groupedElements[element.sectionName]) {
        groupedElements[element.sectionName] = {
          section: element.section,
          singular: [],
          multiple: [],
        };
      }

      if (element.input.type === MultiInputType.Multi) {
        groupedElements[element.sectionName].multiple.push(element);
      } else {
        groupedElements[element.sectionName].singular.push(element);
      }
    }

    return Object.keys(groupedElements).map((key) => groupedElements[key]);
  };

  renderFor(step: MultiInputStep, values: FormValuesWithKeys, currency = 'ZAR') {
    const elementsForStep = this.getElementsForStep(step);
    const elementsToRender = this.getElementsToRender(elementsForStep, values);

    const renderedSections: any = {};

    return elementsToRender
      .map((e, i) => {
        const autoFocus = i === 0;
        const element = FormElementFactory.mapToFormElement(
          step,
          values,
          e.inputName,
          e.input,
          autoFocus,
          e.input.required,
          currency,
        );

        if (e.section.heading && !renderedSections[e.section.heading]) {
          renderedSections[e.section.heading] = true;
          return [
            <h5
              key={`heading${e.sectionName}`}
              style={{
                marginBottom: '16px',
                marginTop: '32px',
                fontWeight: 'bold',
              }}
            >
              {e.section.heading}
            </h5>,
            element,
          ];
        }

        return [element];
      })
      .reduce((a, b) => a.concat(...b), []);
  }

  getValuesForStep(step: MultiInputStep, values: FormValuesWithKeys) {
    const elementsForStep = this.getElementsForStep(step);
    const elementsToRender = this.getElementsToRender(elementsForStep, values);

    const valuesForStep: any = {};

    elementsToRender.forEach((e) => {
      valuesForStep[e.inputName] = values.value(e.inputName);
    });

    return valuesForStep;
  }

  getPrettyValuesForStep(step: MultiInputStep, values: FormValuesWithKeys) {
    const elementsForStep = this.getElementsForStep(step);
    const elementsToRender = this.getElementsToRender(elementsForStep, values);

    const valuesForStep: any = {};

    elementsToRender.forEach((e) => {
      if (e.input.currency) {
        valuesForStep[e.inputName] = parseInt(values.value(e.inputName), 10) * 100;
      } else if (e.input.type === MultiInputType.Multi) {
        const formValues = (values.value(e.inputName) as FormValuesWithKeys[]).map((v) => {
          const toSubmit: any = {};
          for (const element of (e.input.schema as MultiInput[]).filter(
            (x) => x.inputStep === step || x.inputStep === MultiInputStep.All,
          )) {
            toSubmit[element.name] = v.prettyValue(v.value(element.name));
          }
          return toSubmit;
        });

        if (e.input.requireAtLeast === 1 && e.input.requireAtMost === 1) {
          [valuesForStep[e.inputName]] = formValues;
        } else {
          valuesForStep[e.inputName] = formValues;
        }
      } else {
        valuesForStep[e.inputName] = values.prettyValue(values.value(e.inputName));
      }
    });

    return valuesForStep;
  }

  isValid(step: MultiInputStep, values: FormValuesWithKeys) {
    const elementsForStep = this.getElementsForStep(step);
    const elementsToRender = this.getElementsToRender(elementsForStep, values);

    for (const element of elementsToRender) {
      if (!values.valid(element.inputName)) {
        return false;
      }
    }

    return true;
  }
}

interface RequiredIfObject {
  path: string;
  equals: string;
}
