import React from 'react';
import { Event, DisplayEvent } from '../event';

export class PolicyholderUpdatedEvent extends Event<any> {}

export class PolicyholderUpdatedDisplayEvent extends DisplayEvent {
  getTitle = () => 'Policyholder updated';
  renderFull = () => {
    const event = this.props.event as any;
    return (
      <div>
        <table className='summary-table no-hover full-width compressed'>
          <tbody>
            {event.companyName && (
              <tr>
                <td>Company name</td>
                <td>{event.companyName}</td>
              </tr>
            )}
            <tr>
              <td>First name</td>
              <td>{event.firstName}</td>
            </tr>
            <tr>
              <td>Middle name</td>
              <td>{event.middleName || '-'}</td>
            </tr>
            <tr>
              <td>Last name</td>
              <td>{event.lastName}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{event.email}</td>
            </tr>
            <tr>
              <td>Cellphone</td>
              <td>{event.cellphone || 'Not set'}</td>
            </tr>
            {event.phoneOther && (
              <tr>
                <td>Alternative number</td>
                <td>{event.phoneOther?.number}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}
