import { ValidatorResult, Validator } from './types';
import { FormValues } from '../form';

export class MultiValuesValidator<T extends FormValues[]> implements Validator<T> {
  constructor(private readonly name: string) {}

  validate(values: T) {
    if (values === undefined || !Array.isArray(values)) {
      return { valid: true };
    }

    let isValid = true;
    for (const item of values) {
      for (const key in item.values) {
        isValid = isValid && item.values[key].valid;
        if (!isValid) {
          break;
        }
      }
      if (!isValid) {
        break;
      }
    }

    const result: ValidatorResult = { valid: isValid };

    if (!result.valid) {
      result.message = `${this.name} is not valid.`;
    }

    return result;
  }
}
