import * as React from 'react';
import { MainLayout, MainSidebar, MainSidebarSpacer, LinkButton } from '../../components';

const ErrorPageContainer = (props: any) => (
  <div
    style={{
      width: '45%',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    {props.children}
  </div>
);

const HeadingContainer = (props: any) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    }}
  >
    {props.children}
  </div>
);

const HeadingText = (props: any) => (
  <p
    style={{
      lineHeight: '32px',
      fontWeight: 300,
      color: '#3d3d3d',
      fontSize: '28px',
      margin: 0,
    }}
  >
    {props.children}
  </p>
);

const ErrorDescription = (props: any) => (
  <div
    style={{
      width: '100%',
      background: '#f6f6f6',
      padding: '24px',
      borderRadius: '4px',
      marginTop: '60px',
    }}
  >
    {props.children}
  </div>
);

const ErrorActions = (props: any) => <ul style={{ lineHeight: '20px', margin: 0 }}>{props.children}</ul>;

const ErrorCode = (props: any) => <p style={{ fontSize: '12px', marginBottom: '30px' }}>{props.children}</p>;

const WhatYouCanDo = () => <p style={{ fontWeight: 'bold' }}>Here&#39;s what you can do:</p>;

export const Page500 = () => {
  return (
    <MainLayout>
      <MainSidebar>
        <MainSidebarSpacer />
      </MainSidebar>
      <ErrorPageContainer>
        <HeadingContainer>
          <div>
            <HeadingText>We&#39;re sorry,</HeadingText>
            <HeadingText>but something went wrong.</HeadingText>
          </div>
          <img src='/assets/images/empty/small-empty-claims.png' alt='' />
        </HeadingContainer>
        <ErrorDescription>
          <ErrorCode>ERROR CODE: 500</ErrorCode>
          <WhatYouCanDo />
          <ErrorActions>
            <li>
              <LinkButton href='' onClick={() => window.location.reload()}>
                Refresh
              </LinkButton>{' '}
              your page to try again.
            </li>
            <li>
              Email <a href='mailto:support@root.co.za'>support@root.co.za</a> and tell us what you were trying to do.
            </li>
          </ErrorActions>
        </ErrorDescription>
      </ErrorPageContainer>
    </MainLayout>
  );
};

export const Page404 = () => {
  return (
    <MainLayout>
      <ErrorPageContainer>
        <HeadingContainer>
          <div>
            <HeadingText>We couldn&#39;t find the page</HeadingText>
            <HeadingText>you&#39;re looking for.</HeadingText>
          </div>
          <img src='/assets/images/empty/small-empty-claims.png' alt='' />
        </HeadingContainer>
        <ErrorDescription>
          <ErrorCode>ERROR CODE: 404</ErrorCode>
          <WhatYouCanDo />
          <ErrorActions>
            <li>Check that your url is correct.</li>
            <li>
              Go{' '}
              <LinkButton
                href=''
                onClick={(event: any) => {
                  event.preventDefault();
                  window.history.go(-1);
                }}
              >
                back
              </LinkButton>{' '}
              to the previous page.
            </li>
            <li>
              Head to <a href='/'>the beginning</a> and start again.
            </li>
          </ErrorActions>
        </ErrorDescription>
      </ErrorPageContainer>
    </MainLayout>
  );
};

export const Page403 = () => {
  return (
    <MainLayout>
      <ErrorPageContainer>
        <HeadingContainer>
          <div>
            <HeadingText>You do not have permission</HeadingText>
            <HeadingText>to access this page.</HeadingText>
          </div>
          <img src='/assets/images/empty/small-empty-claims.png' alt='' />
        </HeadingContainer>
        <ErrorDescription>
          <ErrorCode>ERROR CODE: 403</ErrorCode>
          <WhatYouCanDo />
          <ErrorActions>
            <li>Check that your url is correct.</li>
            <li>
              Go{' '}
              <LinkButton
                href=''
                onClick={(event: any) => {
                  event.preventDefault();
                  window.history.go(-1);
                }}
              >
                back
              </LinkButton>{' '}
              to the previous page.
            </li>
            <li>
              Head to <a href='/'>the beginning</a> and start again.
            </li>
          </ErrorActions>
        </ErrorDescription>
      </ErrorPageContainer>
    </MainLayout>
  );
};
