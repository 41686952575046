import { Title } from 'insurance/general/title';
import { Moment } from 'moment-timezone';
import { Construct } from '../../models/construct';

export enum CoveredPersonRelationship {
  MainMember = 'main_member',
  Spouse = 'spouse',
  Parent = 'parent',
  Son = 'son',
  Daughter = 'daughter',
  AuntOrUncle = 'aunt_or_uncle',
  Grandparent = 'grandparent',
  CousinRelative = 'cousin_or_relative',
  Brother = 'brother',
  Sister = 'sister',
  Employer = 'employer',
  Policyholder = 'policyholder',
  SonInLaw = 'son_in_law',
  DaughterInLaw = 'daughter_in_law',
  MotherInLaw = 'mother_in_law',
  FatherInLaw = 'father_in_law',
  Grandchild = 'grandchild',
  NieceOrNephew = 'niece_or_nephew',
  Other = 'other',
}

export enum IdentificationType {
  Id = 'id',
  Passport = 'passport',
  Custom = 'custom',
  Email = 'email',
  Cellphone = 'cellphone',
}

export interface Identification {
  type: IdentificationType;
  number: string;
  country: string;
  expirationDate?: Moment;
}

export class PolicyCoveredPeople {
  id?: Identification;
  firstName?: string;
  lastName?: string;
  cellphone?: string;
  email?: string;
  relationship: CoveredPersonRelationship;
  dateOfBirth?: string;
  initials?: string;
  title?: Title;

  constructor(init: Construct<PolicyCoveredPeople>) {
    Object.assign(this, init);
  }

  public static fromNetwork(init: NetworkPolicyCoveredPerson) {
    return new PolicyCoveredPeople({
      id: init.id,
      firstName: init.first_name,
      lastName: init.last_name,
      cellphone: init.cellphone,
      email: init.email,
      relationship: init.relationship,
      dateOfBirth: init.date_of_birth,
      initials: init.initials,
      title: init.title,
    });
  }
}

export interface NetworkPolicyCoveredPerson {
  id?: NetworkIdentification;
  first_name?: string;
  last_name?: string;
  cellphone?: string;
  email?: string;
  relationship: CoveredPersonRelationship;
  date_of_birth?: string;
  initials?: string;
  title?: Title;
}
interface NetworkIdentification {
  type: IdentificationType;
  number: string;
  country: string;
}
