import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { OverlayTrigger, Popover, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { Form, Text, PrimaryButton, FormValues, FormGroupHalf } from '../components';
import { buttonText } from '../insurance/utils';
import { checkPassword } from '../insurance/account/utils';
import { AccountStore } from '../insurance/account/stores/account-store';
import { Alert } from '../components/alerts/alerts';

interface Props extends RouteComponentProps {
  onSubmit: (params: { password: string }) => void;
}

interface Injected extends Props {
  accountStore: AccountStore;
}

interface LoginFormData {
  password: string;
  confirmPassword: string;
}

interface State {
  loading: boolean;
  showForgotPassword: boolean;
  form: LoginFormData;
  valid: boolean;
  touched: boolean;
  errorMessage?: string;
}

@inject('accountStore')
@observer
export class ChangePasswordImpl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      form: {
        password: '',
        confirmPassword: '',
      },
      valid: false,
      loading: false,
      showForgotPassword: false,
      touched: false,
    };
  }

  get injected() {
    return this.props as Injected;
  }

  submit = async () => {
    const { form, valid } = this.state;

    this.setState({ loading: true });
    if (!valid) {
      return this.setState({
        touched: true,
        loading: false,
      });
    }

    const { password } = form;
    const { confirmPassword } = form;

    if (password !== confirmPassword) {
      return this.setState({
        errorMessage: 'Passwords should match.',
        touched: true,
        loading: false,
      });
    }
    try {
      checkPassword(password);
      this.props.onSubmit({ password });
      this.setState({ loading: false });
    } catch (error) {
      this.setState({
        errorMessage: 'Password is too weak. Please check the password requirements below and try again.',
        touched: true,
        loading: false,
      });
    }
  };

  changed = (valid: boolean, form: FormValues) => {
    const data = {
      password: form.value<string>('password'),
      confirmPassword: form.value<string>('confirmPassword'),
    };

    this.setState({ form: data, valid });
  };

  initialized = (valid: boolean, form: FormValues) => {
    const data = {
      password: form.value<string>('password'),
      confirmPassword: form.value<string>('confirmPassword'),
    };

    this.setState({ form: data, valid });
  };

  renderSideBar() {
    const passwordPopover = (
      <Popover id='popover-trigger-click' title='Password requirements' style={{ marginLeft: -375 }}>
        <PasswordRules />
      </Popover>
    );
    return (
      <div style={{ position: 'relative' }}>
        <OverlayTrigger trigger='click' placement='left' overlay={passwordPopover}>
          <Button variant='link'>View our password requirements</Button>
        </OverlayTrigger>
      </div>
    );
  }

  render() {
    const { errorMessage } = this.state;
    return (
      <div>
        <h3>Change password</h3>
        <p>Please create a secure password for your Root account.</p>
        {errorMessage && <Alert color='warning'>{errorMessage}</Alert>}
        <Form
          onSubmit={this.submit}
          onValueChanged={this.changed}
          initialized={this.initialized}
          touched={this.state.touched}
        >
          <FormGroupHalf>
            <Text type='password' name='password' label='Password' defaultValue='' required />
          </FormGroupHalf>
          <FormGroupHalf>
            <Text type='password' name='confirmPassword' label='Confirm password' required />
          </FormGroupHalf>
          {this.renderSideBar()}
          <br />
          <br />
          <PrimaryButton
            type='submit'
            disabled={this.state.loading}
            style={{ width: 155, height: 48 }}
            id='loginButton'
            className='btn btn-primary'
          >
            {buttonText({
              loading: this.state.loading,
              text: 'Change password',
            })}
          </PrimaryButton>
        </Form>
      </div>
    );
  }
}

export const ChangePassword = withRouter(ChangePasswordImpl);

const PasswordRules = () => {
  return (
    <StyledPasswordRules>
      <li>Must be 10 or more characters</li>
      <li>Must be 128 or less characters</li>
      <li>
        If less than 20 characters, it must satisfy at <br /> least 3 of the following:
        <ul>
          <li>at least one lowercase letter</li>
          <li>at least one uppercase letter</li>
          <li>at least one digit</li>
          <li>at least one special character</li>
        </ul>
      </li>
    </StyledPasswordRules>
  );
};

export const StyledPasswordRules = styled.ul`
  font-size: 12px;
  padding-top: 15px;
  padding-bottom: 0px;
  padding-left: 30px;
  padding-right: 16px;

  li {
    font-size: 12px;
  }
`;
