import momentTimezone, { Moment } from 'moment-timezone';
import { ValidatorResult, Validator } from './types';

export class AgeValidator implements Validator<Moment | undefined> {
  constructor(private readonly name: string, private readonly age: number) {}

  validate(value: Moment | undefined) {
    if (value === undefined) {
      return { valid: true };
    }

    const result: ValidatorResult = { valid: isValidAge(value, this.age) };

    if (!result.valid) {
      result.message = `${this.name} should reflect an age of ${this.age}.`;
    }

    return result;
  }
}

function isValidAge(value: Moment | undefined, age: number) {
  if (!value) {
    return false;
  }

  return Math.abs(value.diff(momentTimezone(), 'years')) === age;
}
