import { ComplaintEventType } from './complaint-event-type';
import { NetworkEvent } from '../network-event';
import { Event } from '../event';

import { ComplaintOpenedEvent, ComplaintOpenedDisplayEvent } from './complaint-opened-event';
import { ComplaintClosedEvent, ComplaintClosedDisplayEvent } from './complaint-closed-event';
import { ComplaintReopenedEvent, ComplaintReopenedDisplayEvent } from './complaint-reopened-event';
import { ComplaintUpdatedEvent, ComplaintUpdatedDisplayEvent } from './complaint-updated-event';
import { Complaint } from '../../../../complaints/domain/complaint';

export class ComplaintEventFactory {
  public static fromNetwork(event: NetworkEvent, complaint: Complaint) {
    switch (event.type) {
      case ComplaintEventType.ComplaintOpened:
        return new ComplaintOpenedEvent(event as any, complaint);
      case ComplaintEventType.ComplaintClosed:
        return new ComplaintClosedEvent(event as any, complaint);
      case ComplaintEventType.ComplaintReopened:
        return new ComplaintReopenedEvent(event as any, complaint);
      case ComplaintEventType.ComplainantUpdated:
        return new ComplaintUpdatedEvent(event as any, complaint);

      default:
        return null;
    }
  }

  public static toDisplayEvent(event: Event<any>) {
    switch (event.type) {
      case ComplaintEventType.ComplaintOpened:
        return ComplaintOpenedDisplayEvent;
      case ComplaintEventType.ComplaintClosed:
        return ComplaintClosedDisplayEvent;
      case ComplaintEventType.ComplaintReopened:
        return ComplaintReopenedDisplayEvent;
      case ComplaintEventType.ComplainantUpdated:
        return ComplaintUpdatedDisplayEvent;

      default:
        return null;
    }
  }
}
