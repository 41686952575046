export enum CreatedByType {
  User = 'user',
  ApiKey = 'api_key',
  LegacyApiKey = 'legacy_api_key',
  System = 'system',
}

export interface CreatedBy {
  id: string;
  type: CreatedByType;
  ownerId: string;
}

export interface RequestedBy {
  id: string;
  type: CreatedByType;
}
