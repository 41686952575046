import React from 'react';
import { ModalHeader, Modal, ModalBody } from '../../components';
import { AttachmentApi } from '../attachments/stores/attachment-api';

interface Props {
  fileUrl: string;
  fileType: string;
  fileName: string;
  close: () => void;
}

interface State {
  waitingForContent: boolean;
  url?: string;
}

export class ViewFileModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      waitingForContent: true,
    };
  }

  componentDidMount() {
    AttachmentApi.downloadFromUrl({ url: this.props.fileUrl }).then(async (response) => {
      const blob = await response.blob();

      const url = URL.createObjectURL(blob);
      this.setState({
        waitingForContent: false,
        url,
      });
    });
  }

  render() {
    const { waitingForContent, url } = this.state;
    const { fileType, fileName } = this.props;

    return (
      <>
        <Modal
          animated={this.state.waitingForContent}
          isOpen
          toggle={this.props.close}
          className={`document-modal${fileType.toLowerCase().includes('pdf') ? ' pdf' : ''}`}
        >
          <ModalHeader toggle={this.props.close}>{fileName}</ModalHeader>
          <ModalBody>
            <div className='document-content'>
              {fileType.includes('pdf') && !waitingForContent && <embed src={url} type={fileType} />}
              {fileType.includes('image') && !waitingForContent && (
                <img alt='' src={url} onLoad={() => this.setState({ waitingForContent: false })} />
              )}
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
