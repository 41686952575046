import { observable, computed, makeObservable } from 'mobx';
import { ProductModuleApi } from '../product-module-api';
import { FeatureContext, Context, Permission } from '../../framework';
import { Environment } from '../../models/environment';
import { ProductModuleDeprecated } from '../domain/product-module-deprecated';
import { ProductModuleFilters } from '../actions/get-product-modules';

export class ProductModuleListStore {
  @observable isLoaded = false;
  @observable error = '';

  productModules: ProductModuleDeprecated[] = [];

  setError = (error: string) => {
    this.error = error;
  };

  load = async (params?: { filters: ProductModuleFilters }) => {
    this.isLoaded = false;
    try {
      this.productModules = await ProductModuleApi.getProductModules(params);
      this.isLoaded = true;
    } catch (error) {
      this.isLoaded = true;
      return error;
    }
  };

  constructor() {
    makeObservable(this);
  }

  get enabledProductModules() {
    if (!this.productModules.length) {
      return [];
    }
    return this.productModules.filter((productModule: ProductModuleDeprecated) => {
      const liveSettings = productModule.productModuleDefinition.settings;
      const environment = Context.environment === Environment.Sandbox ? Environment.Sandbox : Environment.Production;
      if (!liveSettings || !liveSettings.canIssuePolicyThroughDashboard) {
        return false;
      }

      if (environment === Environment.Production && !productModule.live) {
        return false;
      }

      return true;
    });
  }

  @computed
  get canIssuePolicyFromDashboard() {
    if (!this.isLoaded) {
      return false;
    }
    if (this.enabledProductModules.length === 0) {
      return false;
    }

    const canSendQuote =
      FeatureContext.instance.hasPermission(Permission.CreateQuotes) &&
      FeatureContext.instance.hasPermission(Permission.CreateApplications) &&
      FeatureContext.instance.hasPermission(Permission.CreatePolicyholders);

    const canIssuePolicy =
      FeatureContext.instance.hasPermission(Permission.CreateQuotes) &&
      FeatureContext.instance.hasPermission(Permission.CreateApplications) &&
      FeatureContext.instance.hasPermission(Permission.IssuePolicies);

    return canSendQuote || canIssuePolicy;
  }

  setProductModuleListStoreLoadingTrue = () => {
    this.isLoaded = false;
  };

  updateFromPusher = async () => {
    try {
      this.productModules = await ProductModuleApi.getProductModules();
    } catch (error) {
      console.error('Could not update product modules');
    }
  };
}

export const productModuleListStore = new ProductModuleListStore();
