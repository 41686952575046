import React from 'react';
import { Link } from 'react-router-dom';
import { Svg } from '../svg';

export class Sidebar extends React.Component<any, any> {
  render() {
    return (
      <div className='sidebar'>
        <h6 className='h8'>Kitchen Sink</h6>
        <h4>Kitchen Sink</h4>
        <h6>Work in progress</h6>
      </div>
    );
  }
}

export interface SidebarLinkProps {
  href: string;
  active?: boolean;
  onClick?: () => void;
  isDisabled?: boolean;
  onArchive?: () => void;
}

export class SidebarLink extends React.Component<SidebarLinkProps, any> {
  constructor(props: SidebarLinkProps) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Link
          to={!this.props.isDisabled ? this.props.href : '#'}
          onClick={!this.props.isDisabled ? this.props.onClick : () => {}}
        >
          <div
            className={`sidebar-link${
              this.props.active ? ' active' : this.props.isDisabled ? ' sidebar-link-disabled' : ''
            }`}
          >
            {this.props.children}
            {this.props.onArchive && (
              <Svg
                onClick={this.props.onArchive}
                style={{ float: 'right' }}
                href='/assets/images/icons/icon-16-px-cross.svg'
              />
            )}
          </div>
        </Link>
      </>
    );
  }
}

export interface SidebarScrollLinkProps {
  to: string;
  last?: boolean;
}

let isForcedScroll = false;
let activeId: string | undefined;

export class SidebarScrollLink extends React.Component<SidebarScrollLinkProps, any> {
  constructor(props: SidebarScrollLinkProps) {
    super(props);
    this.state = {
      active: false,
    };
  }

  clicked = () => {
    const elem = document.getElementById('full-events-close') as HTMLAnchorElement;
    if (elem) {
      elem.click();
    }

    const element = document.getElementById(this.props.to) as HTMLDivElement;
    if (element === null) {
      return;
    }

    const parent = element.parentElement as HTMLDivElement;
    activeId = this.props.to;
    isForcedScroll = true;
    this.setState({ active: true });
    scrollTo(parent, element.offsetTop - 80, 250);
    setTimeout(() => {
      isForcedScroll = false;
      activeId = undefined;
      this.scrolled();
    }, 300);
  };

  componentDidMount() {
    const element = document.getElementById(this.props.to) as HTMLDivElement;
    if (element === null) {
      return;
    }

    const p = element.parentElement as HTMLDivElement;
    p.addEventListener('scroll', this.scrolled);
    this.scrolled();
  }

  componentWillUnmount() {
    const element = document.getElementById(this.props.to) as HTMLDivElement;
    if (element === null) {
      return;
    }

    const p = element.parentElement as HTMLDivElement;
    p.removeEventListener('scroll', this.scrolled);
  }

  scrolled = () => {
    if (isForcedScroll) {
      if (activeId !== this.props.to) {
        this.setState({ active: false });
      }
      return;
    }

    const element = document.getElementById(this.props.to) as HTMLDivElement | null;
    const parent = element && (element.parentElement as HTMLDivElement);
    const sections: HTMLDivElement[] = parent
      ? Array.prototype.slice.call(parent.children).filter((x: HTMLDivElement) => x.classList.contains('section'))
      : [];

    const visibleSection = sections.find((x) => isVisible(x.children.item(0) as HTMLElement)) as HTMLDivElement;
    if (!visibleSection) {
      this.setState({
        active: this.props.last === true && parent && parent.scrollTop > parent.scrollHeight * 0.75,
      });
      return;
    }

    const id = visibleSection.getAttribute('id');
    if (id === this.props.to) {
      this.setState({ active: true });
    } else {
      this.setState({ active: false });
    }
  };

  render() {
    return (
      <div className={`sidebar-link${this.state.active ? ' active' : ''}`} onClick={this.clicked}>
        {this.props.children}
      </div>
    );
  }
}

function isVisible(elem: HTMLElement) {
  if (elem === null) {
    return false;
  }

  if (
    elem.offsetWidth + elem.offsetHeight + elem.getBoundingClientRect().height + elem.getBoundingClientRect().width ===
    0
  ) {
    return false;
  }
  const elemCenter = {
    x: elem.getBoundingClientRect().left + elem.offsetWidth / 2,
    y: elem.getBoundingClientRect().top + elem.offsetHeight / 2,
  };
  if (elemCenter.x < 0) {
    return false;
  }
  if (elemCenter.x > (document.documentElement.clientWidth || window.innerWidth)) {
    return false;
  }
  if (elemCenter.y < 0) {
    return false;
  }
  if (elemCenter.y > (document.documentElement.clientHeight || window.innerHeight)) {
    return false;
  }
  let pointContainer: any = document.elementFromPoint(elemCenter.x, elemCenter.y);
  if (pointContainer === null) {
    return false;
  }

  do {
    if (pointContainer === elem) {
      return true;
    }
    pointContainer = pointContainer.parentNode;
  } while (pointContainer);
  return false;
}

export function scrollTo(element: HTMLElement, to: number, duration: number) {
  if (to < 0) {
    to = 0;
  }

  const start = element.scrollTop;
  const change = to - start;
  const increment = 20;
  let currentTime = 0;

  const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
    t /= d / 2;
    if (t < 1) {
      return (c / 2) * t * t + b;
    }
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const animateScroll = () => {
    currentTime += increment;
    const val = easeInOutQuad(currentTime, start, change, duration);
    element.scrollTop = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
}

export const SidebarIconLink = (props: { icon: string; to: string; children: any }) => (
  <Link to={props.to} className='sidebar-link icon'>
    <img src={`/assets/images/icons/${props.icon}`} alt='sidebar-icon' />
    <span>{props.children}</span>
  </Link>
);
