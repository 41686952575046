import { observable, makeObservable } from 'mobx';
import { ProductModule } from 'insurance/product-modules/domain/product-module';
import { getProductModules, ProductModuleFilters } from 'insurance/product-modules/actions/get-product-modules';
import { Context } from 'insurance/framework';
import { getLoginToken } from 'insurance/auth/actions/get-login-token';
import { Organization } from '../domain/organization';
import { OrganizationsApi } from '../organization-api';

export class OrganizationStore {
  @observable isLoaded = false;
  @observable error = '';
  @observable organization?: Organization;
  @observable organizationProductModules: ProductModule[] = [];

  organizations?: Organization[] = [];

  setError = (error: string) => {
    this.error = error;
  };

  getUserOrganizations = async (params: { isLoaded?: boolean }) => {
    this.isLoaded = !!params.isLoaded;
    try {
      this.organizations = await OrganizationsApi.getOrganizations();
      this.isLoaded = true;
    } catch (error) {
      this.error = error;
      this.isLoaded = true;
    }
  };

  getOrganization = async () => {
    try {
      this.organization = await OrganizationsApi.getOrganization();
    } catch (error) {
      return error;
    }
  };

  getOrganizationProductModules = async (filters: ProductModuleFilters) => {
    const { environment, organizationId } = Context;
    const loginToken = getLoginToken();

    this.organizationProductModules = await getProductModules({
      environment,
      organizationId,
      loginToken,
      filters,
    });
  };

  constructor() {
    makeObservable(this);
  }
}

export type TOrganizationStore = OrganizationStore;
export const organizationStore = new OrganizationStore();
