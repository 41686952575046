import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { AccountStore } from '../insurance/account/stores/account-store';
import {
  LoginTwoFARequired,
  LoginError,
  LoginSuccess,
  AccountErrorType,
} from '../insurance/account/domain/login-result';
import { EmailAndPassword } from './email-and-passord';
import { TwoFA } from './two-fa';
import { InsuranceLoadingPage } from '../core/module';
import { ChangePassword } from './change-password';
import {
  LoginPageBodyContainer,
  LoginPageCenterContainer,
  LoginPageFooterLink,
  LoginPageMainContainer,
  LoginPageFooterContainerLinks,
} from './styles/login-styles';

export enum LoginStep {
  EmailAndPassword,
  TwoFA,
}

type Props = RouteComponentProps;

interface Injected extends Props {
  accountStore: AccountStore;
}

interface State {
  step: LoginStep;
  showLoginError: boolean;
  showSystemError: boolean;
  loading: boolean;
  email: string;
  password: string;
  module: any;
  checkingLoginToken: boolean;
  twoFAError: boolean;
  accountLockedError: boolean;
  showForgotPassword: boolean;
  accountErrorType?: AccountErrorType;
  appToken: string;
}

const loginTokenDelta = 1000 * 60 * 44;

@inject('accountStore')
@observer
export class LoginImpl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      checkingLoginToken: true,
      step: LoginStep.EmailAndPassword,
      showLoginError: false,
      showSystemError: false,
      loading: false,
      email: '',
      password: '',
      module: null,
      twoFAError: false,
      showForgotPassword: false,
      appToken: '',
      accountLockedError: false,
    };
  }

  get injected() {
    return this.props as Injected;
  }

  async componentDidMount() {
    if (this.loginTokenValid()) {
      return this.loadInsurance();
    }

    this.setState({ checkingLoginToken: false });
  }

  loginTokenValid = () => {
    const loginTime = localStorage.getItem('login_time');

    if (loginTime) {
      const date = new Date(loginTime);
      const timeTooOld = Date.now() - date.getTime() >= loginTokenDelta;

      if (!timeTooOld) {
        return true;
      }
    }

    return false;
  };

  onEmailAndPasswordSubmitted = async (email: string, password: string) => {
    this.setState({ loading: true, showLoginError: false });
    try {
      const result = await this.injected.accountStore.tryLogin({
        email,
        password,
      });

      if (result instanceof LoginTwoFARequired) {
        this.setState({ step: LoginStep.TwoFA, email, password });
      } else if (result instanceof LoginError) {
        this.setState({
          showLoginError: true,
          showSystemError: false,
          accountErrorType: result.error,
        });
      } else if (result instanceof LoginSuccess) {
        return this.loadInsurance();
      } else {
        this.setState({ showLoginError: false, showSystemError: true });
      }
    } catch (error) {
      this.setState({
        showSystemError: true,
        loading: false,
      });
    }
    this.setState({ loading: false });
  };

  onTwoFASubmitted = async (twoFACode?: string) => {
    const { email, password } = this.state;
    const { accountStore, location } = this.injected;
    const isChangePassword = !!window.location.pathname.includes('/reset-password/');
    const appToken = location.pathname.split('/').pop() as string;

    if (isChangePassword) {
      return this.setState({ loading: true, twoFAError: false }, async () => {
        const result = await accountStore.resetPassword({
          appToken,
          password,
          code: twoFACode,
        });
        if (result && result.account_error_type === AccountErrorType.TwoFaIncorrect) {
          return this.setState({
            twoFAError: true,
            loading: false,
          });
        }
        return (window.location.href = '/?password-reset=true');
      });
    }

    this.setState({ loading: true, twoFAError: false, accountLockedError: false }, async () => {
      try {
        const result = await accountStore.tryLogin({
          email,
          password,
          twoFACode,
        });

        if (result instanceof LoginSuccess) {
          return this.loadInsurance();
        }

        if (result && result.error === AccountErrorType.TwoFaIncorrect) {
          this.setState({
            twoFAError: true,
            loading: false,
          });
        } else if (result && result.error === AccountErrorType.AccountLocked) {
          this.setState({
            twoFAError: false,
            loading: false,
            accountLockedError: true,
          });
        }
      } catch (error) {
        this.setState({
          twoFAError: true,
          loading: false,
        });
      }
    });
  };

  onPasswordChanged = async (password: string) => {
    const { accountStore } = this.injected;
    const appToken = this.props.location.pathname.split('/').pop();

    if (!appToken) return;

    const result = await accountStore.resetPassword({
      password,
      appToken,
    });

    if (result && result.account_error_type === AccountErrorType.TwoFaRequired) {
      this.setState({ step: LoginStep.TwoFA, password, appToken });
    } else {
      window.location.href = '/?password-reset=true';
    }
  };

  loadInsurance = async () => {
    import('../insurance/index')
      .then((a: any) => {
        this.setState({
          twoFAError: false,
          accountLockedError: false,
          module: a.default,
          checkingLoginToken: false,
        });
      })
      .catch((error) => {
        console.error('err', error);
      });
  };

  openBankWeb = () => {
    if (window.location.href.includes('localhost')) {
      window.open('http://localhost:4100/');
    } else if (window.location.href.includes('staging')) {
      window.open('https://staging-card.root.co.za');
    } else {
      window.open('https://card.root.co.za');
    }
  };

  render() {
    const {
      checkingLoginToken,
      email,
      loading,
      password,
      showLoginError,
      showSystemError,
      step,
      twoFAError,
      accountLockedError,
      accountErrorType,
      appToken,
    } = this.state;

    if (checkingLoginToken) {
      return <InsuranceLoadingPage />;
    }

    const InsuranceComponent = this.state.module;

    if (InsuranceComponent) {
      return (
        <div>
          <InsuranceComponent />
        </div>
      );
    }

    const isChangePassword = !!window.location.pathname.includes('/reset-password/');

    return (
      <LoginPageMainContainer>
        <LoginPageCenterContainer>
          <div className='content'>
            <div className='img-container' style={{ marginLeft: 10 }}>
              <img alt='root' src='/assets/images/root-logo.svg' />
            </div>
            <LoginPageBodyContainer>
              <div>
                {isChangePassword && step !== LoginStep.TwoFA && (
                  <ChangePassword onSubmit={({ password }) => this.onPasswordChanged(password)} />
                )}
                {!isChangePassword && step === LoginStep.EmailAndPassword && (
                  <EmailAndPassword
                    updateShowForgotPassword={({ showForgotPassword }) => this.setState({ showForgotPassword })}
                    onSubmit={this.onEmailAndPasswordSubmitted}
                    showLoginError={showLoginError}
                    accountErrorType={accountErrorType}
                    showSystemError={showSystemError}
                    loading={loading}
                  />
                )}
                {step === LoginStep.TwoFA && (
                  <TwoFA
                    email={email}
                    appToken={appToken}
                    password={password}
                    twoFAError={twoFAError}
                    accountLockedError={accountLockedError}
                    loading={loading}
                    onSubmit={(twoFAcode?: string) => this.onTwoFASubmitted(twoFAcode)}
                    close={() => {
                      this.setState({ step: LoginStep.EmailAndPassword });
                    }}
                  />
                )}
              </div>
            </LoginPageBodyContainer>
            <LoginPageFooterContainerLinks>
              <LoginPageFooterLink content='&copy; Root' link='https://rootplatform.com/' />
              <LoginPageFooterLink content='Developer Hub' link='https://docs.rootplatform.com/' />
              <LoginPageFooterLink content='Help Centre' link='https://support.root.co.za/hc/en-us' />
              <LoginPageFooterLink content='Privacy & Terms' link='https://rootplatform.com/legal' hideDivider />
            </LoginPageFooterContainerLinks>
          </div>
        </LoginPageCenterContainer>
      </LoginPageMainContainer>
    );
  }
}

export const Login = withRouter(LoginImpl);
