import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Event, DisplayEvent, DisplayEventContext, DisplayEventProps } from '../event';
import { LinkButton } from '../../../../../components';
import { navigateTo } from '../../../../../core';
import { Context } from '../../../../framework';
import { PolicyViewStore } from '../../../../policies/stores/policy-view-store';

export class PolicyRequotedEvent extends Event<any> {
  public readonly policyId: string;
}

type PolicyRequotedDisplayEventProps = DisplayEventProps<PolicyRequotedEvent>;

interface Injected extends PolicyRequotedDisplayEventProps {
  policyViewStore: PolicyViewStore;
}

@inject('policyViewStore')
@observer
export class PolicyRequotedDisplayEventImpl extends DisplayEvent<PolicyRequotedDisplayEventProps> {
  get injected() {
    return this.props as Injected;
  }

  getTitle = () => {
    if (this.props.context === DisplayEventContext.Policy) {
      return 'Policy requoted';
    }
    const { policy } = this.injected.policyViewStore;
    const { isLoading } = this.injected.policyViewStore;

    return (
      <>
        Policy <i>{isLoading && policy ? policy.packageName : ''}</i> requoted
      </>
    );
  };

  renderFull = () => {
    const { history } = this.props as any;
    const { isLoading } = this.injected.policyViewStore;
    const { policy } = this.injected.policyViewStore;

    return (
      <>
        Policy <i>{!isLoading && policy ? policy.policyNumber : ''}</i> requoted.
        {this.props.context !== DisplayEventContext.Policy && (
          <LinkButton
            onClick={() =>
              navigateTo({
                url: `/orgs/${Context.organizationId}/insurance/policies/${policy?.policyId}`,
                history,
              })
            }
          >
            View policy
          </LinkButton>
        )}
      </>
    );
  };
}

export const PolicyRequotedDisplayEvent = withRouter(PolicyRequotedDisplayEventImpl as any);
