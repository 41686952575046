import React from 'react';
import Logo from '../images/logo';

export default class SinglePageCardLayout extends React.Component {
  render() {
    return (
      <div className='single-page'>
        <div className='logo-bar'>
          <a href='/'>
            <Logo />
          </a>
        </div>
        <div className='container'>
          <div className='single-page-card'>{this.props.children}</div>
        </div>
      </div>
    );
  }
}
